import { SET_USER_MESSAGE, CLEAR_USER_MESSAGE } from './userMessageActionTypes';

export const getInitialState = () => ({
  message: null,
  logLevel: null,
});

const userMessageReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_USER_MESSAGE: {
      const { message, logLevel } = action;
      return { ...state, message, logLevel };
    }
    case CLEAR_USER_MESSAGE: {
      return getInitialState();
    }
    default:
      return state;
  }
};

export default userMessageReducer;
