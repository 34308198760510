export const ONBOARD_SUPPLIER_REQUEST = 'ONBOARD_SUPPLIER_REQUEST';
export const ONBOARD_SUPPLIER_SUCCESS = 'ONBOARD_SUPPLIER_SUCCESS';
export const ONBOARD_SUPPLIER_FAILURE = 'ONBOARD_SUPPLIER_FAILURE';
export const ONBOARD_SUPPLIER_PROGRESS = 'ONBOARD_SUPPLIER_PROGRESS';

export const ONBOARD_SUPPLIER_CANCEL_REQUEST =
  'ONBOARD_SUPPLIER_CANCEL_REQUEST';

export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';

export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER_FAILURE = 'DELETE_SUPPLIER_FAILURE';
