import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';
import { get, noop, throttle } from 'lodash';
import AtlasGroup from './atlasGroup/atlasGroup';
import { pinGroupShape, mapDetailsShape } from './atlasPropTypes';

const Atlas = ({ pins, mapDetails, selectedGroup, clickCallback }) => {
  const mapRef = useRef(null);
  const center = [33.865418, -84.481537]; // HOME DEPOT HQ in ATLANTA
  const zoom = 10; // 10 is a default zoom level that properly shows the Home Depot HQ and surrounding areas
  const [ids, setIds] = useState([]);

  // This function is used to map a group to an actual DOM element.  The DOM element ID
  // comes from Leaflet and upon the group being created for the first time its unique ID is
  // sent to this function.  The effect below makes use of this ID to pan and zoom to the correct group.
  const setGroupId = useCallback(
    (groupId, elementId) => {
      const newIds = ids;
      newIds[groupId] = elementId;
      setIds(newIds);
    },
    [ids],
  );

  // On prop changes the map will re-render with the new pins.  A list of group IDs
  // will have been populated via a callback from the AtlasGRoup component.  Using this ID
  // We can pan and zoom to the selected ID.  The ID does change after every render.
  const panAndZoom = useCallback(() => {
    if (mapRef.current && mapRef.current.leafletElement) {
      mapRef.current.leafletElement.zoomControl.setPosition('topright');
      mapRef.current.leafletElement.eachLayer((layer) => {
        if (layer.getLayers && layer._leaflet_id === ids[selectedGroup]) {
          const bounds = layer.getBounds();

          // If the featuregroup only has one pin in it, getBounds will return a zero area box
          // for the map bounds.  The function below (fitBounds) will fail not gracefully in this case.
          // By slightly changing the location of one of the bounds we can get the fitBounds function to operate as expected.
          if (
            bounds._northEast.lat === bounds._southWest.lat &&
            bounds._northEast.lng === bounds._southWest.lng
          ) {
            bounds._northEast.lat += 0.01;
            bounds._northEast.lng += 0.01;
            bounds._southWest.lat -= 0.01;
            bounds._southWest.lng -= 0.01;
          }

          const options = {};
          window.innerWidth > 680
            ? // This padding is used to ensure that the cards are not sitting on top of pins
              (options.paddingTopLeft = [300, 0])
            : (options.paddingBottomRight = [0, 300]);

          mapRef.current.leafletElement.fitBounds(bounds, options);
        }
      });
    }
  }, [ids, selectedGroup]);

  // this function is throttled this so that the map only pans and zooms at most once a second
  window.addEventListener('resize', throttle(panAndZoom, 1000));

  useEffect(() => panAndZoom(), [ids, selectedGroup, panAndZoom]);

  return (
    <>
      <Map
        preferCanvas
        ref={mapRef}
        center={center}
        zoom={zoom}
        zoomAnimation={false}
        fadeAnimation={false}
      >
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {pins.length
          ? pins.map((pinGroup) => {
              return (
                <AtlasGroup
                  setGroupId={setGroupId}
                  key={`${get(pinGroup, 'id', 'all')}`}
                  pinGroup={pinGroup}
                  mapDetails={mapDetails}
                  clickCallback={clickCallback}
                />
              );
            })
          : ''}
      </Map>
    </>
  );
};

Atlas.propTypes = {
  pins: PropTypes.arrayOf(pinGroupShape),
  mapDetails: mapDetailsShape,
  selectedGroup: PropTypes.string,
  clickCallback: PropTypes.func,
};

Atlas.defaultProps = {
  pins: [],
  mapDetails: {},
  selectedGroup: '',
  clickCallback: noop,
};

export default Atlas;
