import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';

//  Cases covered:
//    1. If JSD is turned on in the supplier page, the user should have JSD settings
//    2. If JSD settings are provided, JSD must be turned on in the supplier page

export const crossEntityJobSiteValidator = (
  jobSiteDeliveryData,
  supplierData,
) => {
  if (!jobSiteDeliveryData || !supplierData) return {};

  // This holds our validation
  // At the end we will return this object
  const validation = validationObjInit();
  validation.message = [];

  const { allowJobSiteDelivery } = supplierData;

  // =======================================================
  //  JSD settings on the vendor page
  //      should match the presence of JSD information
  // =======================================================

  if (
    allowJobSiteDelivery.toLowerCase() === 'yes' &&
    jobSiteDeliveryData.length === 0
  ) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.ALLOW_JOB_SITE_DELIVERY,
      allowJobSiteDelivery,
      window.Locale.JSD_YES_WITHOUT_JSD_SETTINGS,
      window.Locale.SUPPLIER_LISTING,
    );

    validation.isValid = false;
    validation.message.push(message);
  }

  if (
    allowJobSiteDelivery.toLowerCase() === 'no' &&
    jobSiteDeliveryData.length > 0
  ) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.JOBSITE_DELIVERY_COLUMN,
      allowJobSiteDelivery,
      window.Locale.JSD_NO_WITH_JSD_SETTINGS,
      window.Locale.SUPPLIER_LISTING,
    );

    validation.isValid = false;
    validation.message.push(message);
  }

  return validation;
};
