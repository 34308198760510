import { get, each } from 'lodash';
import { createSelector } from 'reselect';

export const getStoreInfo = createSelector(
  (state) => get(state, 'storeInfo.data', {}),
  (stores) => {
    const allStores = {};

    each(stores, (store, storeId) => {
      // New store info service occasionally returns entries that are not actually stores
      // and do not have coordinates or store names, here we filter those out
      if (store.coordinates && store.name) {
        allStores[storeId] = {
          ...store,
          Latitude: store.coordinates.latitude,
          Longitude: store.coordinates.longitude,
        };
      }
    });

    return allStores;
  },
);

export const getStoreCount = (state) => {
  const data = getStoreInfo(state);

  return Object.values(data).length;
};
