import { lengthValidator } from './lengthValidator';
import { numericalStringValidator } from './numericalStringValidator';
import { validationObjInit } from './validationObjInit';

export const phoneNumberValidator = (number) => {
  const validationObj = validationObjInit();

  if (!lengthValidator(number, { length: 10 })) {
    validationObj.isValid = false;
    validationObj.message = `${window.Locale.ERROR_PHONE_LENGTH} ${number}`;
    return validationObj;
  }

  if (!numericalStringValidator(number)) {
    validationObj.isValid = false;
    validationObj.message = `${window.Locale.ERROR_PHONE_NON_NUMBERS} ${number}`;
    return validationObj;
  }

  return validationObj;
};
