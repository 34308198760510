import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import { pinShape } from '../atlasPropTypes';

const AtlasPin = ({ pin, zIndex, icon }) => {
  if (pin.position !== undefined) {
    return (
      <Marker
        icon={icon}
        key={`${pin.name}-pin`}
        position={pin.position}
        zIndexOffset={zIndex}
      >
        {pin.tooltip ? <Popup>{pin.tooltip}</Popup> : null}
      </Marker>
    );
  } else {
    return null;
  }
};

AtlasPin.propTypes = {
  zIndex: PropTypes.number,
  pin: pinShape.isRequired,
  icon: PropTypes.object,
};

AtlasPin.defaultProps = {
  icon: {},
  zIndex: 0,
};

export default AtlasPin;
