import { get } from 'lodash';
import { createSelector } from 'reselect';
import { validateBranchToStoreMappingData } from '../../validateParsedData/validateBranchToStoreMappingData';
import { getJobSiteDeliveryData } from '../../parseOnboardingSpreadsheet/redux/jobSiteDelivery/jobSiteDeliverySelectors';

export const getBranchToStoreMappingData = (state) =>
  get(state, 'branchToStoreMapping.data', []);

export const getBranchToStoreMappingValidation = createSelector(
  getBranchToStoreMappingData,
  getJobSiteDeliveryData,
  (branchToStoreMappingData, jobSiteData) => {
    return validateBranchToStoreMappingData(
      branchToStoreMappingData,
      jobSiteData,
    );
  },
);
