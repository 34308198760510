import { put, takeLatest, call } from 'redux-saga/effects';
import { userErrorMessage } from 'common/userMessageContainer/redux/userMessageActionCreators';
import { AUTHENTICATE_USER_SUCCESS } from 'features/userSession/redux/userSessionActionTypes';
import {
  getStoreInfoSuccess,
  getStoreInfoFailure,
} from './storeInfoActionCreators';
import { getStoreInfo } from './storeInfoAJAX';
import { GET_STORE_INFO_REQUEST } from './storeInfoActionTypes';

export function* getStoreInfoSaga() {
  try {
    const storeInfo = yield getStoreInfo();

    if (!storeInfo || Object.values(storeInfo).length === 0)
      throw new Error(window.Locale.ERROR_NO_STORE_INFO_RECIEVED);

    return yield put(getStoreInfoSuccess(storeInfo));
  } catch (e) {
    yield put(userErrorMessage(e.message));
    return yield put(getStoreInfoFailure(e));
  }
}

function* firstTimeLoadGetStores() {
  // After a user authenticates, we get the store info
  yield call(getStoreInfoSaga);
}

function* storeInfoSaga() {
  yield takeLatest(GET_STORE_INFO_REQUEST, getStoreInfoSaga);
  yield takeLatest(AUTHENTICATE_USER_SUCCESS, firstTimeLoadGetStores);
}

export default storeInfoSaga;
