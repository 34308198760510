import { groupBy, flatten } from 'lodash';

export const getAsNeededStoreDeliveryGroups = (allStoreDeliveryGroups) => {
  // if the deliverToStoreAsNeeded property is set to true on the deliverToStoreAsNeeded object, return that storeDeliveryGroup
  // if the deliverToStoreAsNeeded property is null, empty, or false, it will not be returned
  return allStoreDeliveryGroups.filter(
    (storeDeliveryGroup) =>
      storeDeliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded,
  );
};

export const getCadencedStoreDeliveryGroups = (allStoreDeliveryGroups) => {
  // if the deliverToStoreOnWeeklyCadence property is set to true on the deliverToStoreOnWeeklyCadence object, return that storeDeliveryGroup
  // if the deliverToStoreOnWeeklyCadence property is null, empty, or false, it will not be returned
  return allStoreDeliveryGroups.filter(
    (storeDeliveryGroup) =>
      storeDeliveryGroup.deliverToStoreOnWeeklyCadence
        .deliverToStoreOnWeeklyCadence,
  );
};

export function sortStoreDeliveryGroups(storeDeliveryData) {
  // first we group the array of store delivery groups by branch name
  const storeDeliverySorted = groupBy(storeDeliveryData, 'branchName');

  // lodash's groupBy returns a JavaScript object
  // inside of each branch name (group) object, we sort by deliveryGroupName
  // because arrays are reference types
  // This sorting function will also sort the arrays in our groupBy object.
  Object.values(storeDeliverySorted).forEach((array) =>
    array.sort((a, b) => a.deliveryGroupName - b.deliveryGroupName),
  );

  // we now sort the same (groupBy) JavaScript object from above by the branch name
  // inside of the outermost map function, we get the delivery groups for a given branch
  // finally, we use lodash's flatten method to merge the delivery groups arrays
  // we will pass this flattened array to the DataTable component for display
  return flatten(
    Object.keys(storeDeliverySorted)
      .sort()
      .map((branchName) =>
        storeDeliverySorted[branchName].map((element) => ({
          ...element,
        })),
      ),
  );
}
