import { map, each, groupBy } from 'lodash';
import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';
import { jobSiteDeliveryValidator } from './validators/jobSiteDeliveryValidator';

export const validateJobSiteDelivery = (jobSiteData, branchListingData) => {
  if (!jobSiteData || !branchListingData) return {};

  // we use this to track the validation state of the whole entity.
  const jobsiteDeliveryValidation = validationObjInit();
  jobsiteDeliveryValidation.message = [];

  const validatedData = jobSiteData.map((jobsiteDelivery) => {
    return jobSiteDeliveryValidator(jobsiteDelivery);
  });

  // ======================
  //  Pull the messaging and errors out
  // ======================
  // We now have an array of validation objects,
  // we iterate look through them to find any errors and lift them out to be easily used within the UI
  validatedData
    .filter((val) => {
      return val.validation.message.length > 0;
    })
    .forEach((invalidItem) => {
      if (
        jobsiteDeliveryValidation.isValid &&
        !invalidItem.validation.isValid
      ) {
        jobsiteDeliveryValidation.isValid = false;
      }
      jobsiteDeliveryValidation.message.push(...invalidItem.validation.message);
    });

  // ============================================
  //  Meta Validation
  // ============================================

  // ======================
  // Branch Names defined in Branch Listing
  // ======================
  // Any branch name referenced in the JobSite Delivery tab must be defined in
  // the Branch Listing tab

  // A list of all the branch names from the branch listing tab
  const validBranchNames = map(branchListingData, 'branchName');
  each(jobSiteData, (jobSiteEntry) => {
    // If this branch name does not exist in the valid branch names array
    if (validBranchNames.indexOf(jobSiteEntry.branchName) === -1) {
      const message = createErrorObj(
        jobSiteEntry.row,
        window.Locale.BRANCH_NAME,
        jobSiteEntry.branchName,
        window.Locale.JOBSITE_DELIVERY.UNIQUE_BRANCH_NAME_ERROR,
      );
      jobsiteDeliveryValidation.isValid = false;
      jobsiteDeliveryValidation.message.push(message);
    }
  });

  // ======================
  // From field is unique from other From fields in the same branch
  // From field is different by a margin of 2 or greater from other From fields of the same branch
  // ======================

  // JobSite Delivery groups grouped by branch name
  const jobSiteGroups = groupBy(jobSiteData, 'branchName');

  each(jobSiteGroups, (group) => {
    const bannedNumbers = [];
    each(group, (jobSiteEntry) => {
      // If this number is present in the banned numbers array
      if (bannedNumbers.indexOf(jobSiteEntry.fromInMiles) > -1) {
        const message = createErrorObj(
          jobSiteEntry.row,
          window.Locale.JOBSITE_DELIVERY.FROM,
          jobSiteEntry.fromInMiles,
          window.Locale.JOBSITE_DELIVERY.FROM_ERROR,
        );
        jobsiteDeliveryValidation.isValid = false;
        jobsiteDeliveryValidation.message.push(message);
      } else {
        // Given a range of 0 to 10, 10 is now an invalid number.
        // So is 11 as the next range is implicitly 11 to n.
        bannedNumbers.push(jobSiteEntry.fromInMiles);
        bannedNumbers.push(jobSiteEntry.fromInMiles + 1);
      }
    });
  });

  return {
    validation: jobsiteDeliveryValidation,
    data: validatedData,
  };
};
