import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { groupBy, flatten } from 'lodash';
import accounting from 'accounting';
import { jobSiteDeliveryGroupsPropType } from './previewJobSiteDeliveryPropTypes';
import { getJobSiteDeliveryData } from '../parseOnboardingSpreadsheet/redux/jobSiteDelivery/jobSiteDeliverySelectors';
import Card from '../../common/card/card';

export const PreviewJobSiteDelivery = ({ jobSiteDeliveryGroups }) => {
  // We're going to cleanly handle nodata at a higher level;
  if (!jobSiteDeliveryGroups || jobSiteDeliveryGroups.length === 0) return null;

  // first we group the array of job site delivery groups by branch name
  const jobSiteDeliveryGroupsSorted = groupBy(
    jobSiteDeliveryGroups,
    'branchName',
  );

  // lodash's groupBy returns a JavaScript object
  // inside of each branch name (group) object, we sort by fromInMiles
  // because arrays are reference types
  // this sorting function will also sort the arrays in our groupBy object
  Object.values(jobSiteDeliveryGroupsSorted).forEach((array) =>
    array.sort((a, b) => a.fromInMiles - b.fromInMiles),
  );

  // we now sort the same (groupBy) JavaScript object from above by the branch name
  // inside of the outermost map function, we get the delivery groups for a given branch
  // then in the inside map function, we assign a range number to each item in the array
  // finally, we use lodash's flatten method to merge the delivery groups arrays
  // we pass this flattened array of delivery groups to the DataTable component for display
  const jobSiteDeliveryArray = flatten(
    Object.keys(jobSiteDeliveryGroupsSorted)
      .sort()
      .map((branchName) =>
        jobSiteDeliveryGroupsSorted[branchName].map((element, index) => ({
          ...element,
          rangeNumber: `${window.Locale.RANGE} ${index + 1}`,
        })),
      ),
  );

  const rangeFromTemplate = (rowData) =>
    `${window.Locale.MILES(rowData.fromInMiles)}`;

  const deliveryFeeTemplate = (rowData) =>
    rowData.deliveryFeeDollarAmount && rowData.deliveryFeeDollarAmount > 0
      ? `${accounting.formatMoney(rowData.deliveryFeeDollarAmount)}`
      : null;

  const freeDeliveryTemplate = (rowData) =>
    rowData.freeDeliveryOverDollarAmount &&
    rowData.freeDeliveryOverDollarAmount > 0
      ? `${accounting.formatMoney(rowData.freeDeliveryOverDollarAmount)}`
      : null;

  const maxDeliveryTime = (rowData) =>
    `${window.Locale.DAYS(rowData.maximumDeliveryTime)}`;

  return (
    <Card header={window.Locale.JOB_SITE_DELIVERY_GROUP}>
      <div className="supplier-info-grid">
        <DataTable value={jobSiteDeliveryArray} responsive>
          <Column
            field="branchName"
            header={window.Locale.BRANCH_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            field="rangeNumber"
            header={window.Locale.RANGE_NUMBER}
            headerClassName="cell header-cell"
          />
          <Column
            body={rangeFromTemplate}
            header={window.Locale.FROM}
            headerClassName="cell header-cell"
          />
          <Column
            body={deliveryFeeTemplate}
            header={window.Locale.DELIVERY_FEE}
            headerClassName="cell header-cell"
          />
          <Column
            body={freeDeliveryTemplate}
            header={window.Locale.FREE_DELIVERY_OVER}
            headerClassName="cell header-cell"
          />
          <Column
            body={maxDeliveryTime}
            header={window.Locale.MAX_DELIVERY_TIME}
            headerClassName="cell header-cell"
          />
        </DataTable>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  jobSiteDeliveryGroups: getJobSiteDeliveryData(state),
});

PreviewJobSiteDelivery.propTypes = {
  jobSiteDeliveryGroups: jobSiteDeliveryGroupsPropType,
};

PreviewJobSiteDelivery.defaultProps = {
  jobSiteDeliveryGroups: [],
};

export default connect(mapStateToProps)(PreviewJobSiteDelivery);
