import { get } from 'lodash';
import { createSelector } from 'reselect';
import { validateSupplierData } from '../../validateParsedData/validateSupplierData';

export const getSupplierInformation = (state) =>
  get(state, 'supplierInformation.data', {});

export const getSupplierValidation = createSelector(
  getSupplierInformation,
  (supplierInformationData) => {
    return validateSupplierData(supplierInformationData);
  },
);

export const getPresentationalSupplierInformation = createSelector(
  getSupplierInformation,
  (data) => {
    if (!data) return null;

    const { ediContact, idmContact, primaryContact, additionalContacts } = data;

    // requiresQcAccessCredentials is a boolean, not a string.
    // This function uses that boolean ONLY as a means of displaying "Yes" or "No" in the Supplier Information table.
    // Because we are not using the resulting "Yes" or "No values to perform validation, we do not need to worry about casing.

    const presentationPrimary = {
      ...primaryContact,
      requiresQcAccessCredentials: primaryContact.requiresQcAccessCredentials
        ? window.Locale.YES
        : window.Locale.NO,
    };
    const presentationEdi = {
      ...ediContact,
      requiresQcAccessCredentials: ediContact.requiresQcAccessCredentials
        ? window.Locale.YES
        : window.Locale.NO,
    };
    const presentationIdm = {
      ...idmContact,
      requiresQcAccessCredentials: idmContact.requiresQcAccessCredentials
        ? window.Locale.YES
        : window.Locale.NO,
    };
    const presentationAdditionalContacts = additionalContacts.map(
      (contact) => ({
        ...contact,
        requiresQcAccessCredentials: contact.requiresQcAccessCredentials
          ? window.Locale.YES
          : window.Locale.NO,
      }),
    );

    return {
      ...data,
      ediContact: presentationEdi,
      idmContact: presentationIdm,
      primaryContact: presentationPrimary,
      additionalContacts: presentationAdditionalContacts,
    };
  },
);
