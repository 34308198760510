import React from 'react';
import PropTypes from 'prop-types';
import './logoutButton.scss';

export const logoutButton = ({ logout }) => {
  return (
    <button
      className="logout-button"
      type="button"
      onClick={logout}
      onKeyDown={(e) => {
        if (e.key === 'Enter') logout();
      }}
    >
      <i className="icon_exit-to-app" />
      {window.Locale.LOGOUT}
    </button>
  );
};

logoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default logoutButton;
