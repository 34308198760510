import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkDataPresence } from 'features/validationDisplay/redux/validationDisplaySelectors';
import SubmissionSummaryBanner from 'features/submissionSummary/submissionSummaryBanner/submissionSummaryBanner';
import Header from 'common/header/header';
import ValidationDisplay from 'features/validationDisplay/validationDisplay';
import ParseOnboardingSpreadsheet from 'features/parseOnboardingSpreadsheet/parseOnboardingSpreadsheet';
import UserMessageContainer from 'common/userMessageContainer/userMessageContainer';

const ValidationErrorPage = () => {
  const isDataPresent = useSelector(checkDataPresence);

  if (!isDataPresent) return <Redirect to="/" />;

  return (
    <>
      <Header />
      <ParseOnboardingSpreadsheet />
      <UserMessageContainer />
      <SubmissionSummaryBanner />
      <ValidationDisplay />
    </>
  );
};

export default ValidationErrorPage;
