import { validationObjInit } from './validationObjInit';
import { existenceValidator } from './existenceValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { numericalStringValidator } from './numericalStringValidator';
import { lengthValidator } from './lengthValidator';
import { createErrorObj } from '../helpers/createErrorObj';

export const branchToStoreMappingValidator = (data) => {
  if (!data) return {};

  // we use this to track the validation state of the whole entity.
  const branchToStoreValidation = validationObjInit();
  branchToStoreValidation.message = [];

  // ============================================
  //  Name Validations
  // ============================================

  // ======================
  // Branch Name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================

  const branchNameValidation = validationObjInit();
  // must exist and be at least 1 character
  if (
    !existenceValidator(data.branchName) ||
    !nonEmptyValidator(data.branchName)
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.BRANCH_NAME,
      data.branchName,
      window.Locale.ERROR_NO_BRANCH_NAME,
    );

    branchNameValidation.isValid = false;
    branchNameValidation.message = message;

    branchToStoreValidation.isValid = false;
    branchToStoreValidation.message.push(message);
  }

  // ============================================
  //  Number Validations
  // ============================================

  // ======================
  // StoreID must:
  //   - Be a number
  //   - Be either 3 or 4 digits
  //   - Exist
  // ======================

  const storeNumberValidation = validationObjInit();

  // Must be a number
  if (!numericalStringValidator(data.storeData.storeNumber)) {
    const message = createErrorObj(
      data.row,
      window.Locale.STORE_ID,
      data.storeData.storeNumber,
      window.Locale.ERROR_STORE_ID_MUST_BE_NUMBER,
    );

    storeNumberValidation.isValid = false;
    storeNumberValidation.message = message;

    branchToStoreValidation.isValid = false;
    branchToStoreValidation.message.push(message);
  }

  // Must be a number and must be 3 or 4 digits
  if (
    !numericalStringValidator(data.storeData.storeNumber) ||
    !lengthValidator(data.storeData.storeNumber, { min: 3, max: 4 })
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.STORE_ID,
      data.storeData.storeNumber,
      window.Locale.ERROR_STORE_ID_MUST_BE_CORRECT_LENGTH(
        data.storeData.storeNumber,
      ),
    );

    storeNumberValidation.isValid = false;
    storeNumberValidation.message = message;

    branchToStoreValidation.isValid = false;
    branchToStoreValidation.message.push(message);
  }

  // Must exist
  if (!nonEmptyValidator(data.storeData.storeNumber)) {
    const message = createErrorObj(
      data.row,
      window.Locale.STORE_ID,
      data.storeData.storeNumber,
      window.Locale.ERROR_NO_STORE_ID,
    );

    storeNumberValidation.isValid = false;
    storeNumberValidation.message = message;

    branchToStoreValidation.isValid = false;
    branchToStoreValidation.message.push(message);
  }

  return {
    validation: branchToStoreValidation,
    branchName: branchNameValidation,
    storeData: { storeNumber: storeNumberValidation },
  };
};
