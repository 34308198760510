import { existenceValidator } from './existenceValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { validationObjInit } from './validationObjInit';
import { phoneNumberValidator } from './phoneNumberValidator';
import { numericalStringValidator } from './numericalStringValidator';
import { emailValidator } from './emailValidator';
import { enumValidator } from './enumValidator';
import { createErrorObj } from '../helpers/createErrorObj';

/**
 * This is a function used in validation of parsed data.
 * It validates an object and checks if it is a contact
 *
 * @param {obj} obj - A parsed Contact object
 * @return {obj} - {
 *       validation: {
 *            isValid: Bool
 *            message: String
 *       }
 *       [KEY] : {
 *            isValid: Bool
 *            message: String
 *       }
 *   }
 *
 *   Top level validation key will fail if any sub validation
 *
 */

export const contactValidator = (obj) => {
  // we use this to track the validation state of the entire contact object
  const contactValidation = validationObjInit();
  contactValidation.message = [];

  // ======================
  // Contact name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================

  const nameValidation = validationObjInit();
  if (!existenceValidator(obj.name) || !nonEmptyValidator(obj.name)) {
    const message = createErrorObj(
      obj.row,
      window.Locale.CONTACT_NAME,
      obj.name,
      window.Locale.ERROR_NO_CONTACT_NAME,
    );

    nameValidation.isValid = false;
    nameValidation.message = message;

    contactValidation.isValid = false;
    contactValidation.message.push(message);
  }

  // ======================
  // Contact name is always valid
  // ======================

  const titleValidation = validationObjInit();

  // ======================
  // Phone number must:
  //   - Be a valid phone number per phoneNumberValidator
  //   - Exist
  // ======================

  const phoneNumberValidation = phoneNumberValidator(`${obj.phoneNumber}`);

  if (!phoneNumberValidation.isValid) {
    contactValidation.isValid = false;

    contactValidation.message.push(
      createErrorObj(
        obj.row,
        window.Locale.PHONE_NUMBER,
        obj.phoneNumber,
        phoneNumberValidation.message,
      ),
    );
  }

  // ======================
  // Phone number ext must:
  //   - Be a number
  //   OR
  //   - Be an empty string
  //   OR
  //   - Not exist
  // ======================
  // NOTE: Only primary contacts can have an extension object (if validating spreadsheet data)
  //   As a result this must handle the case where extension = null

  const phoneNumberExtValidation = validationObjInit();
  if (
    obj.phoneNumberExt &&
    existenceValidator(obj.phoneNumberExt) &&
    obj.phoneNumberExt.length !== 0 &&
    !numericalStringValidator(obj.phoneNumberExt)
  ) {
    const message = createErrorObj(
      obj.row,
      window.Locale.PHONE_NUMBER_EXTENSION,
      obj.phoneNumberExt,
      window.Locale.ERROR_BAD_EXTENSION,
    );

    phoneNumberExtValidation.isValid = false;
    phoneNumberExtValidation.message = message;

    contactValidation.isValid = false;
    contactValidation.message.push(message);
  }

  // ======================
  // Email must:
  //   - Be a email per emailValidator
  //   - Exist
  // ======================

  const emailValidation = validationObjInit();
  if (
    !existenceValidator(obj.email) ||
    !nonEmptyValidator(obj.email) ||
    !emailValidator(obj.email)
  ) {
    const message = createErrorObj(
      obj.row,
      window.Locale.EMAIL,
      obj.email,
      window.Locale.ERROR_BAD_EMAIL,
    );

    emailValidation.isValid = false;
    emailValidation.message = message;

    contactValidation.isValid = false;
    contactValidation.message.push(message);
  }

  // ======================
  // requiresQcAccessCredentials must:
  //   - Be true or false
  //   - Exist
  // ======================

  const qcAccessValidation = enumValidator(obj.requiresQcAccessCredentials, [
    true,
    false,
  ]);

  if (!qcAccessValidation.isValid) {
    contactValidation.isValid = false;
    contactValidation.message.push(
      createErrorObj(
        obj.row,
        window.Locale.REQUIRES_QC_ACCESS_CREDENTIALS,
        obj.requiresQcAccessCredentials,
        qcAccessValidation.message,
      ),
    );
  }

  return {
    validation: contactValidation,
    name: nameValidation,
    title: titleValidation,
    phoneNumber: phoneNumberValidation,
    phoneNumberExt: phoneNumberExtValidation,
    email: emailValidation,
    requiresQcAccessCredentials: qcAccessValidation,
  };
};
