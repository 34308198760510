import { takeEvery } from 'redux-saga/effects';
import shajs from 'sha.js';
import get from 'lodash/fp/get';
import { AUTHENTICATE_USER_SUCCESS } from '../../features/userSession/redux/userSessionActionTypes';

export function* initializePendoSaga(action) {
  const pendo = get('pendo', window);

  const profile = get('userProfile', action);
  if (!pendo || !profile) {
    return;
  }

  const { username, storeId } = profile;

  // The user ID must be hashed for PII concerns
  const hashedId = shajs('sha512')
    .update(username)
    .digest('hex');

  yield pendo.initialize({
    account: { id: storeId },
    visitor: { id: hashedId },
  });
}

// eslint-disable-next-line func-names
export default function*() {
  yield takeEvery(AUTHENTICATE_USER_SUCCESS, initializePendoSaga);
}
