import React from 'react';
import PropTypes from 'prop-types';
import { branchType } from '../../previewBranch/previewBranchPropTypes';
import formatAddress from '../../../common/formatAddress/formatAddress';
import './branchCard.scss';

const BranchCard = ({ branch, active, onClick, id }) => {
  const { address, branchName, storeCount } = branch;

  return (
    // This ID is used to scroll the element into view.
    // Only the first card in the list gets an ID.
    <div className="card-wrapper" id={id}>
      <div
        className={`branch-to-store-card ${active ? 'active' : ''}`}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
        role="button"
        tabIndex="0"
      >
        <h4>{window.Locale.BRANCH_NAME}</h4>
        <span className="branch-name">{branchName}</span>

        <h4>{window.Locale.ADDRESS}</h4>
        <span className="address">{formatAddress(address)}</span>

        <h4>{window.Locale.STORE_COUNT}</h4>
        <span className="store-count">{storeCount}</span>
      </div>
    </div>
  );
};

BranchCard.propTypes = {
  active: PropTypes.bool,
  branch: branchType.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

BranchCard.defaultProps = {
  active: false,
  id: null,
  onClick: () => {},
};

export default BranchCard;
