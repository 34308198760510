import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './globalStyles/app.scss';
import configureStore from './store';
import history from './history';
import ProtectedRoutes from '../features/userSession/protectedRoutes';

const App = () => (
  <Provider store={configureStore()}>
    <Router history={history}>
      <ProtectedRoutes />
    </Router>
  </Provider>
);

export default App;
