export const specialCharactersValidator = (string) => {
  // The regex below confirms that ONLY the following characters are present:
  // A-Z : any uppercase letter
  // a-z : any lowercase letter
  // 0-9 : any digit
  // _ : underscore
  // - : dash                -- must be escaped in regex
  // , : comma
  // . : period
  // / : forward slash
  // ( : parenthesis - left
  // ) : parenthesis - right
  // [ : bracket - left
  // ] : bracket - right     -- must be escaped in regex
  // & : ampersand
  // ' : apostrophe
  //   : space character
  // if the string DOES NOT contain a special character, the function returns true
  // if the string DOES contain a special character, the function returns false

  const specialCharacterRegex = new RegExp(/^[A-Za-z0-9_\-,./()[\]&' ]*$/);
  return specialCharacterRegex.test(string);
};

// Bing's address lookup service often cannot resolve address strings that contain special characters such as #.
// For this reason, we attempt to prevent these types of errors by flagging characters that cause Bing to be unable to locate the address.
// It is still possible for an address to pass validation but not be able to be located by Bing; in this case, it is likely that the
// address was entered incorrectly by the user.
// All addresses in a spreadsheet must be able to be located/resolved by Bing before the user can click the push-to-production button.
// We handle unreolvable addresses in a separate check after validation.
export const streetAddressSpecialCharactersValidator = (string) => {
  // The regex below confirms that ONLY the following characters are present:
  // A-Z : any uppercase letter
  // a-z : any lowercase letter
  // 0-9 : any digit
  // - : dash                -- must be escaped in regex
  // , : comma
  // . : period
  // ' : apostrophe
  //   : space character
  // if the string DOES NOT contain a special character, the function returns true
  // if the string DOES contain a special character, the function returns false

  const specialCharacterRegex = new RegExp(/^[A-Za-z0-9\-,.' ]*$/);
  return specialCharacterRegex.test(string);
};
