export const lengthValidator = (str, { length, min, max }) => {
  if (length && str.length !== length) {
    return false;
  }

  if (min && str.length < min) {
    return false;
  }

  if (max && str.length > max) {
    return false;
  }

  return true;
};
