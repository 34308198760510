import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkDataPresence } from 'features/validationDisplay/redux/validationDisplaySelectors';
import SubmissionSummary from 'features/submissionSummary/submissionSummary';
import PreviewInfo from 'features/previewInfo/previewInfo';
import Header from 'common/header/header';

const SummaryPage = () => {
  const isDataPresent = useSelector(checkDataPresence);

  if (!isDataPresent) return <Redirect to="/" />;

  return (
    <>
      <Header />
      <SubmissionSummary routeName="/preview/summary" />
      <PreviewInfo />
    </>
  );
};

export default SummaryPage;
