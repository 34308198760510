import {
  GET_STORE_INFO_REQUEST,
  GET_STORE_INFO_SUCCESS,
  GET_STORE_INFO_FAILURE,
} from './storeInfoActionTypes';

export const getStoreInfo = () => ({
  type: GET_STORE_INFO_REQUEST,
});

export const getStoreInfoFailure = (error) => ({
  type: GET_STORE_INFO_FAILURE,
  error,
});

export const getStoreInfoSuccess = (stores) => ({
  type: GET_STORE_INFO_SUCCESS,
  stores,
});
