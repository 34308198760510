import XLSX from 'xlsx';
import { has, difference } from 'lodash';

export function uppercaseArrayValues(array) {
  return array.map((element) => element.toUpperCase());
}

export function formatArrayAsPrettyString(array) {
  // to make each sheetname more readable, we wrap it with quotation marks
  const arrayWithQuotationMarks = array.map((element) => `"${element}"`);

  // slice off the last element, then transform the rest of the elements into a comma-separated list
  const commaSeparated = arrayWithQuotationMarks.slice(0, -1).join(', ');

  // pull off the last element so that we can add the word "and" in front of it
  const lastElement =
    arrayWithQuotationMarks[arrayWithQuotationMarks.length - 1];

  const formattedString = window.Locale.COMMA_STRING_AND(
    commaSeparated,
    lastElement,
  );

  return arrayWithQuotationMarks.length > 1
    ? formattedString
    : arrayWithQuotationMarks.toString();
}

export default (fileBuffer, expectedSheetNames) => {
  const workbook = XLSX.read(fileBuffer, { type: 'array' });

  // Because Lodash's difference function is case-sensitive,
  // we convert the sheetnames in both arrays to uppercase before comparing them
  // This will catch casing errors ONLY (i.e., instances where the sheetnames are correct, but the casing is not)
  const uppercasedExpectedSheetnames = uppercaseArrayValues(expectedSheetNames);
  const uppercasedReceivedSheetnames = uppercaseArrayValues(
    workbook.SheetNames,
  );

  if (
    has(workbook, 'SheetNames') &&
    workbook.SheetNames.length === expectedSheetNames.length &&
    difference(uppercasedExpectedSheetnames, uppercasedReceivedSheetnames)
      .length === 0
  ) {
    return workbook;
  } else {
    // We expect the uploaded spreadsheet to contain ONLY the following six sheetnames:
    // Vendor Listing, Branch Listing, Store Delivery Group Listing, Job Site Delivery Listing, Branch-to-Store, and Reference
    // This comparison is case-sensitive

    // get the sheetname count so that we know whether or not to use the plural -s in our error message
    const sheetNameCount = difference(expectedSheetNames, workbook.SheetNames)
      .length;

    const sheetNamesExpectedButNotReceived = difference(
      expectedSheetNames,
      workbook.SheetNames,
    );
    const sheetNamesReceivedButNotExpected = difference(
      workbook.SheetNames,
      expectedSheetNames,
    );

    throw new Error(
      window.Locale.WORKBOOK_DOES_NOT_CONTAIN_CORRECT_SHEETS(
        sheetNameCount,
        formatArrayAsPrettyString(sheetNamesExpectedButNotReceived),
        formatArrayAsPrettyString(sheetNamesReceivedButNotExpected),
      ),
    );
  }
};
