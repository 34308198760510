import React from 'react';
import PropTypes from 'prop-types';
import './card.scss';

const Card = ({ header, children }) => {
  return children ? (
    <div className="card">
      {header ? <header>{header}</header> : null}
      <section>{children}</section>
    </div>
  ) : null;
};

Card.propTypes = {
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Card.defaultProps = {
  header: '',
};

export default Card;
