import PropTypes from 'prop-types';

// ================================
//  Important message:
// ================================
//
// In this file you'll see this line of code:
//
//   PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//
// Which may confuse you. Why could a name, title, email or any other field possibly
// be a number, and why would we allow it to be a number?
//
// Our Excel parser will automagically return anything in a cell that can be a number
// as a number. This means that if someone puts a 1 in the name field,
// we'll get a number, not a string. We were expecting a string,
// but it turns out it wasn't and now react is mad. We have a type error.
// To prevent this you'll see that line of code.
//
// Now the better question: Would we ever want it to be a number?
// The answer to that is a plain NO. We want all things from the spreadsheet to be a string.
// Unless you are doing math, you want it to be a string.
// So when we get around to it, we'll improve the information to always be a string
// then this line of code will go away.

// Here we are instantiating a propType shape to extend it.
const contactShape = {
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiresQcAccessCredentials: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// we need to extend the contact shape to allow a phone number extension
// because apparently only primary contacts can have a phone extension ¯\_(ツ)_/¯
export const primaryContact = PropTypes.shape({
  ...contactShape,
  phoneNumberExt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

// And we need to extend it to have a row for all other contacts
export const nonPrimaryContact = PropTypes.shape({
  ...contactShape,
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const supplierInfoType = PropTypes.shape({
  GLN: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalContacts: PropTypes.arrayOf(nonPrimaryContact),
  allowJobsiteDelivery: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  allowUniqueVendorProductIdByLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  backgroundInformation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  brandName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ediContact: nonPrimaryContact,
  idmContact: nonPrimaryContact,
  mVendorNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pVendorNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryContact,
  requireVendorProductIdsForEdi: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  supplierAccountName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
});

export const defaultSupplierInfo = {
  GLN: '',
  additionalContacts: [],
  allowJobsiteDelivery: '',
  allowUniqueVendorProductIdByLength: '',
  backgroundInformation: '',
  brandName: '',
  ediContact: {},
  idmContact: {},
  mVendorNumber: '',
  pVendorNumber: '',
  primaryContact: {
    email: '',
    name: '',
    phoneNumber: '',
    requiresQcAccessCredentials: '',
    title: '',
    phoneNumberExt: '',
  },
  requireVendorProductIdsForEdi: '',
  row: '',
  supplierAccountName: '',
};
