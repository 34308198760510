import {
  FILE_UPLOADED,
  FILE_PARSING_STARTED,
  FILE_PARSING_COMPLETED,
  FILE_VALIDATION_STARTED,
  FILE_VALIDATION_COMPLETED,
  INVALID_FILE_PARSED,
  VALID_FILE_PARSED,
  FILE_PARSING_FAILED,
  CLEAR_FILE_PARSING_ERRORS,
} from './fileUploadedActionTypes';

export const fileUploaded = (fileData, fileName) => ({
  type: FILE_UPLOADED,
  fileData,
  fileName,
});

export const fileParsingStarted = () => ({
  type: FILE_PARSING_STARTED,
});

export const fileParsingCompleted = () => ({
  type: FILE_PARSING_COMPLETED,
});

export const fileValidationStarted = () => ({
  type: FILE_VALIDATION_STARTED,
});

export const fileValidationCompleted = () => ({
  type: FILE_VALIDATION_COMPLETED,
});

export const invalidFileParsed = () => ({
  type: INVALID_FILE_PARSED,
});
export const validFileParsed = () => ({
  type: VALID_FILE_PARSED,
});
export const fileParsingFailed = (error) => ({
  type: FILE_PARSING_FAILED,
  error,
});
export const clearFileParsingErrors = () => ({
  type: CLEAR_FILE_PARSING_ERRORS,
});
