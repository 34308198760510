import { select } from 'redux-saga/effects';
import { LogClientActionEvent } from 'features/clientActions/clientActionLoggingUtil';
import {
  getBranchCount,
  getSupplierAccountName,
} from 'features/submissionSummary/redux/submissionSelectors';

export function* clientActionSuccessSaga({
  startTime,
  endTime,
  createTime,
  supplierId,
}) {
  const branchCount = yield select(getBranchCount);
  const supplierAccountName = yield select(getSupplierAccountName);
  const profile = yield select((state) => state.userSession.userProfile);

  // Logging events for observability
  const payload = {
    supplierAccountName,
    createTime,
    supplierId,
    startTime,
    endTime,
    branchCount,
  };

  LogClientActionEvent(
    profile,
    'SOT_ANALYTICS_EVENT',
    'PROFILE_CREATED_SUCCESSFULLY',
    payload,
  );
}
