import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { areCoordinatesPresent } from 'features/parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesSelector';
import history from 'app/history';
import Button from '@quotecenter/component-library/Button';
import './toggleViewButtons.scss';

export const ToggleViewButtons = () => {
  const areCoordsPresent = useSelector(areCoordinatesPresent);
  const routeName = history.location.pathname;

  // if coordinates are not present, BOTH the map button AND the spreadsheet button are disabled
  const mapClassnames = classnames([
    routeName === '/preview/map' ? 'selected' : null,
    !areCoordsPresent ? 'disabled' : null,
  ]);

  const spreadsheetClassnames = classnames([
    routeName === '/preview/summary' ? 'selected' : null,
    !areCoordsPresent ? 'disabled' : null,
  ]);

  return (
    <div className="toggle-view-button-container">
      <Button
        size="small"
        aria-label={window.Locale.VIEW_MAP}
        className={mapClassnames}
        onClick={() => {
          if (areCoordsPresent) history.push('/preview/map');
        }}
      >
        <i className="icon_location" />
      </Button>
      <Button
        size="small"
        aria-label={window.Locale.VIEW_SPREADSHEET}
        className={spreadsheetClassnames}
        onClick={() => history.push('/preview/summary')}
      >
        <i className="icon_web" />
      </Button>
    </div>
  );
};

export default ToggleViewButtons;
