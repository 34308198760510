import { each, uniq } from 'lodash';
import { validationObjInit } from './validators/validationObjInit';
import { branchValidator } from './validators/branchValidator';
import { createErrorObj } from './helpers/createErrorObj';

/**
 * This is the startpoint for the validation process of the Branch Listing entity
 *
 * @param {string} data - An array of branches
 * @return {obj} - {
 *      validation: {
 *          isValid: Bool
 *          message: [String]
 *      }
 *      data: [BranchValidation]
 *   }
 *
 */

export const validateBranchListingData = (data) => {
  if (!data) return {};

  // we use this to track the validation state of the whole entity.
  const branchListingDataValidation = validationObjInit();
  branchListingDataValidation.message = [];

  // ============================================
  //  Branch Listing Level Validations
  // ============================================

  // ======================
  //  Validate all branches
  // ======================
  // Branches are stored as an array. We iterate through them and validate each individually
  const validatedData = [];

  each(data, (branch) => {
    validatedData.push(branchValidator(branch));
  });

  // ======================
  //  Pull the messaging and errors out
  // ======================
  // We now have an array of validation objects,
  // we iterate look through them to find any errors and lift them out to be easily used within the UI
  validatedData
    .filter((val) => {
      return val.validation.message.length > 0;
    })
    .forEach((invalidItem) => {
      if (
        branchListingDataValidation.isValid &&
        !invalidItem.validation.isValid
      ) {
        branchListingDataValidation.isValid = false;
      }
      branchListingDataValidation.message.push(
        ...invalidItem.validation.message,
      );
    });

  // ============================================
  //  Meta Validation
  // ============================================
  // Branch names must be unique
  const branchNames = data.map((branch) => branch.branchName);

  const duplicateBranchNames = branchNames
    .reduce(
      // checks for duplicates inside the branchNames array
      // if duplicates are found, it returns an array of duplicate values (with each duplicate appearing only once)
      // then use Array.join() to transform the array of duplicates into a comma-separated string
      function getDuplicates(acc, el, i, arr) {
        if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
        return acc;
      },
      [],
    )
    .join(', ');

  // If the length of the unique branchNames array does not match the length of the branchNames array,
  // we know that there are duplicate branch names.
  // If duplicateBranchNames is an empty string, then the duplicate values are empty cells.
  // We do not want the "Two or more branches share a name; please rename one of them" error to display
  // when the duplicate values are empty cells, so we perform an additional check.
  if (
    uniq(branchNames).length !== branchNames.length &&
    duplicateBranchNames !== ''
  ) {
    const message = createErrorObj(
      '--',
      window.Locale.BRANCH_NAME,
      duplicateBranchNames,
      window.Locale.ERROR_BRANCH_NAME_DUPLICATION,
    );

    branchListingDataValidation.isValid = false;
    branchListingDataValidation.message.push(message);
  }

  return {
    validation: branchListingDataValidation,
    data: validatedData,
  };
};
