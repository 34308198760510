import { get } from 'lodash';
import { getConfig } from 'util/config';
import { getPercentForStep } from './onboardSupplierUtil';

/**
 * Returns the progress states
 *   These can be one of the states in onboardSupplierReducer.js, or NOT_STARTED
 *
 * @return {string} one of: NOT_STARTED, PROCESSING, COMPLETED, FAILED
 *
 */
export const getProgressState = (state) => {
  const status = get(state, 'onboardSupplierReducer.status');
  if (!status) return 'NOT_STARTED';
  return status;
};

/**
 * Returns the progress percent
 *
 * @return {Number} between 0 and 100 (inclusive) rounded to a whole number
 *
 */
export const getProgressPercent = (state) => {
  const step = get(state, 'onboardSupplierReducer.step');
  if (!step) return 0;
  return getPercentForStep(step);
};

/**
 * Returns the progress percent
 *
 * @return {String} The name of the step. These names can be found in onboardSupplierReducer.js, if no step returns ''
 *
 */
export const getProgressStep = (state) => {
  const step = get(state, 'onboardSupplierReducer.step');
  if (!step) return '';
  return step.name;
};

export const getCreatedSupplierId = (state) =>
  get(state, 'onboardSupplierReducer.createdSupplierId');

export const getSupplierUrl = (state) => {
  const id = getCreatedSupplierId(state);
  if (!id) return '';
  const { REACT_APP_SUPPLIER_PORTAL_URL } = getConfig();

  return `${REACT_APP_SUPPLIER_PORTAL_URL}/Manage/Vendors/View/${id}`;
};
