import { reduce, findIndex, find } from 'lodash';
/**
 * This reduce function takes the combination of branchInfo and storeData and
 * produces a new array of objects that have the branchId, branchName and stores to mapp to the branch
 * @param {*} branchInfo Array of Objects whose key is the branchName and value is the id
 * @param {*} storeData Array of store branch information
 * @returns {*} an array
 */
export const mapBranchInfoToStoreData = (branchInfo, storeData) =>
  reduce(
    storeData,
    (result, { branchName, storeData: { storeNumber } }) => {
      const newBranch = find(
        branchInfo,
        (branch) => branch.branchName === branchName,
      );
      if (newBranch && newBranch.branchId) {
        const found = findIndex(result, { branchName });
        if (found < 0) {
          const newKey = {
            branchId: newBranch.branchId,
            branchName,
            storeIds: [storeNumber],
          };
          result.push(newKey);
        } else if (!result[found].storeIds[storeNumber]) {
          result[found].storeIds.push(storeNumber);
        }
      }
      return result;
    },
    [],
  );
