import PropTypes from 'prop-types';

export const jobSiteDeliveryType = PropTypes.shape({
  branchName: PropTypes.string,
  fromInMiles: PropTypes.number,
  deliveryFeeDollarAmount: PropTypes.number,
  freeDeliveryOverDollarAmount: PropTypes.number,
  maximumDeliveryTime: PropTypes.number,
});

export const jobSiteDeliveryGroupsPropType = PropTypes.arrayOf(
  jobSiteDeliveryType,
);
