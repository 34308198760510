import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LOG_LEVEL from 'common/userMessageContainer/logLevels';
import UserMessage from 'common/userMessageContainer/userMessage/userMessage';
import { getUserMessageAndLogLevel } from 'common/userMessageContainer/redux/userMessageSelectors';
import { clearUserMessage } from './redux/userMessageActionCreators';

export const UserMessageContainer = ({ logLevel, message, clearMessage }) => {
  if (!logLevel || !message || !Object.values(LOG_LEVEL).includes(logLevel))
    return null;

  return (
    <UserMessage
      logLevel={logLevel}
      message={message}
      clearMessage={clearMessage}
    />
  );
};

UserMessageContainer.propTypes = {
  logLevel: PropTypes.oneOf(Object.values(LOG_LEVEL)),
  message: PropTypes.string,
  clearMessage: PropTypes.func.isRequired,
};

UserMessageContainer.defaultProps = {
  logLevel: null,
  message: null,
};

const mapStateToProps = (state) => ({
  ...getUserMessageAndLogLevel(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearMessage: () => {
    dispatch(clearUserMessage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserMessageContainer);
