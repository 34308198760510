import React from 'react';
import SupplierInfoDisplay from '../supplierInfoDisplay/supplierInfoDisplay';
import PreviewBranch from '../previewBranch/previewBranch';
import PreviewStoreDeliveryAsNeeded from '../previewStoreDelivery/asNeeded/previewStoreDeliveryAsNeeded';
import PreviewStoreDeliveryCadenced from '../previewStoreDelivery/cadenced/previewStoreDeliveryCadenced';
import PreviewJobSiteDelivery from '../previewJobSiteDelivery/previewJobSiteDelivery';
import BranchToStoreMappingDataGrid from '../branchToStoreMapping/branchToStoreMappingDataGrid';
import './previewInfo.scss';

export const PreviewInfo = () => {
  return (
    <div className="preview-info-container">
      <SupplierInfoDisplay />
      <PreviewBranch />
      <PreviewStoreDeliveryAsNeeded />
      <PreviewStoreDeliveryCadenced />
      <PreviewJobSiteDelivery />
      <BranchToStoreMappingDataGrid />
    </div>
  );
};

export default PreviewInfo;
