import { map, get, groupBy, isArray, filter, has, find, each } from 'lodash';

export const getPins = (mapping, allStores, coordinates, branches) => {
  const getPosition = (storeNumber) => [
    allStores[storeNumber].Latitude,
    allStores[storeNumber].Longitude,
  ];

  // Returns all stores that are mapped to a branch
  const allPins = map(mapping, (mapRef) => ({
    name: `#${mapRef.storeData.storeNumber} - ${mapRef.storeData.regionName} - ${mapRef.storeData.marketName}`,
    position: getPosition(mapRef.storeData.storeNumber),
    id: mapRef.id,
    icon: 'store',
    tooltip: `#${mapRef.storeData.storeNumber}`,
    branchName: mapRef.branchName,
    storeNumber: mapRef.storeData.storeNumber,
  }));

  // Returns all stores as unmapped
  const allStoresAsUnmapped = map(allStores, (store, key) => ({
    storeNumber: key,
    name: `#${key} - ${store.name}`,
    position: [store.Latitude, store.Longitude],
    tooltip: `#${key}`,
    icon: 'unMappedStore',
  }));

  const pinGroups = groupBy(allPins, 'id');

  // Find all branches that have no associated store
  const unmappedBranches = [];

  each(branches, (branch) => {
    if (!has(pinGroups, branch.row)) {
      unmappedBranches.push({
        name: `${branch.row}`,
        branchName: branch.branchName,
        id: `${branch.row}`,
        position: coordinates[`${branch.row}`],
        icon: 'branch',
        associatedPins: [],
      });
    }
  });

  // This will return an array of branches with all associated associated stores and an array of all
  return [
    ...map(pinGroups, (group, key) => ({
      name: key,
      branchName: group[0].branchName,
      id: key,
      position: coordinates[key],
      icon: 'branch',
      associatedPins: group,
    })),
    ...unmappedBranches,
    allStoresAsUnmapped,
  ];
};

/**
 * Given an array of pinGroupShapes and a branch to select, this function will format the data to be rendered by Atlas component.
 *
 * The pin group shapes provided must be pure, meaning all associated stores present and the allstores array should be
 * inclusive of the associated stores.
 *
 * Associated stores will be removed from all branches with the exception of the specified branch.
 * Stores that are mapped to a branch are removed from the all unmapped stores array.
 *
 * Example:
    branchName = bar
    allPins = [
        {
            branchName: 'foo',
            associatedStores: [
                {pin-1}
            ]
        },
        {
            branchName: 'bar',
            associatedStores: [
                {pin-a}
            ]
        },
        [
            {pin-1},
            {pin-a},
            {unMapped-pin-x},
            {unMapped-pin-y},
            {unMapped-pin-z},
        ]
    ]

    Example Return: [
        {
            branchName: 'foo',
            associatedStores: []
        },
        {
            branchName: 'bar',
            associatedStores: [
                {pin-a}
            ]
        },
        [
            {unMapped-pin-x},
            {unMapped-pin-y},
            {unMapped-pin-z},
        ]
    ]
 *
 * @param {string} id - The id of the branch to select
 * @param {[pinGroupShape]} allPins - An array of pin group shapes as defined by the atlas prop types.
 * @param {branches} branches
 * @returns pinSets - An array of pin group shapes, sans associated stores of non-selected branches.
 */
export const selectBranch = (id, allPins, branches) => {
  let pinSets = [];
  const branchIds = map(branches, (branch) => `${branch.row}`);

  // Check that the selected ID is a valid branch ID
  if (branchIds.indexOf(id) > -1) {
    pinSets = map(allPins, (pinSet) => {
      let newPinSet;
      // Deselect any stores mapped to another branch, this indicates that the user
      // intereacted with a branch card or branch icon
      if (has(pinSet, 'id') && pinSet.id !== id) {
        newPinSet = { ...pinSet };
        newPinSet.associatedPins = [];

        // Remove the selected branch's stores from the all stores list
      } else if (isArray(pinSet)) {
        newPinSet = [...pinSet];

        // Gets an array of all store numbers that are mapped
        const branchPins = map(
          get(find(allPins, { id }), 'associatedPins', []),
          'storeNumber',
        );

        // Returns a subset of the all stores array that only includes unmapped stores
        newPinSet = filter(newPinSet, (obj) => {
          // Return a pin only is it not present in the branchPins array.
          return branchPins.indexOf(obj.storeNumber) === -1;
        });
      } else {
        newPinSet = { ...pinSet };
      }
      return newPinSet;
    });

    // All unmapped stores has been selected if no ID is present in the branchIds array
  } else {
    let removedPins = [];
    pinSets = map(allPins, (pinSet) => {
      let newPinSet;

      // While viewing all unmapped stores, branch locations should still be present
      if (!isArray(pinSet)) {
        newPinSet = { ...pinSet };
        removedPins = [
          ...removedPins,
          ...map(newPinSet.associatedPins, 'storeNumber'),
        ];
        newPinSet.associatedPins = [];

        // Remove all the removed pins from the all stores pinset
      } else {
        newPinSet = [...pinSet];
        newPinSet = filter(newPinSet, (obj) => {
          return removedPins.indexOf(obj.storeNumber) === -1;
        });
      }
      return newPinSet;
    });
  }
  return pinSets;
};
