import PropTypes from 'prop-types';

export const pinShape = PropTypes.shape({
  icon: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.number),
});

export const pinGroupShape = PropTypes.oneOfType([
  PropTypes.shape({
    associatedPins: PropTypes.arrayOf(pinShape),
    pinShape,
  }),
  PropTypes.arrayOf(pinShape),
]);

export const mapDetailsShape = PropTypes.shape({
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      iconSize: PropTypes.arrayOf(PropTypes.number),
      iconUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
});
