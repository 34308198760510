/**
 * This is a function used in preparing error messages.
 *      It allows for a uniformed convention.
 *      It mandates a high level error message.
 *      It is a point at which we can extend or repurpose messaging.
 *
 * @param {string} row - The row on the spreadsheet
 * @param {string} column - The column name on the sheet
 * @param {string} value - The value of the cell
 * @param {string} error - The error message
 * @param {string} location - Optional: The entity the error came from (The page on the spreadsheet).
 *  Typically set at a higher level than callsite (IE, you can ignore it)
 *
 * @return {Object} Error Message - A UI ready error message
 */

export const createErrorObj = (
  row,
  column,
  value,
  error,
  location = 'UNSET',
) => ({
  row,
  column,
  value,
  error,
  location,
});
