import { v4 } from 'uuid';
import getLogger from './clientActionsLogger';

export const LogClientActionEvent = (
  authUserProfile,
  category,
  description,
  event,
) => {
  try {
    // If we aren't given a logger here a configuration error has occurred within getLogger()
    const actionLogger = getLogger();

    if (actionLogger) {
      const { username, fullName, storeId } = authUserProfile;

      const eventAction = {
        ...event,
        data: JSON.stringify(event), // To resolve ElasticSearch field type issues, data can be many different objects.
      };

      const logData = {
        description,
        category,
        data: {
          thdUserId: username,
          userFullName: fullName,
          userStoreId: storeId,
          action: eventAction,
          eventId: v4(),
        },
      };
      actionLogger.logEvent(logData);
    }
  } catch (err) {
    // Defensively handle any exception in this function so we can guarantee
    // that page render is not disrupted
    // TODO send error to datadog?
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
