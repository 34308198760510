import { isNumber } from 'lodash';
import { validationObjInit } from './validationObjInit';
import { existenceValidator } from './existenceValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { integerValidator } from './integerValidator';
import { createErrorObj } from '../helpers/createErrorObj';

export const jobSiteDeliveryValidator = (data) => {
  // we use this to track the validation state of the whole entity.
  const jobsiteDeliveryGroupValidation = validationObjInit();
  jobsiteDeliveryGroupValidation.message = [];

  // ======================
  // Branch Name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================
  const branchNameValidation = validationObjInit();
  if (
    !existenceValidator(data.branchName) ||
    !nonEmptyValidator(data.branchName)
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.BRANCH_NAME,
      data.branchName,
      window.Locale.JOBSITE_DELIVERY.BRANCH_NAME_REQUIRED,
    );

    branchNameValidation.isValid = false;
    branchNameValidation.message = message;

    jobsiteDeliveryGroupValidation.isValid = false;
    jobsiteDeliveryGroupValidation.message.push(message);
  }

  // ======================
  // From must:
  //   - Be at least 1
  //   - Be at most 12500
  //   - Exist
  // ======================
  const rangeValidation = validationObjInit();
  if (
    !integerValidator(data.fromInMiles) ||
    // The range includes 1 and 12500 so the below conditions will cause an error
    data.fromInMiles < 1 ||
    data.fromInMiles > 12500
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.JOBSITE_DELIVERY.FROM,
      data.fromInMiles,
      window.Locale.JOBSITE_DELIVERY.FROM_REQUIRED,
    );

    rangeValidation.isValid = false;
    rangeValidation.message = message;

    jobsiteDeliveryGroupValidation.isValid = false;
    jobsiteDeliveryGroupValidation.message.push(message);
  }

  // ======================
  // Delivery Fee must:
  //   - Be a positive number
  //   - Be at most 100000
  //   - Optional
  // ======================
  const deliveryFeeValidation = validationObjInit();
  if (
    nonEmptyValidator(data.deliveryFeeDollarAmount) &&
    (!isNumber(data.deliveryFeeDollarAmount) ||
      // The range includes 0 and 100000 so the below conditions will cause an error
      data.deliveryFeeDollarAmount > 100000 ||
      data.deliveryFeeDollarAmount < 0)
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.JOBSITE_DELIVERY.DELIVERY_FEE,
      data.deliveryFeeDollarAmount,
      window.Locale.JOBSITE_DELIVERY.DELIVERY_FEE_ERROR,
    );

    deliveryFeeValidation.isValid = false;
    deliveryFeeValidation.message = message;

    jobsiteDeliveryGroupValidation.isValid = false;
    jobsiteDeliveryGroupValidation.message.push(message);
  }

  // ======================
  // Free Delivery Over must:
  //   - Be a positive whole number
  //   - Be at most 1000000
  //   - Optional
  // ======================
  const freeDeliveryOverValidation = validationObjInit();
  if (
    nonEmptyValidator(data.freeDeliveryOverDollarAmount) &&
    (!isNumber(data.freeDeliveryOverDollarAmount) ||
      // The range includes 0 and 1000000 so the below conditions will cause an error
      data.freeDeliveryOverDollarAmount > 1000000 ||
      data.freeDeliveryOverDollarAmount < 0)
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.JOBSITE_DELIVERY.FREE_DELIVERY,
      data.freeDeliveryOverDollarAmount,
      window.Locale.JOBSITE_DELIVERY.FREE_DELIVERY_ERROR,
    );

    freeDeliveryOverValidation.isValid = false;
    freeDeliveryOverValidation.message = message;

    jobsiteDeliveryGroupValidation.isValid = false;
    jobsiteDeliveryGroupValidation.message.push(message);
  }

  // ======================
  // Max Delivery must:
  //   - Be a positive whole number
  //   - Be at least 1
  //   - Be at most 99
  //   - Exist
  // ======================
  const maxDeliveryTimeValidation = validationObjInit();

  if (
    !existenceValidator(data.maximumDeliveryTime) ||
    !integerValidator(data.maximumDeliveryTime) ||
    // The range includes 1 and 99 so the below conditions will cause an error
    data.maximumDeliveryTime > 99 ||
    data.maximumDeliveryTime < 1
  ) {
    const message = createErrorObj(
      data.row,
      window.Locale.JOBSITE_DELIVERY.MAX_DELIVERY,
      data.maximumDeliveryTime,
      window.Locale.JOBSITE_DELIVERY.MAX_DELIVERY_ERROR,
    );

    maxDeliveryTimeValidation.isValid = false;
    maxDeliveryTimeValidation.message = message;

    jobsiteDeliveryGroupValidation.isValid = false;
    jobsiteDeliveryGroupValidation.message.push(message);
  }

  return {
    validation: jobsiteDeliveryGroupValidation,
    branchName: branchNameValidation,
    from: rangeValidation,
    deliveryFee: deliveryFeeValidation,
    freeDeliveryOver: freeDeliveryOverValidation,
    maxDeliveryTime: maxDeliveryTimeValidation,
  };
};
