import { validationObjInit } from './validationObjInit';
import { existenceValidator } from './existenceValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { stringValidator } from './stringValidator';
import { phoneNumberValidator } from './phoneNumberValidator';
import { integerValidator } from './integerValidator';
import { addressValidator } from './addressValidator';
import { booleanValidator } from './booleanValidator';
import { emailValidator } from './emailValidator';
import { createErrorObj } from '../helpers/createErrorObj';

export const branchValidator = (branch) => {
  // we use this to track the validation state of the whole entity.
  const branchValidation = validationObjInit();
  branchValidation.message = [];

  if (!branch) {
    branchValidation.isValid = false;
    branchValidation.message.push(
      createErrorObj('', '', '', window.Locale.ERROR_NO_BRANCH),
    );
    return {
      validation: branchValidation,
    };
  }

  // ======================
  // Address must:
  //   - Be a valid address
  //   - Exist
  // ======================
  const addressValidation = addressValidator(branch.address, branch.row);
  if (!addressValidation.validation.isValid) {
    branchValidation.isValid = false;
    branchValidation.message.push(...addressValidation.validation.message);
  }

  // ======================
  // Store Delivery Enabled must:
  //   - Be a boolean
  //   - Exist
  // ======================
  const storeDeliveryValidation = validationObjInit();
  if (!booleanValidator(branch.storeDeliveryEnabled)) {
    const message = createErrorObj(
      branch.row,
      window.Locale.STORE_DELIVERY_ENABLED,
      branch.storeDeliveryEnabled,
      window.Locale.ERROR_NO_BRANCH_STORE_DELIVERY,
    );

    storeDeliveryValidation.isValid = false;
    storeDeliveryValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ============================================
  //  Name Validations
  // ============================================

  // ======================
  // Branch name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================
  const branchNameValidation = validationObjInit();
  if (
    !existenceValidator(branch.branchName) ||
    !nonEmptyValidator(branch.branchName)
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.BRANCH_NAME,
      branch.branchName,
      window.Locale.ERROR_NO_BRANCH_NAME,
    );

    branchNameValidation.isValid = false;
    branchNameValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ======================
  // Brand name must:
  //   - Be a string
  //   - Optional
  // ======================
  const brandNameValidation = validationObjInit();
  if (
    existenceValidator(branch.brandName) &&
    nonEmptyValidator(branch.brandName) &&
    !stringValidator(branch.brandName)
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.BRAND_NAME,
      branch.brandName,
      window.Locale.ERROR_BAD_BRAND_NAME,
    );

    brandNameValidation.isValid = false;
    brandNameValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ======================
  // Customer Service Contact must:
  //   - Be at least 1 character
  //   - Exist
  // ======================
  const customerServiceContactValidation = validationObjInit();
  if (
    !existenceValidator(branch.customerServiceContact) ||
    !nonEmptyValidator(branch.customerServiceContact)
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.CUSTOMER_SERVICE_CONTACT,
      branch.customerServiceContact,
      window.Locale.ERROR_NO_CUSTOMER_SERVICE_CONTACT,
    );

    customerServiceContactValidation.isValid = false;
    customerServiceContactValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ============================================
  //  Phone Validations
  // ============================================

  // ======================
  // Phone number must:
  //   - Be a valid phone number per phoneNumberValidator
  //   - Exist
  // ======================
  const phoneNumberValidation = phoneNumberValidator(`${branch.phone}`);
  if (!phoneNumberValidation.isValid) {
    branchValidation.isValid = false;
    branchValidation.message.push(
      createErrorObj(
        branch.row,
        window.Locale.PHONE_NUMBER,
        branch.phone,
        phoneNumberValidation.message,
      ),
    );
  }

  // ======================
  // Fax number must:
  //   - Be a valid fax number per phoneNumberValidator
  //   - Optional
  // ======================
  const faxNumberValidation = validationObjInit();
  if (
    existenceValidator(branch.fax) &&
    nonEmptyValidator(branch.fax) &&
    !phoneNumberValidator(`${branch.fax}`).isValid
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.FAX_NUMBER,
      branch.fax,
      faxNumberValidation.message,
    );

    faxNumberValidation.isValid = false;
    faxNumberValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ======================
  // Phone Extension number must:
  //   - Be a valid number
  //   - Optional
  // ======================
  const phoneExtensionValidation = validationObjInit();
  // if it exists and isn't empty it must be a number
  if (
    existenceValidator(branch.phoneExt) &&
    nonEmptyValidator(branch.phoneExt) &&
    !integerValidator(branch.phoneExt)
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.PHONE_NUMBER_EXTENSION,
      branch.phoneExt,
      window.Locale.ERROR_BAD_EXTENSION,
    );

    phoneExtensionValidation.isValid = false;
    phoneExtensionValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  // ======================
  // Email must:
  //   - Be a email per emailValidator
  //   - Exist
  // ======================

  const emailValidation = validationObjInit();
  if (
    !existenceValidator(branch.email) ||
    !nonEmptyValidator(branch.email) ||
    !emailValidator(branch.email)
  ) {
    const message = createErrorObj(
      branch.row,
      window.Locale.EMAIL,
      branch.email,
      window.Locale.ERROR_BAD_EMAIL,
    );

    emailValidation.isValid = false;
    emailValidation.message = message;

    branchValidation.isValid = false;
    branchValidation.message.push(message);
  }

  return {
    validation: branchValidation,
    branchName: branchNameValidation,
    brandName: brandNameValidation,
    customerServiceContact: customerServiceContactValidation,
    phone: phoneNumberValidation,
    fax: faxNumberValidation,
    phoneExt: phoneExtensionValidation,
    storeDeliveryEnabled: storeDeliveryValidation,
  };
};
