export const SHEETNAMES = [
  'Vendor Listing',
  'Branch Listing',
  'Store Delivery Group Listing',
  'Job Site Delivery Listing',
  'Branch-to-Store',
  'Reference',
];

export const supplierInfoAdditionalContactRowNumbers = [22, 23, 24, 25];

export const branchListingStartingRowNumber = 8;

export const storeDeliveryStartingRowNumber = 10;

export const jobSiteDeliveryStartingRowNumber = 10;

export const branchToStoreStartingRowNumber = 7;
