import { validationObjInit } from './validationObjInit';
import { existenceValidator } from './existenceValidator';
import { streetAddressSpecialCharactersValidator } from './specialCharactersValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { stringValidator } from './stringValidator';
import { postalCodeValidator } from './postalCodeValidator';
import { createErrorObj } from '../helpers/createErrorObj';

export const addressValidator = (address, row) => {
  // we use this to track the validation state of the whole entity.
  const addressValidation = validationObjInit();
  addressValidation.message = [];

  if (!address) {
    addressValidation.isValid = false;
    addressValidation.message.push(
      createErrorObj(row, '', '', window.Locale.ERROR_ADDRESS),
    );
    return { validation: addressValidation };
  }

  // ======================
  // Street must:
  //   - Be at least 1 character
  //   - Contain only alphanumeric characters
  //   - Exist
  // ======================
  const addressStreetValidation = validationObjInit();
  if (
    !existenceValidator(address.street) ||
    !nonEmptyValidator(address.street)
  ) {
    const message = createErrorObj(
      row,
      window.Locale.STREET,
      address.street,
      window.Locale.ERROR_ADDRESS_STREET,
    );

    addressStreetValidation.isValid = false;
    addressStreetValidation.message = message;

    addressValidation.isValid = false;
    addressValidation.message.push(message);
  }

  if (!streetAddressSpecialCharactersValidator(address.street)) {
    const message = createErrorObj(
      row,
      window.Locale.STREET,
      address.street,
      window.Locale.ERROR_ADDRESS_STREET_CONTAINS_SPECIAL_CHARACTER,
    );

    addressStreetValidation.isValid = false;
    addressStreetValidation.message = message;

    addressValidation.isValid = false;
    addressValidation.message.push(message);
  }

  // ======================
  // City must:
  //   - Be at least 1 character
  //   - Exist
  // ======================
  const addressCityValidation = validationObjInit();
  if (!existenceValidator(address.city) || !nonEmptyValidator(address.city)) {
    const message = createErrorObj(
      row,
      window.Locale.CITY,
      address.city,
      window.Locale.ERROR_ADDRESS_CITY,
    );

    addressCityValidation.isValid = false;
    addressCityValidation.message = message;

    addressValidation.isValid = false;
    addressValidation.message.push(message);
  }

  // ======================
  // State must:
  //   - Be 2 characters
  //   - Exist
  // ======================
  const addressStateValidation = validationObjInit();
  if (
    !existenceValidator(address.state) ||
    !stringValidator(address.state) ||
    address.state.length !== 2
  ) {
    const message = createErrorObj(
      row,
      window.Locale.STATE,
      address.state,
      window.Locale.ERROR_ADDRESS_STATE,
    );

    addressStateValidation.isValid = false;
    addressStateValidation.message = message;

    addressValidation.isValid = false;
    addressValidation.message.push(message);
  }

  // ======================
  // Postal code must:
  //   - Be a postal code per postalCodeValidator (see comments around ZIP+4 and Canadian postal codes)
  //   - Exist
  // ======================
  const addressZipValidation = validationObjInit();
  if (!existenceValidator(address.zip) || !postalCodeValidator(address.zip)) {
    const message = createErrorObj(
      row,
      window.Locale.ZIP,
      address.zip,
      window.Locale.ERROR_ADDRESS_ZIP,
    );

    addressZipValidation.isValid = false;
    addressZipValidation.message = message;

    addressValidation.isValid = false;
    addressValidation.message.push(message);
  }

  return {
    validation: addressValidation,
    street: addressStreetValidation,
    city: addressCityValidation,
    state: addressStateValidation,
    zip: addressZipValidation,
  };
};
