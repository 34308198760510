import { put, takeLatest, select, all } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  getCoordinatesSuccess,
  getCoordinatesFailure,
} from './coordinatesActionCreators';
import { getCoordinates } from './coordinatesAJAX';
import { GET_COORDINATES_FOR_ALL_BRANCHES } from './coordinatesActionTypes';
import { getBranchListingData } from '../../branchListingSelectors';

export function* getCoordinatesSaga() {
  const branches = yield select(getBranchListingData);
  const coordinates = {};
  try {
    yield all(
      branches.map(async (branch) => {
        coordinates[branch.row] = get(
          await getCoordinates(branch.address),
          'point.coordinates',
        );
      }),
    );

    yield put(getCoordinatesSuccess(coordinates));
  } catch (e) {
    yield put(getCoordinatesFailure(e));
  }
}

function* coordinatesSaga() {
  yield takeLatest(GET_COORDINATES_FOR_ALL_BRANCHES, getCoordinatesSaga);
}

export default coordinatesSaga;
