import { takeEvery, put } from 'redux-saga/effects';
import history from 'app/history';
import { clearUserMessage } from 'common/userMessageContainer/redux/userMessageActionCreators';
import { resetOnboardingState } from 'features/submissionSummary/pushToProduction/redux/onboardSupplierActionCreators';
import { clearFileParsingErrors } from 'features/parseOnboardingSpreadsheet/redux/fileUploaded/fileUploadedActionCreators';
import { RETURN_TO_HOME } from './returnToHomeActionTypes';

export function* returnToHomeSaga() {
  yield put(clearUserMessage());
  yield put(resetOnboardingState());
  yield put(clearFileParsingErrors());

  yield history.push('/');
}

// eslint-disable-next-line func-names
export default function*() {
  yield takeEvery(RETURN_TO_HOME, returnToHomeSaga);
}
