import { validationObjInit } from './validators/validationObjInit';
import { deliveryGroupValidator } from './validators/deliveryGroupValidator';
import { createErrorObj } from './helpers/createErrorObj';

/**
 * This is the startpoint for the validation process of the Store Delivery entity
 *
 * @param {string} data - An array of delivery groups
 * @return {obj} - {
 *      validation: {
 *          isValid: Bool
 *          message: [String]
 *      }
 *      data: [DeliveryGroupValidation]
 *   }
 *
 */

export const validateStoreDeliveryData = (data) => {
  if (!data) return {};

  // we use this to track the validation state of the whole entity.
  const storeDeliveryDataValidation = validationObjInit();
  storeDeliveryDataValidation.message = [];

  // ============================================
  //  Delivery Group Level Validations
  // ============================================

  // ======================
  //  Validate all delivery groups
  // ======================
  // Delivery groups are stored as an array. We iterate through them and validate each individually
  // This does validation at the form input level (IE: did the user put a legal value in a cell)
  // This also does validation at the group level (As in, did the user's inputs result in a legal delivery group)
  // This will be returned for when it may be needed.

  const validatedData = data.map((deliveryGroup) => {
    return deliveryGroupValidator(deliveryGroup);
  });

  // ======================
  //  Pull the messaging and errors out
  // ======================
  // We now have an array of validation objects,
  // we iterate look through them to find any errors and lift them out to be easily used within the UI
  validatedData
    .filter((val) => {
      return val.validation.message.length > 0;
    })
    .forEach((invalidItem) => {
      if (
        storeDeliveryDataValidation.isValid &&
        !invalidItem.validation.isValid
      ) {
        storeDeliveryDataValidation.isValid = false;
      }
      storeDeliveryDataValidation.message.push(
        ...invalidItem.validation.message,
      );
    });

  // ============================================
  //  Meta Validation
  // ============================================

  // ======================
  // Delivery group and Branch names
  //     - Each deliveryGroupName and BranchName pairing must be unique
  // ======================
  // Finally we must verify that the combinations of deliveryGroups and Branch names
  // We must verify that we have no two delivery groups of the same name within a branch
  // We do this by creating an object with the following shape:
  //   existingPairs = {
  //      branchName: {
  //          deliveryGroupName: true
  //      }
  //   }
  // If at any point we try to add a key that already exists we have found a repeated pairing
  // At that point we can add an error message

  // We're gonna create an object here and we'll use that to track if we have a duplicate
  const existingPairs = {};

  data.forEach((deliveryGroup) => {
    const { branchName, deliveryGroupName } = deliveryGroup;

    if (!existingPairs[branchName]) {
      existingPairs[branchName] = {
        [deliveryGroupName]: true,
      };
    } else {
      if (existingPairs[branchName][deliveryGroupName]) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.DELIVERY_GROUP_NAME,
          deliveryGroupName,
          window.Locale.ERROR_DELIVERY_GROUP_NAME_DUPLICATION,
        );

        storeDeliveryDataValidation.isValid = false;
        storeDeliveryDataValidation.message.push(message);
      }

      existingPairs[branchName] = {
        ...existingPairs[branchName],
        [deliveryGroupName]: true,
      };
    }
  });

  return {
    validation: storeDeliveryDataValidation,
    data: validatedData,
  };
};
