export default (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      if (data) {
        resolve(event.target.result);
      } else {
        reject(new Error(window.locale.CANNOT_LOAD_FILE));
      }
    };
    reader.readAsArrayBuffer(file);
  });
