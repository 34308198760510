import { SHEETNAMES, branchListingStartingRowNumber } from '../consts';
import {
  dangerouslyCoerceToBooleanOrEmpty,
  getCellRefValue,
  generateRowRange,
} from './spreadsheetHelpers';

export default function createBranchInfo(parsedData) {
  const branches = [];
  const branchInfoRowNumbers = generateRowRange(
    parsedData,
    branchListingStartingRowNumber,
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
  );

  function createBranch(rowNumber) {
    return {
      row: rowNumber,
      branchName: getCellRefValue(parsedData, 'A', rowNumber),
      customerServiceContact: getCellRefValue(parsedData, 'B', rowNumber),
      address: {
        street: getCellRefValue(parsedData, 'C', rowNumber),
        city: getCellRefValue(parsedData, 'D', rowNumber),
        state: getCellRefValue(parsedData, 'E', rowNumber),
        zip: getCellRefValue(parsedData, 'F', rowNumber),
      },
      brandName: getCellRefValue(parsedData, 'G', rowNumber),
      phone: getCellRefValue(parsedData, 'I', rowNumber),
      phoneExt: getCellRefValue(parsedData, 'J', rowNumber),
      fax: getCellRefValue(parsedData, 'K', rowNumber),
      email: getCellRefValue(parsedData, 'L', rowNumber),
      storeDeliveryEnabled: dangerouslyCoerceToBooleanOrEmpty(
        getCellRefValue(parsedData, 'M', rowNumber),
        // sheetName, columnLetter, and rowNumber are ONLY used to generate a console message
        SHEETNAMES[1],
        'M',
        rowNumber,
      ),
    };
  }

  branchInfoRowNumbers.forEach((rowNumber) => {
    branches.push(createBranch(rowNumber));
  });

  return branches;
}
