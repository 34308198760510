import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';

// Note: This validator handles "cross-sheet validation" across three tabs:
//  - Branch Listing
//  - Store Delivery Group Listing
//  - Branch-to-Store (Mapping)

// This validator DOES NOT handle validation between just the Branch Listing and Store Delivery Group Listing tab

export const crossSheetDeliveryGroupValidator = (
  deliveryGroups,
  branchData,
  branchToStoreMapping,
) => {
  if (!deliveryGroups || !branchData || !branchToStoreMapping) return {};

  const validation = validationObjInit();
  validation.message = [];

  //  ===================================
  //  All delivery groups used on the branch-to-store mapping tab must exist on the branch listing tab
  //  ===================================

  //  Delivery groups only exist within the context of a branch; this means two things:
  //  1. Two branches may have delivery groups with the same names.
  //  2. Even though a deliveryGroupName is present within the storeDelivery entity,
  //     it may not be legal for a branch to use that delivery group

  // Create a relational lookup object from the deliveryGroups entity, with (roughly) the following shape:
  //  {
  //     BranchName: [ Set of DeliveryGroupNames ]
  //  }
  const lookupStoreDelivery = {};

  // Iterate through the deliveryGroups entity; if a given delivery group object is not present in the lookup object, add it to the lookup.
  deliveryGroups.forEach((dg) => {
    if (!lookupStoreDelivery[dg.branchName]) {
      lookupStoreDelivery[dg.branchName] = new Set();
    }
    lookupStoreDelivery[dg.branchName].add(dg.deliveryGroupName);
  });

  // Iterate through the branch-to-store mappings; for every mapping, validate that
  // the branchName - deliveryGroupName pairing is present in our lookup object.
  // If the pairing is not present, the delivery group does not exist on the branch listing tab.
  branchToStoreMapping.forEach((b2s) => {
    if (
      // we ignore b2s that are not mapped to a delivery group
      b2s.deliveryGroupName !== '' &&
      lookupStoreDelivery[b2s.branchName] &&
      !lookupStoreDelivery[b2s.branchName].has(b2s.deliveryGroupName)
    ) {
      validation.isValid = false;
      validation.message.push(
        createErrorObj(
          b2s.row,
          window.Locale.DELIVERY_GROUP,
          b2s.deliveryGroupName,
          window.Locale.DELIVERY_GROUP_DOESNT_EXIST,
          window.Locale.BRANCH_TO_STORE_MAPPING,
        ),
      );
    }
  });

  //  ===================================
  //  All delivery groups present on the store delivery group listing tab must be used on the branch-to-store mapping tab
  //  ===================================

  //  We perform the same pattern used above, but with the order of the objects reversed.

  // Create a relational lookup object from the branchToStoreMapping entity.
  const lookupBranchToStore = {};

  // Iterate through the branchToStoreMapping entity; if a given delivery group object is not present in the lookup object, add it to the lookup.
  branchToStoreMapping.forEach((b2s) => {
    if (!lookupBranchToStore[b2s.branchName]) {
      lookupBranchToStore[b2s.branchName] = new Set();
    }
    lookupBranchToStore[b2s.branchName].add(b2s.deliveryGroupName);
  });

  // Iterate through the store delivery group listings; for every listing, validate that
  // the branchName - deliveryGroupName pairing is present in our lookup object.
  // If the pairing is not present, the delivery group is not used on the branch-to-store mapping tab.
  deliveryGroups.forEach((dg) => {
    if (
      lookupBranchToStore[dg.branchName] &&
      !lookupBranchToStore[dg.branchName].has(dg.deliveryGroupName)
    ) {
      validation.isValid = false;
      validation.message.push(
        createErrorObj(
          dg.row,
          window.Locale.BRANCH_TO_STORE_MAPPING,
          dg.deliveryGroupName,
          window.Locale.DELIVERY_GROUP_NOT_USED,
          window.Locale.STORE_DELIVERY,
        ),
      );
    }
    const setOfLegalBranchEntityNames = new Set(
      branchData.map(({ branchName }) => branchName),
    );
    // If the branch name isn't present create an error
    if (!setOfLegalBranchEntityNames.has(dg.branchName)) {
      const err = createErrorObj(
        dg.row,
        window.Locale.BRANCH_NAME,
        dg.branchName,
        window.Locale.NO_SUCH_BRANCH_NAME,
        window.Locale.STORE_DELIVERY_GROUP_LISTING,
      );

      validation.isValid = false;
      validation.message.push(err);
    }
  });

  return validation;
};
