import React from 'react';
import PropTypes from 'prop-types';

export const CoordinatesDoNotExistError = ({ doCoordsExist }) => {
  return !doCoordsExist ? (
    <div className="coordinates-do-not-exist-error">
      {window.Locale.ERROR_BING_COULD_NOT_LOCATE_ADDRESS}
    </div>
  ) : null;
};

CoordinatesDoNotExistError.propTypes = {
  doCoordsExist: PropTypes.bool,
};

export default CoordinatesDoNotExistError;
