import { createSelector } from 'reselect';
import { getSupplierInformation } from '../../supplierInfoDisplay/redux/supplierInformationSelectors';
import { branchSelector } from '../../previewBranch/redux/previewBranchSelectors';
import { getBranchToStoreMappingData } from '../../branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { getStoreCount } from '../../storeInfo/redux/storeInfoSelector';

// Supplier account name
export const getSupplierAccountName = (state) => {
  const data = getSupplierInformation(state);
  if (!data) return null;
  return data.supplierAccountName;
};

// Number of branches that exist
export const getBranchCount = (state) => {
  const branches = branchSelector(state);
  if (!branches) return 0;
  return branches.length;
};

// Number of stores mapped to branches
export const getMappedStoreCount = createSelector(
  getBranchToStoreMappingData,
  (branchToStoreMappings) => {
    if (!branchToStoreMappings) return 0;

    const ids = branchToStoreMappings.map((val) => {
      return val.storeData.storeNumber;
    });

    // We are only interested in the number of unique values
    // TypeCasting this array into a set removes all duplicates
    // set.size then returns the number of unique items.
    const set = new Set(ids);
    return set.size;
  },
);

// Number of stores that are not mapped to a branch
export const getUnmappedStoreCount = (state) => {
  const unmappedStores = getStoreCount(state) - getMappedStoreCount(state);
  // when ghost branches are used, it is possible to have more than the number of stores that exist mapped;
  // this means that the number of unmapped stores could be negative
  // if a negative value is returned, we display 0 instead
  return unmappedStores > 0 ? unmappedStores : 0;
};
