import {
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_REQUEST,
  USER_SESSION_ID_SUCCESS,
  USER_SESSION_ID_FAILURE,
} from './userSessionActionTypes';

export const authenticationStates = {
  PROCESSING: 'PROCESSING',
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

export const initializeState = () => ({
  authenticationStatus: null,
  error: null,
  sessionId: null,
  // TODO: update with user profile information
});

const sessionReducer = (state = initializeState(), action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST: {
      return {
        ...state,
        authenticationStatus: authenticationStates.PROCESSING,
      };
    }
    case AUTHENTICATE_USER_SUCCESS: {
      const { userProfile } = action;
      return {
        ...state,
        authenticationStatus: authenticationStates.AUTHENTICATED,
        userProfile,
      };
    }
    case AUTHENTICATE_USER_FAILURE: {
      const { error } = action;

      return {
        ...state,
        error,
        authenticationStatus: authenticationStates.UNAUTHENTICATED,
      };
    }
    case USER_SESSION_ID_SUCCESS: {
      const { sessionId } = action;
      return {
        ...state,
        sessionId,
      };
    }
    case USER_SESSION_ID_FAILURE: {
      const { error } = action;

      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;
