import React from 'react';
import PropTypes from 'prop-types';
import Button from '@quotecenter/component-library/Button';
import ProgressBar from 'common/progressBar/progressBar';
import UserMessageContainer from 'common/userMessageContainer/userMessageContainer';
import SubmissionSummaryBanner from '../../submissionSummaryBanner/submissionSummaryBanner';
import './progressWrapper.scss';

export const PushToProductionProgressWrapper = ({
  supplierAccountName,
  percentComplete,
  progressStep,
  cancel,
}) => (
  <React.Fragment>
    <div className="progress-wrapper">
      <h2>
        {window.Locale.CREATING_RECORDS_IN_PRODUCTION(supplierAccountName)}
      </h2>

      <div className="flex-row">
        <span>{window.Locale[progressStep]}</span>
        <ProgressBar percentComplete={percentComplete} />

        <Button
          size="small"
          aria-label={window.Locale.CANCEL_PUSH_TO_PROD}
          onClick={cancel}
        >
          {window.Locale.CANCEL}
        </Button>
      </div>
    </div>

    <UserMessageContainer />
    <SubmissionSummaryBanner />
  </React.Fragment>
);

PushToProductionProgressWrapper.propTypes = {
  supplierAccountName: PropTypes.string.isRequired,
  percentComplete: PropTypes.number.isRequired,
  progressStep: PropTypes.oneOf([
    // ProgressStep is an empty string when the process hasn't started.
    '',
    'CREATING_SUPPLIER',
    'CREATING_BRANCHES',
    'SETTING_MARKET_CLASS',
    'SETTING_MERCHANT_CLASS',
    'SETTING_JOBSITE_DELIVERY',
    'SETTING_INSTANT_PRICING',
    'MAPPING_BRANCHES_TO_STORES',
    'CREATING_DELIVERY_GROUPS',
    'MAPPING_STORES_TO_DELIVERY_GROUPS',
    'CREATION_COMPLETE',
  ]).isRequired,
  cancel: PropTypes.func.isRequired,
};

export default PushToProductionProgressWrapper;
