import { supplierInfoAdditionalContactRowNumbers as additionalContactRowNumbers } from '../consts';

// SheetJS is a parser/writer for various spreadsheet formats, including Microsoft Excel.
// We use the community version, js-xlsx, which is written in JavaScript and compatible back to IE6.
// You can find the docs for SheetJS at https://docs.sheetjs.com/#sheetjs-js-xlsx

// The parser spits out an object that contains many cell objects.
// Cell objects are plain JS objects with keys and values following the convention:

// v   raw value (see Data Types section for more info)
// w   formatted text (if applicable)
// t   type: b Boolean, e Error, n Number, d Date, s Text, z Stub
// f   cell formula encoded as an A1-style string (if applicable)
// F   range of enclosing array if formula is array formula (if applicable)
// r   rich text encoding (if applicable)
// h   HTML rendering of the rich text (if applicable)
// c   comments associated with the cell
// z   number format string associated with the cell (if requested)
// l   cell hyperlink object (.Target holds link, .Tooltip is tooltip)
// s   the style/theme of the cell (if applicable)

// You can find further information at https://docs.sheetjs.com/#cell-object

function determineContactAccess(value) {
  // BUSINESS RULE: Unless the value in this cell is explicitly set to 'Yes', we default access to false
  // This was a request from the supplier onboarding team

  // convert the value to lowercase so that we don't have to match specific casing
  const lowercasedValue = value.toLowerCase();

  return lowercasedValue === 'yes';
}

function createPrimaryContact(parsedData) {
  return {
    name: parsedData.D8 ? parsedData.D8.w : '',
    title: parsedData.E8 ? parsedData.E8.w : '',
    phoneNumber: parsedData.F8 ? parsedData.F8.w : '',
    phoneNumberExt: parsedData.G8 ? parsedData.G8.w : '',
    email: parsedData.H8 ? parsedData.H8.w : '',
    requiresQcAccessCredentials: determineContactAccess(
      parsedData.I8 ? parsedData.I8.w : '',
    ),
  };
}

function createContact(parsedData, rowNumber) {
  function getCellRefValue(columnLetter) {
    const cellRef = parsedData[`${columnLetter}${rowNumber}`];
    return cellRef ? parsedData[`${columnLetter}${rowNumber}`].w : '';
  }

  return {
    row: rowNumber,
    name: getCellRefValue('A'),
    title: getCellRefValue('B'),
    phoneNumber: getCellRefValue('C'),
    email: getCellRefValue('D'),
    requiresQcAccessCredentials: determineContactAccess(getCellRefValue('E')),
  };
}

function createAdditionalContacts(parsedData) {
  const additionalContacts = [];

  additionalContactRowNumbers.forEach(function pushNonEmptyRowIntoArray(
    rowNumber,
  ) {
    // check that the row contains a contact name (column A)
    // and either a phone number (column C) or email (column D)
    if (
      parsedData[`A${rowNumber}`] &&
      (parsedData[`C${rowNumber}`] || parsedData[`D${rowNumber}`])
    ) {
      additionalContacts.push(createContact(parsedData, rowNumber));
    }
  });

  return additionalContacts;
}

export default function createSupplierInfo(parsedData) {
  return {
    row: 8,
    supplierAccountName: parsedData.A8 ? parsedData.A8.v : '',
    brandName: parsedData.B8 ? parsedData.B8.v : '',
    pVendorNumber: parsedData.C8 ? parsedData.C8.v : '',
    mVendorNumber: parsedData.J8 ? parsedData.J8.v : '',
    GLN: parsedData.K8 ? parsedData.K8.v : '',
    requireVendorProductIdsForEdi: 'Yes', // Change made as per SUPEXP_419, to set this value of this field as always true.
    allowJobSiteDelivery: parsedData.M8 ? parsedData.M8.v : '',
    allowUniqueVendorProductIdByLength: parsedData.N8 ? parsedData.N8.v : '',
    backgroundInformation: parsedData.O8 ? parsedData.O8.v : '',
    primaryContact: createPrimaryContact(parsedData),
    idmContact: parsedData.A16 ? createContact(parsedData, 16) : {},
    ediContact: parsedData.A19 ? createContact(parsedData, 19) : {},
    additionalContacts: createAdditionalContacts(parsedData),
  };
}
