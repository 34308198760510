import { ADD_SUPPLIER_INFORMATION } from './supplierInformationActionTypes';

export const getInitialState = () => ({
  data: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case ADD_SUPPLIER_INFORMATION:
      return { ...state, data: action.info };
    default:
      return state;
  }
};
