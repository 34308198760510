import { ADD_BRANCH_LISTING_INFORMATION } from './branchListingActionTypes';

export const getInitialState = () => ({
  data: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case ADD_BRANCH_LISTING_INFORMATION: {
      const { info } = action;

      return { ...state, data: info };
    }
    default:
      return state;
  }
};
