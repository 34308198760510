import {
  GET_COORDINATES_FOR_ALL_BRANCHES,
  GET_COORDINATES_SUCCESS,
  GET_COORDINATES_FAILURE,
} from './coordinatesActionTypes';

export const getInitialState = () => ({
  data: null,
  error: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case GET_COORDINATES_FOR_ALL_BRANCHES: {
      return { ...state };
    }

    case GET_COORDINATES_SUCCESS: {
      const { coordinates } = action;
      return { ...state, error: null, data: coordinates };
    }

    case GET_COORDINATES_FAILURE: {
      const { error } = action;
      return { ...state, error };
    }

    default:
      return state;
  }
};
