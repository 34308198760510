import { datadogRum } from '@datadog/browser-rum';
import { name, version } from '../package.json';
import { getConfig } from './util/config';

export const initDatadogRum = () => {
  const config = getConfig();
  datadogRum.init({
    applicationId: '9374f79e-f41c-4467-9ef4-c3cc228644ca',
    clientToken: 'pub940e883cfcc2f9f43ff87f9387c57bac',
    site: 'datadoghq.com',
    service: name,
    version,
    env: config.DD_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    logInjection: true,
    analytics: true,
    allowedTracingUrls: [
      {
        match: /https:\/\/.*\.hdquotecenter\.com/,
        propagatorTypes: ['tracecontext'],
      },
    ],
  });
  datadogRum.startSessionReplayRecording();
};
