import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { branchSelector } from './redux/previewBranchSelectors';
import Card from '../../common/card/card';
import { branchesPropType } from './previewBranchPropTypes';
import FormattedAddress from '../../common/formatAddress/formatAddress';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import './previewBranch.scss';

export const PreviewBranch = ({ branches }) => {
  // We're going to cleanly handle nodata at a higher level;
  if (!branches) return null;

  // PrimeReact does a lot of magic under the hood here, and unfortunately the docs aren't clear
  // rowData is a special object that is automagically passed in by PrimeReact; rowData's properties are then accessible via dot notation
  const addressTemplate = (rowData) => (
    <FormattedAddress
      street={rowData.address.street}
      city={rowData.address.city}
      state={rowData.address.state}
      zip={rowData.address.zip}
    />
  );

  const phoneWithExtensionTemplate = (rowData) =>
    formatPhoneNumber(`${rowData.phone}`, `${rowData.phoneExt}`);

  const faxTemplate = (rowData) => formatPhoneNumber(`${rowData.fax}`);

  return (
    <Card header={window.Locale.BRANCH_LISTING}>
      <div className="branch-display-table-container">
        <DataTable value={branches} responsive>
          <Column
            field="branchName"
            header={window.Locale.BRANCH_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            field="customerServiceContact"
            header={window.Locale.CUSTOMER_SERVICE}
            headerClassName="cell header-cell"
          />
          <Column
            body={addressTemplate}
            header={window.Locale.BRANCH_ADDRESS}
            headerClassName="cell header-cell"
          />
          <Column
            body={phoneWithExtensionTemplate}
            header={window.Locale.PHONE}
            headerClassName="cell header-cell medium-cell"
          />
          <Column
            body={faxTemplate}
            header={window.Locale.FAX}
            headerClassName="cell header-cell medium-cell"
          />
          <Column
            field="email"
            header={window.Locale.EMAIL}
            headerClassName="cell header-cell"
          />
          <Column
            field="brandName"
            header={window.Locale.BRAND_NAME}
            headerClassName="cell header-cell"
          />
        </DataTable>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  branches: branchSelector(state),
});

PreviewBranch.propTypes = {
  branches: branchesPropType,
};
PreviewBranch.defaultProps = {
  branches: [],
};

export default connect(mapStateToProps)(PreviewBranch);
