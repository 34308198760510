import PropTypes from 'prop-types';

export const branchType = PropTypes.shape({
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  branchName: PropTypes.string,
  brandName: PropTypes.string,
  customerServiceContact: PropTypes.string,
  email: PropTypes.string,
  fax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phoneExt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.number,
  storeDeliveryEnabled: PropTypes.bool,
});

export const branchesPropType = PropTypes.arrayOf(branchType);

export const mappingPropType = PropTypes.arrayOf(
  PropTypes.shape({
    branchName: PropTypes.string,
    deliveryGroupName: PropTypes.string,
    storeData: branchType,
  }),
);
