import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserMessageContainer from 'common/userMessageContainer/userMessageContainer';
import PreSubmissionDisplay from './presubmissionDisplay/presubmissionDisplay';
import PushToProductionProgressWrapper from './pushToProduction/pushToProdProgressWrapper/pushToProductionProgressWrapper';
import { getSupplierAccountName } from './redux/submissionSelectors';
import { onboardSupplierCancelRequest } from './pushToProduction/redux/onboardSupplierActionCreators';
import {
  getProgressState,
  getProgressStep,
  getProgressPercent,
  getSupplierUrl,
} from './pushToProduction/redux/onboardSupplierSelectors';

export const SubmissionSummary = ({
  cancelOnboarding,
  supplierAccountName,
  progressPercent,
  progressState,
  progressStep,
  supplierUrl,
}) => {
  // progress state is one of the following:
  // NOT_STARTED
  // PROCESSING
  // COMPLETED
  // FAILED
  // These are exported from the onboarding reducer
  if (progressState === 'NOT_STARTED') {
    return (
      <PreSubmissionDisplay
        supplierAccountName={`${supplierAccountName}`.trim()}
      />
    );
  } else if (progressState === 'PROCESSING') {
    return (
      // progress step is one of the following:
      // CREATING_SUPPLIER,
      // CREATING_BRANCHES,
      // SETTING_MARKET_CLASS
      // SETTING_MERCHANT_CLASS,
      // SETTING_JOBSITE_DELIVERY
      // SETTING_INSTANT_PRICING
      // MAPPING_BRANCHES_TO_STORES
      // CREATION_COMPLETE
      // These are exported from the onboarding reducer
      <PushToProductionProgressWrapper
        cancel={cancelOnboarding}
        supplierAccountName={`${supplierAccountName}`.trim()}
        percentComplete={progressPercent}
        progressStep={progressStep}
      />
    );
  } else if (progressState === 'COMPLETED') {
    return (
      <React.Fragment>
        <div className="progress-wrapper success">
          <h2>
            <i className="icon_check" />
            {window.Locale.SUCCESSFULLY_MAPPED(`${supplierAccountName}`.trim())}
          </h2>
          <a href={supplierUrl}>{window.Locale.VIEW_IN_VENDOR_PORTAL}</a>
        </div>
        <UserMessageContainer />
      </React.Fragment>
    );
  } else {
    // something went wrong; the progressStep is not one of:
    // NOT_STARTED, PROCESSING, or COMPLETED
    // progressStep is either FAILED or we're caught in another edge case
    return (
      <React.Fragment>
        <div className="progress-wrapper">
          <h2>
            {window.Locale.ERROR_UPLOADING_SUPPLIER_RECORD(
              `${supplierAccountName}`.trim(),
            )}
          </h2>
        </div>
        <UserMessageContainer />
      </React.Fragment>
    );
  }
};

SubmissionSummary.propTypes = {
  supplierAccountName: PropTypes.string.isRequired,
  progressPercent: PropTypes.number.isRequired,
  progressState: PropTypes.oneOf([
    'NOT_STARTED',
    'PROCESSING',
    'COMPLETED',
    'FAILED',
  ]).isRequired,
  progressStep: PropTypes.oneOf([
    // ProgressStep is an empty string when the process hasn't started.
    '',
    'CREATING_SUPPLIER',
    'CREATING_BRANCHES',
    'CREATING_DELIVERY_GROUPS',
    'SETTING_MARKET_CLASS',
    'SETTING_MERCHANT_CLASS',
    'SETTING_JOBSITE_DELIVERY',
    'MAPPING_BRANCHES_TO_STORES',
    'SETTING_INSTANT_PRICING',
    'CREATING_DELIVERY_GROUPS',
    'MAPPING_STORES_TO_DELIVERY_GROUPS',
    'CREATION_COMPLETE',
  ]).isRequired,
  supplierUrl: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  cancelOnboarding: () => {
    dispatch(onboardSupplierCancelRequest());
  },
});

const mapStateToProps = (state) => ({
  supplierAccountName: getSupplierAccountName(state),
  progressState: getProgressState(state),
  progressStep: getProgressStep(state),
  progressPercent: getProgressPercent(state),
  supplierUrl: getSupplierUrl(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmissionSummary);
