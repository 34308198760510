import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/fp/get';
import { authenticationStates } from './redux/userSessionReducer';
import { authenticateUserRequest } from './redux/userSessionActionCreators';
import { getConfig } from '../../util/config';

/**
 * React Router does not allow us to route or link outside of the site
 * We need to manually set the window location in order to redirect
 * This might be reusable later
 */
export const redirectToLogin = () => {
  const config = getConfig();
  const redirect = `${encodeURI(window.location.href)}`;
  window.location.assign(
    `${config.REACT_APP_METOXOS_USER_URL}/Account/Login/?ReturnUrl=${redirect}`,
  );
};

/**
 * Custom hook for determining if the user has permission to view the app
 * Leverages redux hooks to dispatch a request to authenticate a cookie
 * returns a boolean representing the authentication status
 */
export const useAuth = () => {
  // useSelector takes in a selector that is used to pull data off of state
  // in this case the userAuthentication data is returned
  const authenticationStatus = useSelector(
    get('userSession.authenticationStatus'),
  );
  // useDispatch gives us access to the dispatch function which allows us to emit redux actions
  const dispatch = useDispatch();

  // This effect will trigger if the userAuthenticationStatus changes, or if dispatch updates
  useEffect(() => {
    if (authenticationStatus === authenticationStates.UNAUTHENTICATED) {
      // If the user is not authenticated redirect to the login page
      redirectToLogin();
    } else if (!authenticationStatus) {
      // If we have not set the status, emit a request to try and authenticate
      // TODO: using a falsy value might be confusing, should we have an initial status?
      dispatch(authenticateUserRequest());
    }
  }, [authenticationStatus, dispatch]);
  // our custom hook will return a boolean to the component with the auth status
  return {
    isAuthenticated:
      authenticationStatus === authenticationStates.AUTHENTICATED,
  };
};
