export const integerValidator = (val) => {
  // coerce to number if not a number then modulo 1.
  // Any int will return 0, any decimal will not.
  // Compare to 0 to return a boolean.
  // Alternative strategy: ParseInt(val)
  // Why not?
  // 0 is a falsy value meaning you need to handle the edge case, now we get that free.

  return Number(val) % 1 === 0;
};
