import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ValidationDisplayTable from './validationDisplayTable/validationDisplayTable';
import ValidationDisplayHeader from './validationDisplayHeader/validationDisplayHeader';
import {
  selectAllValidation,
  checkDataPresence,
} from './redux/validationDisplaySelectors';
import './validationDisplay.scss';

export const ValidationDisplay = ({ validationErrors, isDataPresent }) => {
  // Three cases exist:
  //      Case 1: No data has been uploaded.
  //      Case 2: Data has been uploaded, but no errors exist.
  //      Case 3: Data has been uploaded and errors exist.

  // Case 1: No data has been uploaded
  if (!isDataPresent) {
    return (
      <div className="validation-display centered">
        <ValidationDisplayHeader
          text={window.Locale.VALIDATION_DISPLAY_HEADER_NO_DATA}
        />
      </div>
    );
  }

  // Case 2: Data has been uploaded, but no errors exist.
  if (!validationErrors.length) {
    return (
      <div className="validation-display centered">
        <ValidationDisplayHeader
          text={window.Locale.VALIDATION_DISPLAY_HEADER_NO_ERRORS}
        />
      </div>
    );
  }

  // Case 3: Data has been uploaded and errors exist.
  return (
    <div className="validation-display">
      <ValidationDisplayHeader
        text={window.Locale.VALIDATION_DISPLAY_HEADER_ERRORS}
        icon={<i className="icon_report-filled" />}
      />
      <ValidationDisplayTable validationErrors={validationErrors} />
    </div>
  );
};

ValidationDisplay.propTypes = {
  validationErrors: PropTypes.arrayOf(PropTypes.object),
  isDataPresent: PropTypes.bool.isRequired,
};

ValidationDisplay.defaultProps = {
  validationErrors: [],
};

const mapStateToProps = (state) => ({
  validationErrors: selectAllValidation(state),
  isDataPresent: checkDataPresence(state),
});

export default connect(mapStateToProps)(ValidationDisplay);
