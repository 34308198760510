import PropTypes from 'prop-types';
import React from 'react';

const FormattedAddress = ({ street, city, state, zip }) => {
  const formattedStreet = street ? (
    <React.Fragment>
      {street}
      <br />
    </React.Fragment>
  ) : null;

  let formattedCityState = null;
  if (city && state) {
    formattedCityState = <React.Fragment>{`${city}, ${state}`}</React.Fragment>;
  } else if (city || state) {
    formattedCityState = (
      <React.Fragment>
        {city}
        {state}
      </React.Fragment>
    );
  } else formattedCityState = null;

  const formattedZip = zip ? (
    <React.Fragment>
      {city || state ? ' ' : ''}
      {zip}
      <br />
    </React.Fragment>
  ) : null;

  return (
    <React.Fragment>
      {formattedStreet}
      {formattedCityState}
      {formattedZip}
    </React.Fragment>
  );
};

FormattedAddress.propTypes = {
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormattedAddress.defaultProps = {
  street: '',
  city: '',
  state: '',
  zip: '',
};

export default FormattedAddress;
