import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';

//  Cases covered:
//    1. Every branch should be on the branch to store page
//    2. Branches that are present on the branch to store page should be present on the branch listing page

export const crossEntityBranchValidator = (branchData, branchToStoreData) => {
  if (!branchData || !branchToStoreData) return {};

  // This holds our validation
  // At the end we will return this object
  const validation = validationObjInit();
  validation.message = [];

  // =======================================================
  //  Every branchName on the branchToStore page
  //    should be on the branch page
  // =======================================================
  //
  //  Goal: Find all branches that exist in the branchToStore entity,
  //          that do not exist in the branch entity
  //
  //  Method:
  //    First, we need to isolate all the branchNames in both entities
  //    Next we need, to check the presence of each branch name in the branchToStore entity
  //      in the branch entity
  //    Any element that isn't present needs to get an error message.
  //
  //    We do this by making sets of names, then iterating though the branchToStore entity verifying that each name exists

  // Here we are creating a set here for constant time lookups
  const setOfLegalBranchEntityNames = new Set(
    branchData.map(({ branchName }) => branchName),
  );

  // Then we iterate through our branchToStore entity data
  branchToStoreData.forEach(({ branchName, row }) => {
    // If the name isn't present create an error
    if (!setOfLegalBranchEntityNames.has(branchName)) {
      const err = createErrorObj(
        row,
        window.Locale.BRANCH_NAME,
        branchName,
        window.Locale.NO_SUCH_BRANCH_NAME,
        window.Locale.BRANCH_TO_STORE_MAPPING,
      );

      validation.isValid = false;
      validation.message.push(err);
    }
  });

  // =======================================================
  //  Every branchName in the branch entity must be used
  // =======================================================
  //
  //  Goal:
  //    Find all branchNames that are present in the branch listing entity and not present in the branchToStore mapping entity
  //
  //  Method:
  //    We accomplish this by doing the exact same thing we did to find the branchNames that are present on the branchToStore mapping entity but not present in the branch listing entity.
  //    We simply switch what we are looking up against and what we are iterating through.
  //      This means we make a lookup from branchListing then iterate through branchToStore mapping

  const mappingEntityNames = branchToStoreData.map(
    ({ branchName }) => branchName,
  );

  const setOfMappedNames = new Set(mappingEntityNames);
  branchData.forEach(({ branchName, row }) => {
    if (!setOfMappedNames.has(branchName)) {
      const err = createErrorObj(
        row,
        window.Locale.BRANCH_NAME,
        branchName,
        window.Locale.UNMAPPED_BRANCH_LISTING,
        window.Locale.BRANCH_LISTING,
      );

      validation.isValid = false;
      validation.message.push(err);
    }
  });

  return validation;
};
