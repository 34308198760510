import { get } from 'lodash';
import { SHEETNAMES, storeDeliveryStartingRowNumber } from '../consts';
import {
  dangerouslyCoerceToBooleanOrEmpty,
  getCellRefValue,
  getCellRefNumberValue,
  generateRowRange,
} from './spreadsheetHelpers';

export default function createStoreDeliveryGroupInfo(parsedData) {
  const deliveryGroups = [];
  const deliveryGroupRowNumbers = generateRowRange(
    parsedData,
    storeDeliveryStartingRowNumber,
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
  );

  // BUSINESS RULE, PART 1:
  // A delivery group MUST have either an as-needed or a weekly cadence configured.
  // Failure to configure either causes the delivery group setting to enter a bad state, and results in failures downstream.

  // BUSINESS RULE, PART 2:
  // You can create a branch without creating a store delivery if you set “storeDeliveryEnabled” to “true”.
  // Ergon will automatically add all stores assigned to that branch into one delivery group called “Needs Attention.”
  // The "Needs Attention" delivery group will have a default lead time of 10 days.

  function createDeliveryGroup(rowNumber) {
    return {
      row: rowNumber,
      branchName: getCellRefValue(parsedData, 'A', rowNumber),
      deliveryGroupName: getCellRefValue(parsedData, 'B', rowNumber),
      deliverToStoreAsNeeded: {
        deliverToStoreAsNeeded: dangerouslyCoerceToBooleanOrEmpty(
          getCellRefValue(parsedData, 'C', rowNumber),
          // sheetName, columnLetter, and rowNumber are ONLY used to generate a console message
          SHEETNAMES[2],
          'C',
          rowNumber,
        ),
        arrivalWithinBusinessDays: getCellRefNumberValue(
          parsedData,
          'D',
          rowNumber,
        ),
      },
      deliverToStoreOnWeeklyCadence: {
        deliverToStoreOnWeeklyCadence: dangerouslyCoerceToBooleanOrEmpty(
          getCellRefValue(parsedData, 'E', rowNumber),
          // sheetName, columnLetter, and rowNumber are ONLY used to generate a console message
          SHEETNAMES[2],
          'E',
          rowNumber,
        ),
        deliveryDay: {
          monday:
            getCellRefValue(parsedData, 'F', rowNumber).toLowerCase() === 'yes',
          tuesday:
            getCellRefValue(parsedData, 'G', rowNumber).toLowerCase() === 'yes',
          wednesday:
            getCellRefValue(parsedData, 'H', rowNumber).toLowerCase() === 'yes',
          thursday:
            getCellRefValue(parsedData, 'I', rowNumber).toLowerCase() === 'yes',
          friday:
            getCellRefValue(parsedData, 'J', rowNumber).toLowerCase() === 'yes',
        },
      },
      purchaseOrderDaysInAdvance: getCellRefNumberValue(
        parsedData,
        'K',
        rowNumber,
      ),
      minimumOrder: {
        minimumPurchaseOrderTotal: get(parsedData[`L${rowNumber}`], 'v', ''),
        minimumOrderFee: get(parsedData[`M${rowNumber}`], 'v', ''),
      },
      // rather than matching the pattern set in Ergon (which would allow the creation of invalid data),
      // we prevent these conflicts from occurring by explicitly setting storeDeliveryEnabled to true 
      // this means that if store delivery group information is present, storeDeliveryEnabled will ALWAYS be true
      storeDeliveryEnabled: true,
    };
  }

  deliveryGroupRowNumbers.forEach(function pushRowIntoArray(rowNumber) {
    // Because we're passing a third parameter (an array of columns) to the generateRowRange function above,
    // we need to perform an additional check to ensure that the row is not empty.

    // It looks a bit messy, but the below if statement checks for the presence of data
    // on any one of the properties on the delivery group object we're creating.

    // If that delivery group object contains any data at all,
    // we push that object into the delivery groups array.

    const dg = createDeliveryGroup(rowNumber);

    if (
      dg.branchName ||
      dg.deliveryGroupName ||
      dg.deliverToStoreAsNeeded.deliverToStoreAsNeeded ||
      dg.deliverToStoreAsNeeded.arrivalWithinBusinessDays ||
      dg.deliverToStoreOnWeeklyCadence.deliverToStoreOnWeeklyCadence ||
      dg.deliverToStoreOnWeeklyCadence.deliveryDay.monday ||
      dg.deliverToStoreOnWeeklyCadence.deliveryDay.tuesday ||
      dg.deliverToStoreOnWeeklyCadence.deliveryDay.wednesday ||
      dg.deliverToStoreOnWeeklyCadence.deliveryDay.thursday ||
      dg.deliverToStoreOnWeeklyCadence.deliveryDay.friday ||
      dg.purchaseOrderDaysInAdvance ||
      dg.minimumOrder.minimumPurchaseOrderTotal ||
      dg.minimumOrder.minimumOrderFee
    ) {
      deliveryGroups.push(createDeliveryGroup(rowNumber));
    }
  });

  return deliveryGroups;
}
