import { call, put, takeLatest, all } from 'redux-saga/effects';

import {
  AUTHENTICATE_USER_REQUEST,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
} from './userSessionActionTypes';
import {
  authenticateUserSuccess,
  authenticateUserFailure,
  logoutUserSuccess,
  logoutUserFailure,
  userSessionIdSuccess,
  userSessionIdFailure,
} from './userSessionActionCreators';
import {
  authenticateCookieRequest,
  userSessionIdRequest,
  userLogoutRequest,
} from './userSessionAJAX';
import { redirectToLogin } from '../protectedRoutesHooks';

// Exported for tests
export function* authenticateUser() {
  try {
    const userProfile = yield call(authenticateCookieRequest);
    yield put(authenticateUserSuccess(userProfile));
  } catch (e) {
    yield put(authenticateUserFailure(e));
  }
}

export function* logOutUser() {
  try {
    const response = yield userLogoutRequest();
    yield put(logoutUserSuccess(response));
  } catch (e) {
    yield put(logoutUserFailure(e));
  }
}

export function* getUserSessionId() {
  try {
    const { sessionId } = yield call(userSessionIdRequest);
    yield put(userSessionIdSuccess(sessionId));
  } catch (e) {
    yield put(userSessionIdFailure(e));
  }
}

export function* userSessionFlow() {
  yield call(authenticateUser);
  yield call(getUserSessionId);
}

export function* userSessionEndFlow() {
  yield call(logOutUser);
}

export function* userLoggedOutSuccessfully() {
  yield redirectToLogin();
}

function* userSessionSaga() {
  yield all([
    takeLatest(AUTHENTICATE_USER_REQUEST, userSessionFlow),
    takeLatest(LOGOUT_USER_REQUEST, userSessionEndFlow),
    takeLatest(LOGOUT_USER_SUCCESS, userLoggedOutSuccessfully),
  ]);
}

export default userSessionSaga;
