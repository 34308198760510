import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import Card from '../../common/card/card';
import FormattedAddress from '../../common/formatAddress/formatAddress';
import { BranchToStoreMapping } from './branchToStoreMapping.propType';
import { getBranchToStoreMappingData } from './redux/branchToStoreMappingSelectors';
import './branchToStoreMappingDataGrid.scss';

export const BranchToStoreMappingDataGrid = ({ branchToStoreData }) => {
  // We're going to cleanly handle nodata at a higher level;
  if (!branchToStoreData) return null;

  // PrimeReact does a lot of magic under the hood here, and unfortunately the docs aren't clear
  // rowData is a special object that is automagically passed in by PrimeReact; rowData's properties are then accessible via dot notation
  const addressTemplate = (rowData) => (
    <FormattedAddress
      street={rowData.storeData.address.street}
      city={rowData.storeData.address.city}
      state={rowData.storeData.address.state}
      zip={rowData.storeData.address.zip}
    />
  );

  const regionTemplate = (rowData) =>
    `${rowData.storeData.regionId} - ${rowData.storeData.regionName}`;

  const divisionTemplate = (rowData) =>
    `${rowData.storeData.divisionId} - ${rowData.storeData.divisionName}`;

  const marketTemplate = (rowData) =>
    `${rowData.storeData.marketId} - ${rowData.storeData.marketName}`;

  return (
    <Card header={window.Locale.BRANCH_TO_STORE_MAPPING}>
      <div className="branch-to-store-grid-mapping-container">
        <DataTable value={branchToStoreData} responsive>
          <Column
            field="storeData.storeNumber"
            header={window.Locale.STORE}
            headerClassName="cell header-cell minor-cell"
          />
          <Column
            field="branchName"
            header={window.Locale.BRANCH_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            field="deliveryGroupName"
            header={window.Locale.DELIVERY_GROUP}
            headerClassName="cell header-cell"
          />
          <Column
            body={addressTemplate}
            header={window.Locale.STORE_ADDRESS}
            headerClassName="cell header-cell"
          />
          <Column
            body={regionTemplate}
            header={window.Locale.REGION}
            headerClassName="cell header-cell"
          />
          <Column
            body={divisionTemplate}
            header={window.Locale.DIVISION}
            headerClassName="cell header-cell"
          />
          <Column
            body={marketTemplate}
            header={window.Locale.MARKET}
            headerClassName="cell header-cell"
          />
        </DataTable>
      </div>
    </Card>
  );
};

BranchToStoreMappingDataGrid.propTypes = {
  branchToStoreData: PropTypes.arrayOf(BranchToStoreMapping),
};

BranchToStoreMappingDataGrid.defaultProps = {
  branchToStoreData: [],
};

const mapStateToProps = (state) => ({
  branchToStoreData: getBranchToStoreMappingData(state),
});

export default connect(mapStateToProps)(BranchToStoreMappingDataGrid);
