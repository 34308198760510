import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { returnToHome } from 'common/returnToHome/redux/returnToHomeActionCreators';
import Button from '@quotecenter/component-library/Button';

export const ReuploadButton = () => {
  const dispatch = useDispatch();
  const goToHome = useCallback(() => {
    return dispatch(returnToHome());
  }, [dispatch]);

  return (
    <Button
      size="small"
      aria-label={window.Locale.REUPLOAD_FILE}
      onClick={goToHome}
    >
      <i className="icon_file-upload" />
      <span>{window.Locale.REUPLOAD_FILE}</span>
    </Button>
  );
};

export default ReuploadButton;
