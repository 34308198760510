import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PropTypes from 'prop-types';

const ValidationDisplayTable = ({ validationErrors }) => {
  return (
    <div className="validation-display-table-container">
      <DataTable value={validationErrors} responsive>
        <Column
          field="row"
          header={window.Locale.VALIDATION_ROW}
          headerClassName="cell header-cell row-number"
          bodyClassName="cell row-number"
        />
        <Column
          field="column"
          header={window.Locale.VALIDATION_COLUMN}
          headerClassName="cell header-cell minor-cell"
          bodyClassName="cell minor-cell"
        />
        <Column
          field="location"
          header={window.Locale.VALIDATION_LOCATION}
          headerClassName="cell header-cell"
          bodyClassName="cell location"
        />
        <Column
          field="value"
          header={window.Locale.VALIDATION_VALUE}
          headerClassName="cell header-cell"
          bodyClassName="cell body-cell bolded"
        />
        <Column
          field="error"
          header={window.Locale.VALIDATION_ERROR}
          headerClassName="cell header-cell"
          bodyClassName="cell body-cell is-error"
        />
      </DataTable>
    </div>
  );
};

ValidationDisplayTable.propTypes = {
  validationErrors: PropTypes.arrayOf(PropTypes.object),
};

ValidationDisplayTable.defaultProps = {
  validationErrors: [],
};

export default ValidationDisplayTable;
