import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import requiresQcAccessCredentialTemplate from 'util/requiresQcAccessCredentialTemplate';
import Card from '../../common/card/card';
import ContactDisplayTable from './contactDisplayTable/contactDisplayTable';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import {
  defaultSupplierInfo,
  supplierInfoType,
} from './supplierInfoDisplay.propTypes';
import { getPresentationalSupplierInformation } from './redux/supplierInformationSelectors';
import './supplierInfoDisplay.scss';

export const SupplierInfo = ({ supplierData }) => {
  // we're gonna handle no data at a higher level (that way we don't have a page full of the exact same message over and over again)
  if (!supplierData) return null;

  const { ediContact, idmContact, primaryContact } = supplierData;

  const phoneWithExtensionTemplate = (rowData) =>
    formatPhoneNumber(
      `${rowData.primaryContact.phoneNumber}`,
      `${rowData.primaryContact.phoneNumberExt}`,
    );

  return (
    <Card header="Supplier Information">
      <div className="supplier-info-grid vendor-info">
        <DataTable value={[supplierData]} responsive>
          <Column
            field="supplierAccountName"
            header={window.Locale.SUPPLIER_ACCOUNT}
            headerClassName="cell header-cell"
          />
          <Column
            field="primaryContact.name"
            header={window.Locale.MAIN_CONTACT}
            headerClassName="cell header-cell"
          />
          <Column
            field="primaryContact.title"
            header={window.Locale.TITLE}
            headerClassName="cell header-cell"
          />
          <Column
            body={phoneWithExtensionTemplate}
            header={window.Locale.PHONE}
            headerClassName="cell header-cell phone-cell"
          />
          <Column
            field="primaryContact.email"
            header={window.Locale.EMAIL}
            headerClassName="cell header-cell email-cell"
          />
          <Column
            field="primaryContact.requiresQcAccessCredentials"
            header={requiresQcAccessCredentialTemplate()}
            headerClassName="cell header-cell qc-cell-1"
          />
          <Column
            field="mVendorNumber"
            header={window.Locale.MVENDOR}
            headerClassName="cell header-cell number-cell"
          />
          <Column
            field="GLN"
            header={window.Locale.GLN}
            headerClassName="cell header-cell number-cell"
          />
        </DataTable>
      </div>
      <div className="supplier-info-grid primary-contact contact">
        <ContactDisplayTable
          contactInfo={primaryContact}
          contactNameTitle={window.Locale.PRIMARY_CONTACT_NAME}
        />
      </div>
      <div className="supplier-info-grid edi-contact contact">
        <ContactDisplayTable
          contactInfo={ediContact}
          contactNameTitle={window.Locale.EDI_CONTACT_NAME}
        />
      </div>
      <div className="supplier-info-grid idm-contact contact">
        <ContactDisplayTable
          contactInfo={idmContact}
          contactNameTitle={window.Locale.IDM_CONTACT_NAME}
        />
      </div>
    </Card>
  );
};

SupplierInfo.propTypes = {
  supplierData: supplierInfoType,
};

SupplierInfo.defaultProps = {
  supplierData: defaultSupplierInfo,
};

const mapStateToProps = (state) => ({
  supplierData: getPresentationalSupplierInformation(state),
});

export default connect(mapStateToProps)(SupplierInfo);
