import React from 'react';
import PropTypes from 'prop-types';
import './headerDrawer.scss';

const HeaderDrawer = ({ children, open }) => {
  if (!open) return null;

  return <div className="header-drawer">{children}</div>;
};

HeaderDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  open: PropTypes.bool,
};

HeaderDrawer.defaultProps = {
  children: [],
  open: false,
};

export default HeaderDrawer;
