import { findIndex } from 'lodash';
import { put, takeLatest, select, call, all } from 'redux-saga/effects';
import history from 'app/history';
import convertToJSON from 'util/parsers/convertFileTypeXLSXtoJSON';
import { isSheetValid } from 'features/validationDisplay/redux/validationDisplaySelectors';
import {
  FILE_UPLOADED,
  INVALID_FILE_PARSED,
  VALID_FILE_PARSED,
  FILE_PARSING_COMPLETED,
} from './fileUploadedActionTypes';
import { SHEETNAMES } from '../../consts';
import createSupplierInfo from '../../helpers/supplierInfo';
import createBranchInfo from '../../helpers/branchInfo';
import createStoreDeliveryGroupInfo from '../../helpers/storeDeliveryGroup';
import createJobSiteDeliveryInfo from '../../helpers/jobSiteDelivery';
import createBranchToStoreMappings from '../../helpers/branchToStoreMapping';
import {
  fileParsingStarted,
  fileParsingCompleted,
  invalidFileParsed,
  validFileParsed,
  fileValidationStarted,
  fileValidationCompleted,
  fileParsingFailed,
} from './fileUploadedActionCreators';
import { addBranchListingInformation } from '../branchListing/branchListingActionCreators';
import { addBranchToStoreInformation } from '../../../branchToStoreMapping/redux/branchToStoreMappingActionCreators';
import { addJobSiteDeliveryInformation } from '../jobSiteDelivery/jobSiteDeliveryActionCreators';
import { addStoreDeliveryInformation } from '../storeDelivery/storeDeliveryActionCreators';
import { addSupplierInformation } from '../../../supplierInfoDisplay/redux/supplierInformationActionCreators';
import { getBranchListingData } from '../branchListing/branchListingSelectors';
import { getCoordinatesForAllBranches } from '../branchListing/coordinates/redux/coordinatesActionCreators';

export function transformSheetname(sheetKeys, sheetName) {
  // JavaScript Object keys are case-sensitive, but we want to access these keys in a case-insensitive manner,
  // so we transform them all to uppercase; this is a lot of work for us, but makes the user experience better

  // Working from the innermost part of the function out...
  // 1. Uppercase all of the sheetanmes we get from the parser's allSheetData object as well as the (hard-coded) sheetname
  // 2. Use findIndex to get the (integer) index of the sheetname
  //    - first param: pass in the parser object with ALL the sheets
  //    - second param: pass in the function comparing the hard-coded sheet name and the sheetnames in the parser object
  //    - findIndex returns the integer of the sheetname in the parser object; store this in the variable "sheetIndex"

  const sheetIndex = findIndex(sheetKeys, function uppercaseSheetName(sheet) {
    return sheet.toUpperCase() === sheetName.toUpperCase();
  });

  return sheetKeys[sheetIndex] || false;
}

export function* parseJobsiteDeliveryData({ jobSiteDeliverySheetData }) {
  const jobSiteDeliveryInfo = yield createJobSiteDeliveryInfo(
    jobSiteDeliverySheetData,
  );

  yield put(addJobSiteDeliveryInformation(jobSiteDeliveryInfo));
}

export function* parseBranchToStoreMappingData({
  branchData,
  branchToStoreMappingSheetData,
}) {
  // // This relies on information from branchListing
  const branchToStoreMappingInfo = yield createBranchToStoreMappings(
    branchToStoreMappingSheetData,
    branchData,
  );

  yield put(addBranchToStoreInformation(branchToStoreMappingInfo));
}

export function* parseStoreDeliveryGroupData({ storeDeliverySheetData }) {
  const storeDeliveryInfo = yield createStoreDeliveryGroupInfo(
    storeDeliverySheetData,
  );

  yield put(addStoreDeliveryInformation(storeDeliveryInfo));
}

export function* parseBranchListingData({ branchListingSheetData }) {
  const branchInfo = yield createBranchInfo(branchListingSheetData);

  yield put(addBranchListingInformation(branchInfo));
}

export function* parseSupplierInformationData({ supplierListingSheetData }) {
  const supplierInfo = yield createSupplierInfo(supplierListingSheetData);

  yield put(addSupplierInformation(supplierInfo));
}

export function* validationSaga() {
  yield put(fileValidationStarted());

  const isValid = yield select(isSheetValid);

  yield put(fileValidationCompleted());

  // dispatch actions for observability
  if (isValid) {
    yield put(validFileParsed());
  } else {
    yield put(invalidFileParsed());
  }
}

export function* invalidFileSaga() {
  // This hook executes ONLY when we see a spreadsheet that...
  // Contains the correct sheetnames
  // AND those sheets also have validation errors
  yield history.push('/errors');
}

export function* validFileSaga() {
  // This hook executes ONLY when we see a spreadsheet that...
  // Contains the correct sheetnames
  // AND those sheets DO NOT contain validation errors
  yield history.push('/preview/summary');
  yield put(getCoordinatesForAllBranches());
}

export function* fileUploadedSaga({ fileData }) {
  try {
    yield put(fileParsingStarted());

    const allSheetData = convertToJSON(fileData, SHEETNAMES).Sheets;
    const allSheetDataObjectKeys = Object.keys(allSheetData);

    const jobSiteDeliverySheetData = yield allSheetData[
      transformSheetname(allSheetDataObjectKeys, 'Job Site Delivery Listing')
    ];
    const branchData = yield select(getBranchListingData);
    const branchToStoreMappingSheetData = yield allSheetData[
      transformSheetname(allSheetDataObjectKeys, 'Branch-to-Store')
    ];
    const storeDeliverySheetData = yield allSheetData[
      transformSheetname(allSheetDataObjectKeys, 'Store Delivery Group Listing')
    ];
    const branchListingSheetData = yield allSheetData[
      transformSheetname(allSheetDataObjectKeys, 'Branch Listing')
    ];
    const supplierListingSheetData = yield allSheetData[
      transformSheetname(allSheetDataObjectKeys, 'Vendor Listing')
    ];

    yield call(parseSupplierInformationData, { supplierListingSheetData });
    yield call(parseBranchListingData, { branchListingSheetData });
    yield call(parseStoreDeliveryGroupData, { storeDeliverySheetData });
    yield call(parseJobsiteDeliveryData, { jobSiteDeliverySheetData });
    yield call(parseBranchToStoreMappingData, {
      branchData,
      branchToStoreMappingSheetData,
    });

    yield put(fileParsingCompleted());
  } catch (error) {
    // This hook executes ONLY when we see a spreadsheet that...
    // DOES NOT contain the correct sheetnames
    yield put(fileParsingFailed(error.message));
  }
}

export function* fileUploadingAndValidationSaga() {
  yield all([
    takeLatest(FILE_UPLOADED, fileUploadedSaga),
    takeLatest(INVALID_FILE_PARSED, invalidFileSaga),
    takeLatest(VALID_FILE_PARSED, validFileSaga),
    takeLatest(FILE_PARSING_COMPLETED, validationSaga),
  ]);
}

export default fileUploadingAndValidationSaga;
