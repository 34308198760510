import {
  ONBOARD_SUPPLIER_FAILURE,
  ONBOARD_SUPPLIER_REQUEST,
  ONBOARD_SUPPLIER_SUCCESS,
  ONBOARD_SUPPLIER_PROGRESS,
  ONBOARD_SUPPLIER_CANCEL_REQUEST,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILURE,
  RESET_ONBOARDING_STATE,
} from './onboardSupplierActionTypes';

export const onboardSupplierCancelRequest = () => ({
  type: ONBOARD_SUPPLIER_CANCEL_REQUEST,
});

export const resetOnboardingState = () => ({
  type: RESET_ONBOARDING_STATE,
});

export const onboardSupplierRequest = () => ({
  type: ONBOARD_SUPPLIER_REQUEST,
});

export const onboardSupplierSuccess = (supplierId) => ({
  type: ONBOARD_SUPPLIER_SUCCESS,
  supplierId,
});

export const onboardSupplierError = (error) => ({
  type: ONBOARD_SUPPLIER_FAILURE,
  error,
});

export const onboardSupplierProgress = (stepName) => ({
  type: ONBOARD_SUPPLIER_PROGRESS,
  stepName,
});

export const deleteSupplierRequest = (supplierId, attempt) => ({
  type: DELETE_SUPPLIER_REQUEST,
  supplierId,
  attempt,
});

export const deleteSupplierSuccess = () => ({
  type: DELETE_SUPPLIER_SUCCESS,
});

export const deleteSupplierFailure = (error, supplierId) => ({
  type: DELETE_SUPPLIER_FAILURE,
  error,
  supplierId,
});
