export const emailValidator = (val) => {
  // This is a very permissive email regex.
  // it matches anystring@anstring.anystring
  // This means that if it looks even vaguely like an email address this will work.
  // This means that we will never get a false positives
  // (calling something that is an email not an email.)
  // But we will get false negatives
  // (calling something that isn't an email an email.)

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  return emailRegex.test(val);
};
