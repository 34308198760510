import {
  ONBOARD_SUPPLIER_FAILURE,
  ONBOARD_SUPPLIER_SUCCESS,
  ONBOARD_SUPPLIER_REQUEST,
  ONBOARD_SUPPLIER_PROGRESS,
  RESET_ONBOARDING_STATE,
} from './onboardSupplierActionTypes';

export const progressStatuses = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};
export const PROGRESS_STEPS = {
  CREATING_SUPPLIER: {
    name: 'CREATING_SUPPLIER',
    order: 1,
  },
  CREATING_BRANCHES: {
    name: 'CREATING_BRANCHES',
    order: 2,
  },
  SETTING_MERCHANT_CLASS: {
    name: 'SETTING_MERCHANT_CLASS',
    order: 3,
  },
  SETTING_MARKET_CLASS: {
    name: 'SETTING_MARKET_CLASS',
    order: 4,
  },
  SETTING_JOBSITE_DELIVERY: {
    name: 'SETTING_JOBSITE_DELIVERY',
    order: 5,
  },
  SETTING_INSTANT_PRICING: {
    name: 'SETTING_INSTANT_PRICING',
    order: 6,
  },
  MAPPING_BRANCHES_TO_STORES: {
    name: 'MAPPING_BRANCHES_TO_STORES',
    order: 7,
  },
  CREATING_DELIVERY_GROUPS: {
    name: 'CREATING_DELIVERY_GROUPS',
    order: 8,
  },
  MAPPING_STORES_TO_DELIVERY_GROUPS: {
    name: 'MAPPING_STORES_TO_DELIVERY_GROUPS',
    order: 9,
  },
  CREATION_COMPLETE: {
    name: 'CREATION_COMPLETE',
    order: 10,
  },
};

export const initializeState = () => ({
  error: null,
  status: null,
  step: null,
  createdSupplierId: null,
});

const onboardSupplierReducer = (state = initializeState(), action) => {
  switch (action.type) {
    case ONBOARD_SUPPLIER_REQUEST: {
      return { ...state, error: null, status: progressStatuses.PROCESSING };
    }
    case ONBOARD_SUPPLIER_FAILURE: {
      const { error } = action;
      return { ...state, status: progressStatuses.FAILED, error };
    }
    case ONBOARD_SUPPLIER_SUCCESS: {
      const { supplierId } = action;
      return {
        ...state,
        status: progressStatuses.COMPLETED,
        createdSupplierId: supplierId,
      };
    }
    case RESET_ONBOARDING_STATE: {
      return initializeState();
    }
    case ONBOARD_SUPPLIER_PROGRESS: {
      const { stepName } = action;
      return { ...state, step: stepName };
    }
    default:
      return state;
  }
};

export default onboardSupplierReducer;
