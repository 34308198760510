import React from 'react';
import './notFound.scss';

const NotFound = () => (
  <div className="not-found">
    <h1>404</h1>
    <h2>Page not found.</h2>
  </div>
);

export default NotFound;
