import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import ReadFile from '../../util/parsers/readFile';
import { fileUploaded } from './redux/fileUploaded/fileUploadedActionCreators';
import {
  getFileUploadError,
  getFileName,
} from './redux/fileUploaded/fileUploadedSelector';
import FileInput from '../../common/fileInput/fileInput';
import './parseOnboardingSpreadsheet.scss';

export const ParseOnboardingSpreadsheet = (props) => {
  const { parseUploadedFile } = props;

  const uploadErrorMessage = useSelector(getFileUploadError);
  const fileName = useSelector(getFileName);

  const onFileUploaded = (file) => {
    ReadFile(file.files[0]).then((fileData) => {
      parseUploadedFile(fileData, file.files[0].name);
    });
  };

  return (
    <div className="parse-spreadsheet">
      <div className="file-input-wrapper">
        <h2>{window.Locale.ADD_NEW_SUPPLIER}</h2>
        <FileInput
          uploadedFileName={fileName}
          fileUploaded={onFileUploaded}
          uploadErrorMessage={uploadErrorMessage}
          labelText={window.Locale.UPLOAD_NEW_SPREADSHEET}
        />
      </div>
    </div>
  );
};

ParseOnboardingSpreadsheet.propTypes = {
  parseUploadedFile: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  parseUploadedFile: (fileData, name) => {
    dispatch(fileUploaded(fileData, name));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(ParseOnboardingSpreadsheet);
