import PropTypes from 'prop-types';

export const BranchToStoreMapping = PropTypes.shape({
  branchName: PropTypes.string.isRequired,
  deliveryGroupName: PropTypes.string.isRequired,
  storeData: PropTypes.shape({
    storeNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    regionName: PropTypes.string.isRequired,
    divisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    divisionName: PropTypes.string.isRequired,
    marketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    marketName: PropTypes.string.isRequired,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
});
