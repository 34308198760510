import { get, omit, uniq, includes, range } from 'lodash';

export const dangerouslyCoerceToBooleanOrEmpty = (
  value,
  sheetName,
  columnLetter,
  rowNumber,
) => {
  // This function is used when parsing the "Branch Listing" and "Store Delivery Group Listing" tab.
  // Because SOT parses all of the spreadsheet data before performing validation, the values of "yes" and "no"
  // passed to this function will be coerced and then passed to our validation functions.
  // This increases the likelihood that an invalid value could slip through.

  // For this reason, we have an emergency out: We console log the value and the location in the spreadsheet.
  // The sheetName, columnLetter, and rowNumber are ONLY used to generate the console message.

  // convert the value to lowercase so that we don't have to match specific casing
  const lowercasedValue = value.toLowerCase();

  if (lowercasedValue === 'yes') {
    // value is coerced to true; this value is likely valid
    return true;
  } else if (lowercasedValue === 'no') {
    // value is coerced to false; this value is likely valid
    return false;
  } else if (value === '') {
    // value is an empty string; this value is likely valid
    return value;
  } else {
    // value is NOT yes/no/empty; this value is likely invalid
    // eslint-disable-next-line no-console
    console.log(
      `Received ${value} on sheet ${sheetName}, cell ${columnLetter}${rowNumber}. This value may be invalid.`,
    );
    return value;
  }
};

export function getCellRefValue(parsedData, columnLetter, rowNumber) {
  // convert the cell contents to a string to prevent numerical strings from being accidentally cast to integers,
  // then use trim() to ensure that any trailing spaces are not preserved
  return String(get(parsedData[`${columnLetter}${rowNumber}`], 'w', '')).trim();
}

export function getCellRefNumberValue(parsedData, columnLetter, rowNumber) {
  // if the cell we are parsing is left blank, the function will return a value of zero
  return Number(get(parsedData[`${columnLetter}${rowNumber}`], 'w', ''));
}

export function getSheetRange(parsedData) {
  // will return a string with the column letter and row number of the first (top left) cell in the range
  // followed by a colon (:), then the column letter and row number of the last (bottom right) cell in the range
  // example: A2:O27
  return parsedData['!ref'];
}

export function getmaxRowNumber(parsedData) {
  // split the string at the colon, then slice off the first character (which represents the column letter);
  // will return a number representing the upper bound of a given sheet's rows
  const sheetRange = getSheetRange(parsedData);
  return sheetRange.split(':')[1].slice(1);
}

export function rowNumberHasData(parsedData, rowNumber) {
  // parsedData is a JSON object representing a given sheet;
  // it contains a sheet range object and a sheet margin object
  // we do not need these, so we remove them from the object
  const sheetData = omit(parsedData, '!ref', '!margins');

  // sheetData now contains a sub-object for each non-empty cell
  // (ie, the value of a "empty" cell is not not null or undefined)
  // we create an empty array called cellsWithData
  const cellsWithData = [];

  Object.keys(sheetData).forEach(function sliceOffColumnLetter(cellRef) {
    // slice the column letter off the front of the cell reference to get the row number
    // 1 represents the first character in the string (the column letter); 10 is the radix
    // then use the parseInt function to convert the rowNumber to integer format
    // finally, push the integer for each rowNumber into the cellsWithData array
    cellsWithData.push(parseInt(cellRef.slice(1), 10));
  });

  // filter out duplicate row numbers
  const rowsWithData = uniq(cellsWithData);

  // if the rowNumber is not in integer format, convert it; 10 is the radix
  const rowNumberAsInteger = parseInt(rowNumber, 10);

  return includes(rowsWithData, rowNumberAsInteger);
}

const getMaxRowForColumns = (parsedData, startingRowNumber, columns) => {
  // Turns out this is a pretty classic CS problem

  // Create a set of column letters that we are interested in
  const columnSet = new Set(columns);

  // get keys
  // these are in battleship notation "b4", but include !ref and !margin
  const keys = Object.keys(parsedData);

  // Now we iterate. We're going to find the max in one pass, textbook CS
  let max = startingRowNumber;
  keys.forEach((key) => {
    const letter = key.slice(0, 1);
    const number = Number(key.slice(1));

    // If the number is a number (NaN is falsy) and the letter is something we're concerned about
    if (number && columnSet.has(letter)) {
      if (number > max) max = number;
    }
  });

  return max;
};

export function generateRowRange(parsedData, startingRowNumber, columns) {
  // This will need to be changed slightly if we are dealing with 26 or more columns.
  // Currently we're assuming the letter is 1 character. We'd have two letter columns at 27.
  // If needed we can adapt the strategy for that. Until then, this is a fine solution

  if (columns) {
    return range(
      startingRowNumber,
      // Lodash range function is not inclusive of the second parameter provided
      // example: _.range(1,5) returns [1,2,3,4]
      getMaxRowForColumns(parsedData, startingRowNumber, columns) + 1,
    );
  }
  // use getmaxRowNumber to determine the last filled-out row in the sheet;
  // use parseInt to convert that row number to integer format; 10 is the radix
  // add 1 to this number to compensate for array indexes starting with 0 (not 1)
  // use loadash range function to generate an array of row numbers
  // we'll pass the output of this function to a forEach
  return range(
    startingRowNumber,
    parseInt(getmaxRowNumber(parsedData), 10) + 1,
  );
}
