import { validationObjInit } from './validationObjInit';
import { existenceValidator } from './existenceValidator';
import { nonEmptyValidator } from './nonEmptyValidator';
import { numericalStringValidator } from './numericalStringValidator';
import { enumValidator } from './enumValidator';
import { integerValidator } from './integerValidator';
import { createErrorObj } from '../helpers/createErrorObj';

export const deliveryGroupValidator = (deliveryGroup) => {
  // we use this to track the validation state of the whole entity.
  const deliveryGroupValidation = validationObjInit();
  deliveryGroupValidation.message = [];

  // ============================================
  //  Name Validations
  // ============================================

  // ======================
  // Branch Name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================

  const branchNameValidation = validationObjInit();

  // Must exist and be at least one character
  if (
    !existenceValidator(deliveryGroup.branchName) ||
    !nonEmptyValidator(deliveryGroup.branchName)
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.DELIVERY_GROUP_BRANCH_NAME,
      deliveryGroup.branchName,
      window.Locale.NO_BRANCH_NAME,
    );

    branchNameValidation.isValid = false;
    branchNameValidation.message = message;

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // ======================
  // Delivery Group Name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================

  const deliveryGroupNameValidation = validationObjInit();

  // Must exist and be at least one character
  if (
    !existenceValidator(deliveryGroup.deliveryGroupName) ||
    !nonEmptyValidator(deliveryGroup.deliveryGroupName)
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.DELIVERY_GROUP_NAME,
      deliveryGroup.deliveryGroupName,
      window.Locale.NO_DELIVERY_GROUP_NAME,
    );

    deliveryGroupNameValidation.isValid = false;
    deliveryGroupNameValidation.message = message;

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // ============================================
  //  Number Validations
  // ============================================

  // ======================
  // Purchase days in advance must:
  //   - Be a positive whole number
  //   - Optional
  //   - Be less than or equal to 60 if present
  // ======================

  const purchaseOrderDaysInAdvanceValidation = validationObjInit();

  // Must be a number if it exists
  // Optional
  if (
    existenceValidator(deliveryGroup.purchaseOrderDaysInAdvance) &&
    nonEmptyValidator(deliveryGroup.purchaseOrderDaysInAdvance)
  ) {
    if (!numericalStringValidator(deliveryGroup.purchaseOrderDaysInAdvance)) {
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
        deliveryGroup.purchaseOrderDaysInAdvance,
        window.Locale.PURCHASE_DAYS_IN_ADVANCE_MUST_BE_NUMBER,
      );

      purchaseOrderDaysInAdvanceValidation.isValid = false;
      purchaseOrderDaysInAdvanceValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    } else {
      // catch negative numbers
      if (Number(deliveryGroup.purchaseOrderDaysInAdvance) <= 0) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
          deliveryGroup.purchaseOrderDaysInAdvance,
          window.Locale.FIELD_MUST_BE_POSITIVE_NUM(
            window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
          ),
        );

        purchaseOrderDaysInAdvanceValidation.isValid = false;
        purchaseOrderDaysInAdvanceValidation.message = message;

        deliveryGroupValidation.isValid = false;
        deliveryGroupValidation.message.push(message);
      }

      // must be less than 60
      if (Number(deliveryGroup.purchaseOrderDaysInAdvance) > 60) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
          deliveryGroup.purchaseOrderDaysInAdvance,
          window.Locale.PURCHASE_DAYS_IN_ADVANCE_MUST_BE_LESS_THAN_60,
        );

        purchaseOrderDaysInAdvanceValidation.isValid = false;
        purchaseOrderDaysInAdvanceValidation.message = message;

        deliveryGroupValidation.isValid = false;
        deliveryGroupValidation.message.push(message);
      }

      // catch decimal numbers
      if (!integerValidator(deliveryGroup.purchaseOrderDaysInAdvance)) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
          deliveryGroup.purchaseOrderDaysInAdvance,
          window.Locale.FIELD_MUST_BE_WHOLE_NUM(
            window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE,
          ),
        );

        purchaseOrderDaysInAdvanceValidation.isValid = false;
        purchaseOrderDaysInAdvanceValidation.message = message;

        deliveryGroupValidation.isValid = false;
        deliveryGroupValidation.message.push(message);
      }
    }
  }

  // ======================
  // arrivalWithinBusinessDays in advance must:
  //   - Be a positive whole number
  //   - Optional
  // ======================

  const arrivalWithinBusinessDaysValidation = validationObjInit();

  // Must be a number if it exists
  // Optional
  if (
    existenceValidator(
      deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
    ) &&
    nonEmptyValidator(
      deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
    )
  ) {
    if (
      !numericalStringValidator(
        deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
      )
    ) {
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS,
        deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
        window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS_MUST_BE_A_NUMBER,
      );

      arrivalWithinBusinessDaysValidation.isValid = false;
      arrivalWithinBusinessDaysValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    } else {
      // catch negative numbers
      if (
        Number(
          deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
        ) <= 0
      ) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS,
          deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
          window.Locale.FIELD_MUST_BE_POSITIVE_NUM(
            window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS,
          ),
        );

        arrivalWithinBusinessDaysValidation.isValid = false;
        arrivalWithinBusinessDaysValidation.message = message;

        deliveryGroupValidation.isValid = false;
        deliveryGroupValidation.message.push(message);
      }
      // catch decimal numbers
      if (
        !integerValidator(
          deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
        )
      ) {
        const message = createErrorObj(
          deliveryGroup.row,
          window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS,
          deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
          window.Locale.FIELD_MUST_BE_WHOLE_NUM(
            window.Locale.ARRIVAL_WITHIN_BUSINESS_DAYS,
          ),
        );

        arrivalWithinBusinessDaysValidation.isValid = false;
        arrivalWithinBusinessDaysValidation.message = message;

        deliveryGroupValidation.isValid = false;
        deliveryGroupValidation.message.push(message);
      }
    }
  }

  // ======================
  // minimum purchase order must:
  //   - Be a positive number
  //   - Optional
  // ======================

  const minimumPurchaseOrderValidation = validationObjInit();

  // Must be a number if it exists
  // Optional
  if (
    existenceValidator(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal) &&
    nonEmptyValidator(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal)
  ) {
    if (
      !numericalStringValidator(
        deliveryGroup.minimumOrder.minimumPurchaseOrderTotal,
      )
    ) {
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.MINIMUM_PURCHASE_ORDER_TOTAL,
        deliveryGroup.minimumOrder.minimumPurchaseOrderTotal,
        window.Locale.MINIMUM_PO_MUST_BE_A_NUMBER,
      );

      minimumPurchaseOrderValidation.isValid = false;
      minimumPurchaseOrderValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    } else if (
      Number(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal) <= 0
    ) {
      // catch negative numbers
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.MINIMUM_PURCHASE_ORDER_TOTAL,
        deliveryGroup.minimumOrder.minimumPurchaseOrderTotal,
        window.Locale.FIELD_MUST_BE_POSITIVE_NUM(
          window.Locale.MINIMUM_PURCHASE_ORDER,
        ),
      );

      minimumPurchaseOrderValidation.isValid = false;
      minimumPurchaseOrderValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    }
  }

  // ======================
  // minimum order fee must:
  //   - Be a number
  //   - Optional
  // ======================

  const minimumOrderFeeValidation = validationObjInit();

  // Must be a number if it exists
  // Optional
  if (
    existenceValidator(deliveryGroup.minimumOrder.minimumOrderFee) &&
    nonEmptyValidator(deliveryGroup.minimumOrder.minimumOrderFee)
  ) {
    if (!numericalStringValidator(deliveryGroup.minimumOrder.minimumOrderFee)) {
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.MINIMUM_ORDER_FEE,
        deliveryGroup.minimumOrder.minimumOrderFee,
        window.Locale.MINIMUM_ORDER_FEE_MUST_BE_A_NUMBER,
      );

      minimumOrderFeeValidation.isValid = false;
      minimumOrderFeeValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    } else if (Number(deliveryGroup.minimumOrder.minimumOrderFee) <= 0) {
      // catch negative numbers
      const message = createErrorObj(
        deliveryGroup.row,
        window.Locale.MINIMUM_ORDER_FEE,
        deliveryGroup.minimumOrder.minimumOrderFee,
        window.Locale.FIELD_MUST_BE_POSITIVE_NUM(
          window.Locale.MINIMUM_ORDER_FEE,
        ),
      );

      minimumOrderFeeValidation.isValid = false;
      minimumOrderFeeValidation.message = message;

      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(message);
    }
  }

  // ============================================
  //  Boolean Validations
  // ============================================

  // ======================
  // each day of the week must:
  //   - Be a True, False or Empty.
  //   - Optional
  // ======================

  const {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
  } = deliveryGroup.deliverToStoreOnWeeklyCadence.deliveryDay;

  const mondayValidation = enumValidator(monday, [true, false, '']);
  const tuesdayValidation = enumValidator(tuesday, [true, false, '']);
  const wednesdayValidation = enumValidator(wednesday, [true, false, '']);
  const thursdayValidation = enumValidator(thursday, [true, false, '']);
  const fridayValidation = enumValidator(friday, [true, false, '']);

  [
    mondayValidation,
    tuesdayValidation,
    wednesdayValidation,
    thursdayValidation,
    fridayValidation,
  ].forEach((val) => {
    if (!val.isValid) {
      deliveryGroupValidation.isValid = false;
      deliveryGroupValidation.message.push(
        createErrorObj(
          deliveryGroup.row,
          window.Locale.DAY_OF_THE_WEEK,
          '',
          val.message,
        ),
      );
    }
  });

  // ======================
  // deliver to store as need enabled must:
  //   - Be a True, False or Empty.
  //   - Optional
  // ======================

  const deliverToStoreAsNeededValidation = enumValidator(
    deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded,
    [true, false, ''],
  );
  if (!deliverToStoreAsNeededValidation.isValid) {
    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(
      createErrorObj(
        deliveryGroup.row,
        window.Locale.DELIVER_TO_STORE_AS_NEEDED,
        deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded,
        deliverToStoreAsNeededValidation.message,
      ),
    );
  }

  // ======================
  // deliver to store on weekly cadence enabled must:
  //   - Be a True, False or Empty.
  //   - Optional
  // ======================

  const deliverToStoreOnWeeklyCadenceValidation = enumValidator(
    deliveryGroup.deliverToStoreOnWeeklyCadence.deliverToStoreOnWeeklyCadence,
    [true, false, ''],
  );
  if (!deliverToStoreOnWeeklyCadenceValidation.isValid) {
    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(
      createErrorObj(
        deliveryGroup.row,
        window.Locale.DELIVER_TO_STORE_ON_WEEKLY_CADENCE,
        deliveryGroup.deliverToStoreOnWeeklyCadence
          .deliverToStoreOnWeeklyCadence,
        deliverToStoreOnWeeklyCadenceValidation.message,
      ),
      deliverToStoreOnWeeklyCadenceValidation.message,
    );
  }

  // ======================
  // store delivery enabled must:
  //   - Be a True, False or Empty.
  // ======================

  const storeDeliveryEnabledValidation = enumValidator(
    deliveryGroup.storeDeliveryEnabled,
    [true, false, ''],
  );
  if (!storeDeliveryEnabledValidation.isValid) {
    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(
      createErrorObj(
        deliveryGroup.row,
        window.Locale.STORE_DELIVERY_ENABLED,
        deliveryGroup.storeDeliveryEnabled,
        window.Locale.STORE_DELIVERY_ENABLED_ERROR(
          deliveryGroup.storeDeliveryEnabled,
        ),
      ),
    );
  }

  // ============================================
  //  Meta Validation
  // ============================================

  // ======================
  // Delivery Weekly
  //   1. If we have a delivery day, we should have deliver to store on a weekly cadence
  //   2. If we have no delivery day, we should not have deliver to store on a weekly cadence
  //   3. If we have a purchase days in advance, we should have deliver to store on a weekly cadence
  //   4. If we have no purchase days in advance, we should not have deliver to store on a weekly cadence
  // ======================

  const {
    deliverToStoreOnWeeklyCadence,
    deliveryDay,
  } = deliveryGroup.deliverToStoreOnWeeklyCadence;

  // This is how we check all of the delivery days very easily.
  const filtered = [
    deliveryDay.monday,
    deliveryDay.tuesday,
    deliveryDay.wednesday,
    deliveryDay.thursday,
    deliveryDay.friday,
  ].filter((val) => {
    return val === true;
  });

  // Case 1:
  // Check if we have a weekly cadence. If we do, make sure we have a delivery day.
  // If we don't have filtered.length we had no deliveryDays set to true.
  if (deliverToStoreOnWeeklyCadence && filtered.length === 0) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.CADENCE_DELIVERY_SETTINGS,
      '',
      window.Locale.WEEKLY_DELIV_INFO_MISSING,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // Case 2:
  // Check if we have a weekly cadence. If we don't, make sure we don't have a delivery day.
  // If we don't have filtered.length we have delivery days.
  if (!deliverToStoreOnWeeklyCadence && filtered.length !== 0) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.CADENCE_DELIVERY_SETTINGS,
      '',
      window.Locale.WEEKLY_DELIV_INFO_WITHOUT_WEEKLY_DELIV,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // Case 3:
  // Check if we have a weekly cadence. If we don't, make sure we don't have a purchase days in advance
  if (
    !deliverToStoreOnWeeklyCadence &&
    existenceValidator(deliveryGroup.purchaseOrderDaysInAdvance) &&
    existenceValidator(deliveryGroup.purchaseOrderDaysInAdvance) &&
      nonEmptyValidator(deliveryGroup.purchaseOrderDaysInAdvance)
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.CADENCE_DELIVERY_SETTINGS,
      '',
      window.Locale.WEEKLY_DELIV_INFO_WITHOUT_WEEKLY_DELIV,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // Case 4:
  // Check if we have a weeekly cadence. If we do, make sure we have a purchase days in advance
  if (
    deliverToStoreOnWeeklyCadence &&
    (!existenceValidator(deliveryGroup.purchaseOrderDaysInAdvance) ||
      !nonEmptyValidator(deliveryGroup.purchaseOrderDaysInAdvance))
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.CADENCE_DELIVERY_SETTINGS,
      '',
      window.Locale.WEEKLY_DELIV_INFO_MISSING,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // ======================
  // Delivery Pattern
  //   1. If we have a delivery as needed and weekly delivery it is invalid
  //   2. It we have neither delivery as need nor weekly delivery it is invalid
  // ======================

  // Case 1:
  // If we have both it is invalid
  if (
    deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded &&
    deliveryGroup.deliverToStoreOnWeeklyCadence.deliverToStoreOnWeeklyCadence
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.DELIVERY_SETTINGS,
      '',
      window.Locale.CANT_BE_BOTH_WEEKLY_AND_AS_NEEDED_DELIVERY,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // Case 2:
  // If we have neither it is invalid
  if (
    !deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded &&
    !deliveryGroup.deliverToStoreOnWeeklyCadence.deliverToStoreOnWeeklyCadence
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.DELIVERY_SETTINGS,
      '',
      window.Locale.MUST_BE_EITHER_WEEKLY_OR_AS_NEEDED_DELIVERY,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // ======================
  // Delivery as needed
  //   1. If we have a delivery as needed and don't have arrivalWithinBusinessDays, it is invalid
  //   2. It we have arrivalWithinBusinessDays without delivery as needed, it is invalid
  // ======================

  // Case 1:
  if (
    deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded &&
    (!existenceValidator(
      deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
    ) ||
      !nonEmptyValidator(
        deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
      ))
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.AS_NEEDED_DELIVERY_SETTINGS,
      '',
      window.Locale.AS_NEEDED_DELIV_INFO_MISSING,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // Case 2:
  if (
    !deliveryGroup.deliverToStoreAsNeeded.deliverToStoreAsNeeded &&
    existenceValidator(
      deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
    ) &&
      nonEmptyValidator(
        deliveryGroup.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
      )
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.AS_NEEDED_DELIVERY_SETTINGS,
      '',
      window.Locale.AS_NEEDED_INFO_WITHOUT_AS_NEEDED_DELIV,
    );

    deliveryGroupValidation.isValid = false;
    deliveryGroupValidation.message.push(message);
  }

  // ======================
  // Minimum Order Block
  // If one of the two values is present, but the other is missing, it is invalid
  // ======================

  // minimumPurchaseOrderTotal is defined, but minimumOrderFee is not
  if (
    existenceValidator(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal) &&
    nonEmptyValidator(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal) &&
    (!existenceValidator(deliveryGroup.minimumOrder.minimumOrderFee) ||
      !nonEmptyValidator(deliveryGroup.minimumOrder.minimumOrderFee))
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.MINIMUM_ORDER_FEE,
      '',
      window.Locale
        .MINIMUM_PURCHASE_ORDER_TOTAL_PRESENT_BUT_NO_MINIMUM_ORDER_FEE,
    );

    deliveryGroupValidation.message.push(message);
    deliveryGroupValidation.isValid = false;
  }

  // minimumOrderFee is defined, but minimumPurchaseOrderTotal is not
  if (
    existenceValidator(deliveryGroup.minimumOrder.minimumOrderFee) &&
    nonEmptyValidator(deliveryGroup.minimumOrder.minimumOrderFee) &&
    (!existenceValidator(
      deliveryGroup.minimumOrder.minimumPurchaseOrderTotal,
    ) ||
      !nonEmptyValidator(deliveryGroup.minimumOrder.minimumPurchaseOrderTotal))
  ) {
    const message = createErrorObj(
      deliveryGroup.row,
      window.Locale.MINIMUM_PURCHASE_ORDER_TOTAL,
      '',
      window.Locale
        .MINIMUM_ORDER_FEE_PRESENT_BUT_NO_MINIMUM_PURCHASE_ORDER_TOTAL,
    );

    deliveryGroupValidation.message.push(message);
    deliveryGroupValidation.isValid = false;
  }

  return {
    validation: deliveryGroupValidation,
    branchName: branchNameValidation,
    deliveryGroupName: deliveryGroupNameValidation,
    purchaseOrderDaysInAdvance: purchaseOrderDaysInAdvanceValidation,
    deliverToStoreAsNeeded: {
      arrivalWithinBusinessDays: arrivalWithinBusinessDaysValidation,
      deliverToStoreAsNeeded: deliverToStoreAsNeededValidation,
    },
    minimumOrder: {
      minimumPurchaseOrder: minimumPurchaseOrderValidation,
      minimumOrderFee: minimumOrderFeeValidation,
    },
    deliverToStoreOnWeeklyCadence: {
      deliverToStoreOnWeeklyCadence: deliverToStoreOnWeeklyCadenceValidation,
      deliveryDay: {
        monday: mondayValidation,
        tuesday: tuesdayValidation,
        wednesday: wednesdayValidation,
        thursday: thursdayValidation,
        friday: fridayValidation,
      },
    },
    storeDeliveryEnabled: storeDeliveryEnabledValidation,
  };
};
