import { map, each, uniq } from 'lodash';
import { validationObjInit } from './validators/validationObjInit';
import { branchToStoreMappingValidator } from './validators/branchToStoreMappingValidator';
import { createErrorObj } from './helpers/createErrorObj';

export const validateBranchToStoreMappingData = (
  branchToStoreMappingData,
  jobSiteData,
) => {
  if (!branchToStoreMappingData || !jobSiteData) return {};

  // we use this to track the validation state of the whole entity.
  const branchToStoreMappingValidation = validationObjInit();
  branchToStoreMappingValidation.message = [];

  // ============================================
  //  Branch Listing Level Validations
  // ============================================

  // ======================
  //  Validate all branch-to-store-mappings
  // ======================
  // Branch-to-store mappings are stored as an array. We iterate through them and validate each individually
  const validatedData = [];

  each(branchToStoreMappingData, (mapping) => {
    validatedData.push(branchToStoreMappingValidator(mapping));
  });

  // ======================
  //  Pull the messaging and errors out
  // ======================
  // We now have an array of validation objects,
  // we iterate look through them to find any errors and lift them out to be easily used within the UI
  validatedData
    .filter((val) => {
      return val.validation.message.length > 0;
    })
    .forEach((invalidItem) => {
      if (
        branchToStoreMappingValidation.isValid &&
        !invalidItem.validation.isValid
      ) {
        branchToStoreMappingValidation.isValid = false;
      }
      branchToStoreMappingValidation.message.push(
        ...invalidItem.validation.message,
      );
    });

  // ============================================
  //  Meta Validation
  // ============================================

  // Store ids must be unique
  const storeIds = branchToStoreMappingData.map(
    (mapping) => mapping.storeData.storeNumber,
  );

  const duplicateStoreIds = storeIds
    .reduce(
      // checks for duplicates inside the storeIds array
      // if duplicates are found, it returns an array of duplicate values (with each duplicate appearing only once)
      // then use Array.join() to transform the array of duplicates into a comma-separated string
      (acc, el, i, arr) => {
        // If the index of the element in the array is not our current index. We know we have a duplicate element.
        // If that duplicate element is not in our accumulator (.indexOf(el) returns -1 if it doesn't find an item)
        // add it to our accumulator
        if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
        return acc;
      },
      [],
    )
    .join(', ');

  if (uniq(storeIds).length !== storeIds.length) {
    const message = createErrorObj(
      '--',
      window.Locale.STORE_NUMBER,
      duplicateStoreIds,
      window.Locale.ERROR_STORE_NUMBER_DUPLICATION,
    );

    branchToStoreMappingValidation.isValid = false;
    branchToStoreMappingValidation.message.push(message);
  }

  // All branches must have either job site delivery or store delivery

  // A list of all the branches with job site delivery from the job site delivery listing tab
  const branchNamesWithJobSiteDelivery = map(jobSiteData, 'branchName');

  each(branchToStoreMappingData, (branchToStoreMappingEntry) => {
    // Check that each branch name is present in either the job site delivery branch name array or has a store delivery group name
    // Branches that are not present in either are in an error state
    if (
      !branchToStoreMappingEntry.deliveryGroupName &&
      !branchNamesWithJobSiteDelivery.includes(
        branchToStoreMappingEntry.branchName,
      )
    ) {
      const message = createErrorObj(
        branchToStoreMappingEntry.row,
        window.Locale.BRANCH_NAME,
        branchToStoreMappingEntry.branchName,
        window.Locale.ERROR_BRANCH_NAME_MISSING_DELIVERY_SETTING(
          branchToStoreMappingEntry.branchName,
        ),
      );
      branchToStoreMappingValidation.isValid = false;
      branchToStoreMappingValidation.message.push(message);
    }
  });

  return {
    validation: branchToStoreMappingValidation,
    data: validatedData,
  };
};
