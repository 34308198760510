import {
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  USER_SESSION_ID_SUCCESS,
  USER_SESSION_ID_FAILURE,
} from './userSessionActionTypes';

export const authenticateUserRequest = () => ({
  type: AUTHENTICATE_USER_REQUEST,
});

export const authenticateUserSuccess = (userProfile) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  userProfile,
});

export const authenticateUserFailure = (error) => ({
  type: AUTHENTICATE_USER_FAILURE,
  error,
});

export const logoutUserRequest = () => ({
  type: LOGOUT_USER_REQUEST,
});

export const logoutUserSuccess = (userProfile) => ({
  type: LOGOUT_USER_SUCCESS,
  userProfile,
});

export const logoutUserFailure = (error) => ({
  type: LOGOUT_USER_FAILURE,
  error,
});

export const userSessionIdSuccess = (sessionId) => ({
  type: USER_SESSION_ID_SUCCESS,
  sessionId,
});

export const userSessionIdFailure = (error) => ({
  type: USER_SESSION_ID_FAILURE,
  error,
});
