import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import accounting from 'accounting';
import { sortStoreDeliveryGroups } from '../previewStoreDeliveryUtils';
import { cadencedStoreDeliveryGroupSelector } from '../redux/storeDeliveryGroupsSelector';
import Card from '../../../common/card/card';

export const PreviewStoreDeliveryCadenced = ({ storeDeliveryData }) => {
  // We're going to cleanly handle nodata at a higher level;
  if (!storeDeliveryData || storeDeliveryData.length === 0) return null;

  const storeDeliveryArray = sortStoreDeliveryGroups(storeDeliveryData);

  const deliveryDays = (rowData) => {
    const daysOfWeek = [
      `${
        rowData.deliverToStoreOnWeeklyCadence.deliveryDay.monday
          ? `${window.Locale.MONDAY}, `
          : ''
      }`,
      `${
        rowData.deliverToStoreOnWeeklyCadence.deliveryDay.tuesday
          ? `${window.Locale.TUESDAY}, `
          : ''
      }`,
      `${
        rowData.deliverToStoreOnWeeklyCadence.deliveryDay.wednesday
          ? `${window.Locale.WEDNESDAY}, `
          : ''
      }`,
      `${
        rowData.deliverToStoreOnWeeklyCadence.deliveryDay.thursday
          ? `${window.Locale.THURSDAY}, `
          : ''
      }`,
      `${
        rowData.deliverToStoreOnWeeklyCadence.deliveryDay.friday
          ? `${window.Locale.FRIDAY}, `
          : ''
      }`,
    ];

    // the above code will return the appropriate days of the week, followed by a comma and an empty/space character
    // we concatenate these together, then slice off the final comma and space (two characters);
    // this means that regardless of which days are selected, we end up with a string with no trailing comma
    return ''.concat(...daysOfWeek).slice(0, -2);
  };

  const daysInAdvance = (rowData) =>
    `${window.Locale.BUSINESS_DAYS_IN_ADVANCE(
      rowData.purchaseOrderDaysInAdvance,
    )}`;

  const minimumOrderTotal = (rowData) =>
    rowData.minimumOrder && rowData.minimumOrder.minimumPurchaseOrderTotal > 0
      ? `${accounting.formatMoney(
          rowData.minimumOrder.minimumPurchaseOrderTotal,
        )}`
      : null;

  const minimumOrderFee = (rowData) =>
    rowData.minimumOrder && rowData.minimumOrder.minimumOrderFee > 0
      ? `${accounting.formatMoney(rowData.minimumOrder.minimumOrderFee)}`
      : null;

  return (
    <Card header={window.Locale.STORE_DELIVERY_GROUP_CADENCED}>
      <div className="supplier-info-grid">
        <DataTable value={storeDeliveryArray} responsive>
          <Column
            field="branchName"
            header={window.Locale.BRANCH_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            field="deliveryGroupName"
            header={window.Locale.DELIVERY_GROUP_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            body={deliveryDays}
            header={window.Locale.DAY_OF_THE_WEEK}
            headerClassName="cell header-cell"
          />
          <Column
            body={daysInAdvance}
            header={window.Locale.PURCHASE_ORDER_DAYS_IN_ADVANCE}
            headerClassName="cell header-cell"
          />
          <Column
            body={minimumOrderTotal}
            header={window.Locale.MINIMUM_PURCHASE_ORDER_TOTAL}
            headerClassName="cell header-cell"
          />
          <Column
            body={minimumOrderFee}
            header={window.Locale.MINIMUM_ORDER_FEE}
            headerClassName="cell header-cell"
          />
        </DataTable>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  storeDeliveryData: cadencedStoreDeliveryGroupSelector(state),
});

PreviewStoreDeliveryCadenced.propTypes = {
  storeDeliveryData: PropTypes.array,
};

PreviewStoreDeliveryCadenced.defaultProps = {
  storeDeliveryData: [],
};

export default connect(mapStateToProps)(PreviewStoreDeliveryCadenced);
