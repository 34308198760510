import { get } from 'lodash';
import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';

// Error case:
//      A branch is mapped to a store that doesn't exist
//      No other cases currently exist.
export const validateStoreIds = (branchToStoreMappingData, storeData) => {
  if (!branchToStoreMappingData || !storeData) return {};

  // we use this to track the validation state of the whole entity.
  const storeValidation = validationObjInit();
  storeValidation.message = [];

  branchToStoreMappingData.forEach((branchStore) => {
    const storeNumber = get(branchStore, 'storeData.storeNumber');

    // Previously storeIds was in strings with prefixed 0, now it has been cast to numbers
    // This change is to fix the issue to match storeNumber from spreadsheet with storeIds from service response SUPEXP-409
    if (!storeData[String(storeNumber)] && !storeData[`0${storeNumber}`]) {
      const message = window.Locale.STORE_NUMBER_IS_INVALID;

      const errorObj = createErrorObj(
        branchStore.row,
        window.Locale.STORE_NUMBER,
        storeNumber,
        message,
      );
      storeValidation.message.push(errorObj);
      storeValidation.isValid = false;
    }
  });

  return {
    validation: storeValidation,
  };
};
