import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { areCoordinatesPresent } from 'features/parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesSelector';
import UserMessageContainer from 'common/userMessageContainer/userMessageContainer';
import CoordinatesDoNotExistError from '../coordinatesDoNotExistError/coordinatesDoNotExistError';
import ToggleViewButtons from '../toggleViewButtons/toggleViewButtons';
import ReuploadButton from '../reuploadButton/reuploadButton';
import PushToProductionButton from '../pushToProduction/pushToProdButton/pushToProductionButton';
import SubmissionSummaryBanner from '../submissionSummaryBanner/submissionSummaryBanner';

export const PreSubmissionDisplay = ({ supplierAccountName }) => {
  const doCoordsExist = useSelector(areCoordinatesPresent);

  return (
    <>
      <div className="flex-container-row">
        <h2>{supplierAccountName}</h2>
        <div className="button-container">
          <ToggleViewButtons />

          <div className="spreadsheet-buttons">
            <ReuploadButton />
            <PushToProductionButton doCoordsExist={doCoordsExist} />
          </div>
        </div>
      </div>
      <CoordinatesDoNotExistError doCoordsExist={doCoordsExist} />
      <UserMessageContainer />
      <SubmissionSummaryBanner />
    </>
  );
};

PreSubmissionDisplay.propTypes = {
  supplierAccountName: PropTypes.string.isRequired,
};

export default PreSubmissionDisplay;
