import React from 'react';
import PropTypes from 'prop-types';

export const UsernameButton = ({ username, onClick, drawerOpen }) => (
  <div
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter') onClick();
    }}
    className="header-block"
  >
    <span>
      {username}
      {drawerOpen ? (
        <i className="icon_caret_up" />
      ) : (
        <i className="icon_caret_down" />
      )}
    </span>
  </div>
);

UsernameButton.propTypes = {
  username: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool,
};

UsernameButton.defaultProps = {
  drawerOpen: false,
};

export default UsernameButton;
