import { createSelector } from 'reselect';
import { getStoreDeliveryData } from '../../parseOnboardingSpreadsheet/redux/storeDelivery/storeDeliverySelectors';
import {
  getAsNeededStoreDeliveryGroups,
  getCadencedStoreDeliveryGroups,
} from '../previewStoreDeliveryUtils';

export const asNeededStoreDeliveryGroupSelector = createSelector(
  getStoreDeliveryData,
  (allStoreDeliveryGroups) => {
    return getAsNeededStoreDeliveryGroups(allStoreDeliveryGroups);
  },
);

export const cadencedStoreDeliveryGroupSelector = createSelector(
  getStoreDeliveryData,
  (allStoreDeliveryGroups) => {
    return getCadencedStoreDeliveryGroups(allStoreDeliveryGroups);
  },
);
