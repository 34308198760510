import request from 'superagent';
import { getConfig } from 'util/config';

// The spreadsheet does not have a country column, by defaulting to the US we are safe gaurding against no data being returned
// Given non-existent street, city, state and zip this will still return middle america. We validate for a 5 digit zip, so
// there is confidence that a non-existent street, city and state provided that the point returned will be close to the intended location.
export const getCoordinates = ({
  street = '',
  city = '',
  state = '',
  zip = '',
  country = 'US',
}) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .get(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/coordinates?street=${street}&city=${city}&state=${state}&country=${country}&zip=${zip}`,
    )
    .withCredentials()
    .then((response) => response.body)
    .catch((e) => Promise.reject(e));
};
