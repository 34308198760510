import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PropTypes from 'prop-types';
import requiresQcAccessCredentialTemplate from 'util/requiresQcAccessCredentialTemplate';
import formatPhoneNumber from '../../../util/formatPhoneNumber';
import {
  primaryContact,
  nonPrimaryContact,
} from '../supplierInfoDisplay.propTypes';

const ContactDisplayTable = ({ contactInfo, contactNameTitle }) => {
  const formattedPhoneNumber = (rowData) =>
    formatPhoneNumber(`${rowData.phoneNumber}`);
  // prevent additional contacts from displaying on the vendor listing card when data is not present on the spreadsheet
  if (contactInfo.name) {
    return (
      <DataTable value={[contactInfo]} responsive>
        <Column
          field="name"
          header={contactNameTitle}
          headerClassName="cell header-cell contact-cell"
        />
        <Column
          field="title"
          header={window.Locale.TITLE}
          headerClassName="cell header-cell title-cell"
        />
        <Column
          body={formattedPhoneNumber}
          header={window.Locale.PHONE}
          headerClassName="cell header-cell phone-cell"
        />
        <Column
          field="email"
          header={window.Locale.EMAIL}
          headerClassName="cell header-cell email-cell"
        />
        <Column
          field="requiresQcAccessCredentials"
          header={requiresQcAccessCredentialTemplate()}
          headerClassName="cell header-cell qc-cell"
        />
      </DataTable>
    );
  } else {
    return null;
  }
};

ContactDisplayTable.propTypes = {
  contactInfo: PropTypes.oneOfType([nonPrimaryContact, primaryContact]),
  contactNameTitle: PropTypes.string.isRequired,
};

ContactDisplayTable.defaultProps = {
  contactInfo: [],
};

export default ContactDisplayTable;
