import request from 'superagent';
import Throttle from 'superagent-throttle';
import { getConfig } from 'util/config';

// This is our throttle. It is instantiated here so it can be passed to any of our network requests that require concurrency
// We instantiate it with these params:
//    active: means throttling is occuring
//    rate: the number of requests that can be started every ratePer
//    ratePer: a time in milliseconds. Every ratePer, rate number of requests can start
//    concurrent: the number of simultaneous requests that can be running
// Concurrent is doing all the heavy lifting.
// rate and ratePer are set so that every millisecond we can start 4 requests. This allows for us to fill our concurrency quickly
// Interestingly this also prevents a time delay that occured at the end of the network request.
// Without these lines of code the throttle would wait for a time period before halting execution.
const throttle = new Throttle({
  active: true,
  rate: 4,
  ratePer: 1,
  concurrent: 4,
});

export const createSupplier = (body) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  return request
    .post(`${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier`)
    .withCredentials()
    .send(body)
    .then(({ body: supplierId }) => supplierId)
    .catch(
      (error) =>
        new Promise((resolve, reject) => {
          if (error) {
            return reject(new Error(error.response.text));
          }
          return resolve();
        }),
    );
};

export const enableJobSiteDelivery = (branchId) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/branch/${branchId}/enableJobSiteDelivery`,
    )
    .use(throttle.plugin())
    .retry(2)
    .withCredentials()
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const createJobSiteDelivery = ({ branchId, ranges }) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/branch/${branchId}/createJobSiteDelivery`,
    )
    .use(throttle.plugin())
    .withCredentials()
    .send({ ranges })
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const createBranch = ({ supplierId, body }) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier/${supplierId}/branch`,
    )
    .use(throttle.plugin())
    .withCredentials()
    .send(body)
    .then(({ body: branchId }) => branchId)
    .catch((e) => Promise.reject(e));
};

export const saveMerchantClass = (supplierId, body) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier/${supplierId}/merchantClass`,
    )
    .withCredentials()
    .retry(2)
    .send(body)
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const saveMarketClass = (supplierId, body) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier/${supplierId}/marketClass`,
    )
    .withCredentials()
    .retry(2)
    .send(body)
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const assignStoresToBranch = ({ supplierId, branchId, storeIds }) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier/${supplierId}/branch/${branchId}/stores`,
    )
    .use(throttle.plugin())
    .withCredentials()
    .retry(2)
    .send({ storeIds })
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const setInstantPrice = (branchId, body) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/branch/${branchId}/instantPrice`,
    )
    .use(throttle.plugin())
    .withCredentials()
    .retry(2)
    .send(body)
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const deleteSupplier = (supplierId) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .delete(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/supplier/${supplierId}`,
    )
    .withCredentials()
    .send()
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const createNewDeliveryGroup = (branchId, body) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/deliveryGroups/branch/${branchId}`,
    )
    .use(throttle.plugin())
    .withCredentials()
    .send(body)
    .then(({ body: response }) => ({
      deliveryGroupId: response.deliveryGroupId,
      branchId,
      deliveryGroupName: body.deliveryGroupName,
    }))
    .catch((e) => Promise.reject(e));
};

export const assignStoreToDeliveryGroup = ({
  storeIds,
  deliveryGroupId,
  branchId,
}) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/deliveryGroups/mapping/`,
    )
    .withCredentials()
    .retry(2)
    .send({ storeIds, deliveryGroupId, branchId })
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};

export const pollDeliveryGroups = ({ storeIds, deliveryGroupId, branchId }) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .post(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/deliveryGroups/mapping/confirmation`,
    )
    .withCredentials()
    .retry(2)
    .send({ storeIds, deliveryGroupId, branchId })
    .then((response) => response)
    .catch((e) => Promise.reject(e));
};
