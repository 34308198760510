import { v4 } from 'uuid';
import initializeLogger from '@quotecenter/client-action-ingress-lib';
import { getConfig } from 'util/config';

let clientActionIngressLogger;

const CLIENT_ACTION_LOGGER_SESSION_ID = 'CLIENT_ACTION_LOGGER_SESSION_ID';

try {
  if (!window.sessionStorage.getItem(CLIENT_ACTION_LOGGER_SESSION_ID)) {
    window.sessionStorage.setItem(CLIENT_ACTION_LOGGER_SESSION_ID, v4());
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(
    'Something went wrong when trying to initialize the clientActionLoggerSessionId',
    e,
  );
}

// Defaulting to a uuid just in case something went wrong when trying to retrieve the ID
// This will always guarantee us a sessionId, even if it isn't persisted
const clientActionLoggerSessionId =
  window.sessionStorage.getItem(CLIENT_ACTION_LOGGER_SESSION_ID) || v4();

export const getClientActionSessionId = () => clientActionLoggerSessionId;
export const removeClientActionSessionId = () =>
  window.sessionStorage.removeItem(CLIENT_ACTION_LOGGER_SESSION_ID);

const initLogger = () => {
  if (!clientActionIngressLogger) {
    try {
      const {
        REACT_APP_CLIENT_ACTION_INGRESS_URL = '',
        REACT_APP_CLIENT_ACTION_INGRESS_INSECURE_API_KEY = '',
      } = getConfig();

      // Since the insecureApiKey is being passed through the client browser, it has no reasonable expectation of secrecy
      if (
        REACT_APP_CLIENT_ACTION_INGRESS_URL &&
        REACT_APP_CLIENT_ACTION_INGRESS_INSECURE_API_KEY
      ) {
        clientActionIngressLogger = initializeLogger({
          appName: 'SOT-CustomLogger',
          ingestUrl: REACT_APP_CLIENT_ACTION_INGRESS_URL,
          sessionId: clientActionLoggerSessionId,
          insecureApiKey: REACT_APP_CLIENT_ACTION_INGRESS_INSECURE_API_KEY,
          storageNamespace: null, // Use default namespace
        });
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          'Client Action Ingress logger not configured, so skipping initialization',
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'Something went wrong when trying to initialize the clientActionIngress logger',
        e,
      );
    }
  }
};

export default () => {
  initLogger();

  return clientActionIngressLogger;
};
