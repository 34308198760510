import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import accounting from 'accounting';
import { sortStoreDeliveryGroups } from '../previewStoreDeliveryUtils';
import { asNeededStoreDeliveryGroupSelector } from '../redux/storeDeliveryGroupsSelector';
import Card from '../../../common/card/card';

export const PreviewStoreDeliveryAsNeeded = ({ storeDeliveryData }) => {
  // We're going to cleanly handle nodata at a higher level;
  if (!storeDeliveryData || storeDeliveryData.length === 0) return null;

  const storeDeliveryArray = sortStoreDeliveryGroups(storeDeliveryData);

  const arrivalWithin = (rowData) =>
    `${window.Locale.BUSINESS_DAYS_IN_ADVANCE(
      rowData.deliverToStoreAsNeeded.arrivalWithinBusinessDays,
    )}`;

  const minimumOrderTotal = (rowData) =>
    rowData.minimumOrder && rowData.minimumOrder.minimumPurchaseOrderTotal > 0
      ? `${accounting.formatMoney(
          rowData.minimumOrder.minimumPurchaseOrderTotal,
        )}`
      : null;

  const minimumOrderFee = (rowData) =>
    rowData.minimumOrder && rowData.minimumOrder.minimumOrderFee > 0
      ? `${accounting.formatMoney(rowData.minimumOrder.minimumOrderFee)}`
      : null;

  return (
    <Card header={window.Locale.STORE_DELIVERY_GROUP_AS_NEEDED}>
      <div className="supplier-info-grid">
        <DataTable value={storeDeliveryArray} responsive>
          <Column
            field="branchName"
            header={window.Locale.BRANCH_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            field="deliveryGroupName"
            header={window.Locale.DELIVERY_GROUP_NAME}
            headerClassName="cell header-cell"
          />
          <Column
            body={arrivalWithin}
            header={window.Locale.TRUCK_CAN_ARRIVE_WITHIN}
            headerClassName="cell header-cell"
          />
          <Column
            body={minimumOrderTotal}
            header={window.Locale.MINIMUM_PURCHASE_ORDER_TOTAL}
            headerClassName="cell header-cell"
          />
          <Column
            body={minimumOrderFee}
            header={window.Locale.MINIMUM_ORDER_FEE}
            headerClassName="cell header-cell"
          />
        </DataTable>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  storeDeliveryData: asNeededStoreDeliveryGroupSelector(state),
});

PreviewStoreDeliveryAsNeeded.propTypes = {
  storeDeliveryData: PropTypes.array,
};

PreviewStoreDeliveryAsNeeded.defaultProps = {
  storeDeliveryData: [],
};

export default connect(mapStateToProps)(PreviewStoreDeliveryAsNeeded);
