import React from 'react';
import PropTypes from 'prop-types';

const ValidationDisplayHeader = ({ icon, text }) => {
  return (
    <p className="validation-display-header">
      {icon}
      <span className="header-text">{text}</span>
    </p>
  );
};

ValidationDisplayHeader.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
};

ValidationDisplayHeader.defaultProps = {
  icon: '',
  text: '',
};

export default ValidationDisplayHeader;
