import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkDataPresence } from 'features/validationDisplay/redux/validationDisplaySelectors';

import Header from 'common/header/header';
import SubmissionSummary from 'features/submissionSummary/submissionSummary';
import PreviewBranchToStoreMapping from 'features/previewBranchToStoreMapping/previewBranchToStoreMapping';
import BranchCardContainer from 'features/branchCardContainer/branchCardContainer';
import './mapPage.scss';

const MapPage = () => {
  const [selectedBranch, setSelectedBranch] = useState(null);

  const isDataPresent = useSelector(checkDataPresence);

  if (!isDataPresent) return <Redirect to="/" />;

  return (
    <>
      <div className="flex-container">
        <Header />
        <SubmissionSummary routeName="/preview/map" />
        <div className="branch-to-store-mapping-container">
          <BranchCardContainer
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
          />
          <PreviewBranchToStoreMapping
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
          />
        </div>
      </div>
    </>
  );
};

export default MapPage;
