import request from 'superagent';
import { assign, forEach } from 'lodash';
import { getConfig } from 'util/config';

export const getStoreInfo = () => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();

  return request
    .get(`${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/stores`)
    .withCredentials()
    .then((response) => {
      const formattedStoreResponse = {};
      forEach(response.body, (value, key) =>
        assign(formattedStoreResponse, { [Number(key)]: value }),
      );
      return formattedStoreResponse;
    })
    .catch((e) => Promise.reject(e));
};
