const formatPhoneNumber = (phoneNumber, phoneExtension) => {
  const areaCode = phoneNumber.slice(0, -7);
  const prefix = phoneNumber.slice(3, -4);
  const lineNumber = phoneNumber.slice(-4);
  const formattedPhoneNumber = `(${areaCode}) ${prefix}-${lineNumber}`;

  return `${formattedPhoneNumber}${
    phoneExtension ? `, ext. ${phoneExtension}` : ''
  }`;
};

export default formatPhoneNumber;
