import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Atlas from '../../common/atlas/atlas';
import { getBranchToStoreMappingData } from '../branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { branchSelector } from '../previewBranch/redux/previewBranchSelectors';
import { selectBranch } from './previewBranchToStoreMappingUtils';
import { allPinsSelector } from './redux/allPinsSelector';
import './previewBranchToStoreMapping.scss';

const mapDetails = {
  svgIcons: [
    {
      name: 'branch',
      iconUrl: '/images/branch.svg',
      iconSize: [30, 30],
    },
  ],
  textIcons: [
    {
      name: 'store',
      className: 'icon_location icon-mapped',
    },
    {
      name: 'unMappedStore',
      className: 'icon_location icon-unmapped',
    },
  ],
};

export const PreviewBranchToStoreMapping = ({
  selectedBranch,
  setSelectedBranch,
}) => {
  const allPins = useSelector(allPinsSelector);
  const mapping = useSelector(getBranchToStoreMappingData);
  const branches = useSelector(branchSelector);
  const [pins, setPins] = useState([]);
  const [branch, setBranch] = useState(null);
  useEffect(() => {
    const newPins = selectBranch(selectedBranch, allPins, branches);
    setPins(newPins);
    setBranch(selectedBranch);
  }, [selectedBranch, allPins, branches]);
  if (!mapping && branches) {
    return null;
  } else {
    return (
      <Atlas
        pins={pins}
        selectedGroup={branch}
        mapDetails={mapDetails}
        clickCallback={setSelectedBranch}
      />
    );
  }
};

export default PreviewBranchToStoreMapping;
