import { combineReducers } from 'redux';
import userMessage from 'common/userMessageContainer/redux/userMessageReducer';
import supplierInformation from 'features/supplierInfoDisplay/redux/supplierInformationReducer';
import storeDelivery from 'features/parseOnboardingSpreadsheet/redux/storeDelivery/storeDeliveryReducer';
import jobSiteDelivery from 'features/parseOnboardingSpreadsheet/redux/jobSiteDelivery/jobSiteDeliveryReducer';
import branchToStoreMapping from 'features/branchToStoreMapping/redux/branchToStoreMappingReducer';
import branchListing from 'features/parseOnboardingSpreadsheet/redux/branchListing/branchListingReducer';
import fileUploaded from 'features/parseOnboardingSpreadsheet/redux/fileUploaded/fileUploadedReducer';
import storeInfo from 'features/storeInfo/redux/storeInfoReducer';
import onboardSupplierReducer from 'features/submissionSummary/pushToProduction/redux/onboardSupplierReducer';
import coordinatesReducer from 'features/parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesReducer';
import userSession from 'features/userSession/redux/userSessionReducer';

export default combineReducers({
  fileUploaded,
  branchListing,
  branchToStoreMapping,
  jobSiteDelivery,
  onboardSupplierReducer,
  storeDelivery,
  storeInfo,
  supplierInformation,
  coordinatesReducer,
  userMessage,
  userSession,
});
