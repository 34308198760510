import { PROGRESS_STEPS } from './onboardSupplierReducer';

export const getPercentForStep = (step) => {
  return Math.round(
    Number((step.order / Object.keys(PROGRESS_STEPS).length) * 100),
  );
};

export const createWorkerQueue = (data) => {
  const queue = [];

  Object.keys(data).forEach((branchId) => {
    Object.entries(data[branchId]).forEach(([deliveryGroupId, storeIds]) => {
      queue.push({
        branchId,
        deliveryGroupId,
        storeIds,
      });
    });
  });

  return queue;
};
