import {
  GET_COORDINATES_FOR_ALL_BRANCHES,
  GET_COORDINATES_SUCCESS,
  GET_COORDINATES_FAILURE,
} from './coordinatesActionTypes';

export const getCoordinatesForAllBranches = () => ({
  type: GET_COORDINATES_FOR_ALL_BRANCHES,
});

export const getCoordinatesSuccess = (coordinates) => ({
  type: GET_COORDINATES_SUCCESS,
  coordinates,
});

export const getCoordinatesFailure = (error) => ({
  type: GET_COORDINATES_FAILURE,
  error,
});
