import React from 'react';
import PropTypes from 'prop-types';
import './progressBar.scss';

const ProgressBar = ({ percentComplete }) => {
  // confirm that the percentComplete is between 0 and 100
  return percentComplete >= 0 && percentComplete < 100 ? (
    <div className="progress-bar">
      <div style={{ width: `${percentComplete}%` }} />
    </div>
  ) : null;
};

ProgressBar.propTypes = {
  percentComplete: PropTypes.number,
};

ProgressBar.defaultProps = {
  percentComplete: 0,
};

export default ProgressBar;
