import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import Button from '@quotecenter/component-library/Button';
import './pushToProductionButton.scss';
import { onboardSupplierRequest } from '../redux/onboardSupplierActionCreators';

export const PushToProductionButton = ({ doCoordsExist }) => {
  const dispatch = useDispatch();
  const saveNewSupplier = useCallback(() => {
    return dispatch(onboardSupplierRequest());
  }, [dispatch]);

  const buttonClassnames = classnames([
    'push-to-prod-button',
    !doCoordsExist ? 'disabled' : null,
  ]);

  return (
    <Button
      size="small"
      className={buttonClassnames}
      variant="primary"
      data-test="push-to-prod-button"
      aria-label={window.Locale.PUSH_TO_PRODUCTION}
      onClick={() => {
        if (doCoordsExist) saveNewSupplier();
      }}
    >
      <i className="icon_check" />
      {window.Locale.PUSH_TO_PRODUCTION}
    </Button>
  );
};

PushToProductionButton.propTypes = {
  doCoordsExist: PropTypes.bool.isRequired,
};

export default PushToProductionButton;
