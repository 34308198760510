import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { getUnmappedStoreCount } from 'features/submissionSummary/redux/submissionSelectors';
import BranchCard from './branchCard/branchCard';
import { branchesPropType } from '../previewBranch/previewBranchPropTypes';
import { getBranchCounts } from '../previewBranch/redux/previewBranchSelectors';
import './branchCardContainer.scss';

export class BranchCardContainer extends Component {
  constructor(props) {
    super(props);

    const state = {
      drawerOpen: false,
      scrollCount: 0,
    };
    this.state = state;
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  scrollHandler() {
    const { drawerOpen, scrollCount } = this.state;

    if (!drawerOpen) {
      // we don't want this to fire unless they scroll a couple times
      if (scrollCount > 0) {
        this.setState({
          drawerOpen: true,
          scrollCount: 0,
        });
      } else {
        this.setState({ scrollCount: scrollCount + 1 });
      }
    }
  }

  closeDrawer() {
    const { drawerOpen } = this.state;

    if (drawerOpen) {
      this.setState({ drawerOpen: false });
      this.scrollToTop();
    }
  }

  scrollToTop() {
    if (window.innerWidth < 680) {
      const element = document.getElementById('first-branch-card');
      if (element) element.scrollIntoView();
    }
  }

  render() {
    const {
      branches,
      selectedBranch,
      setSelectedBranch,
      unMappedStoresCount,
    } = this.props;
    const { drawerOpen } = this.state;
    const { closeDrawer } = this;
    if (!branches) return null;
    // Here we are generating the cards.
    // Take a look at the key of the BranchCard component.
    // We could use a number of things here:
    //  1. Array Indexes
    //  2. _.UniqueId()
    //  3. The values of the object
    //
    //  Using array indexes is an antipatern, as if you alter the array things break
    //    (we wouldn't do that, but we should still gaurd against that)
    //
    //  _.UniqueId is perfectly valid and accomplishes everything,
    //    but if any props change, the cards will rerender.
    //    Keys will change and we will have new objects.
    //
    //  This is why we're spreading the info from the object and using that.
    //  This means that we will generate and reuse each card across rerenders.
    //  More performant (and incidentally, accessible).

    const cards = branches.map((branch, index) => (
      <BranchCard
        branch={branch}
        key={Object.values(branch).join('-')}
        // We need to have the first element have a uniqueID so we can scroll it to the top of the container.
        // Here we are giving them that ID to accomplish this. Which is why we're checking if the index is 0.
        id={index === 0 ? 'first-branch-card' : null}
        active={`${branch.row}` === selectedBranch}
        onClick={() => {
          setSelectedBranch(`${branch.row}`);
          closeDrawer();
        }}
      />
    ));
    return (
      <div>
        <div
          className={`branch-card-container-shield ${
            drawerOpen ? 'open' : 'closed'
          }`}
        />
        <div
          className={`branch-card-container ${drawerOpen ? 'open' : 'closed'}`}
          onTouchMove={() => {
            this.scrollHandler();
          }}
          onWheel={(e) => {
            // if we are scrolling down
            if (e.deltaY > 0) {
              this.scrollHandler();
            }
          }}
        >
          {cards}
          {branches.length ? (
            <div className="card-wrapper" id="UNMAPPED">
              <div
                className={`branch-to-store-card ${selectedBranch ===
                  'UNMAPPED'} ? 'active' : ''}`}
                onClick={() => {
                  setSelectedBranch('UNMAPPED');
                  closeDrawer();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedBranch('UNMAPPED');
                    closeDrawer();
                  }
                }}
                role="button"
                tabIndex="0"
              >
                <h4>{window.Locale.UNMAPPED_STORES}</h4>
                <span>{unMappedStoresCount}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

BranchCardContainer.propTypes = {
  branches: branchesPropType,
  selectedBranch: PropTypes.string,
  setSelectedBranch: PropTypes.func,
  unMappedStoresCount: PropTypes.number,
};

BranchCardContainer.defaultProps = {
  branches: [],
  unMappedStoresCount: 0,
  selectedBranch: '',
  setSelectedBranch: noop,
};

const mapStateToProps = (state) => ({
  branches: getBranchCounts(state),
  unMappedStoresCount: getUnmappedStoreCount(state),
});

export default connect(mapStateToProps)(BranchCardContainer);
