import { ADD_STORE_DELIVERY_INFORMATION } from './storeDeliveryActionTypes';

export const getInitialState = () => ({
  data: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case ADD_STORE_DELIVERY_INFORMATION: {
      const { info } = action;

      return { ...state, data: info };
    }
    default:
      return state;
  }
};
