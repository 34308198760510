import {
  FILE_UPLOADED,
  FILE_PARSING_FAILED,
  CLEAR_FILE_PARSING_ERRORS,
} from './fileUploadedActionTypes';

export const getInitialState = () => ({
  error: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case FILE_UPLOADED: {
      const { fileName } = action;

      return { ...state, fileName };
    }

    case FILE_PARSING_FAILED: {
      const { error } = action;

      return { ...state, error };
    }

    case CLEAR_FILE_PARSING_ERRORS: {
      return getInitialState();
    }

    default:
      return state;
  }
};
