let config = null;

export const initConfig = async () => {
  try {
    const response = await fetch('/config.json');
    config = await response.json();
  } catch {
    config = process.env;
  }
  return config;
};

export const setConfig = (testConfig) => {
  // Set config for testing purposes
  config = testConfig;
};

export const getConfig = () => config;
