import { find, get } from 'lodash';
import { branchToStoreStartingRowNumber } from '../consts';
import { getCellRefValue, generateRowRange } from './spreadsheetHelpers';

const mapBranchToStore = (parsedData, branchData, rowNumber) => {
  // Branchname has been pulled out specifically so it can be used to locate the row number of that branch
  // name from the broader set of data, branchData.  This row is naturally a unique value since all rows are
  // automatically incremented, this uniqueness lends itself well to UI rendering downsteam.
  // This value is not going to be saved.
  const branchName = getCellRefValue(parsedData, 'A', rowNumber);
  const id = get(find(branchData, { branchName }), 'row', '');
  return {
    row: rowNumber,
    branchName,
    id,
    deliveryGroupName: getCellRefValue(parsedData, 'B', rowNumber),
    storeData: {
      storeNumber: getCellRefValue(parsedData, 'C', rowNumber),
      // This was previously commented. It should return to being commented in the future (or removed entirely)
      // This may not align with our store data strategy (which we should make)
      address: {
        street: getCellRefValue(parsedData, 'D', rowNumber),
        city: getCellRefValue(parsedData, 'E', rowNumber),
        state: getCellRefValue(parsedData, 'F', rowNumber),
        zip: getCellRefValue(parsedData, 'G', rowNumber),
      },
      regionId: getCellRefValue(parsedData, 'H', rowNumber),
      regionName: getCellRefValue(parsedData, 'I', rowNumber),
      divisionId: getCellRefValue(parsedData, 'J', rowNumber),
      divisionName: getCellRefValue(parsedData, 'K', rowNumber),
      marketId: getCellRefValue(parsedData, 'L', rowNumber),
      marketName: getCellRefValue(parsedData, 'M', rowNumber),
      latitude: getCellRefValue(parsedData, 'N', rowNumber),
      longitude: getCellRefValue(parsedData, 'O', rowNumber),
    },
  };
};

const createBranchToStoreMappings = (parsedData, branchData) => {
  const mappings = [];
  const mappingRowNumbers = generateRowRange(
    parsedData,
    branchToStoreStartingRowNumber,
  );
  mappingRowNumbers.forEach((rowNumber) => {
    // check that the row contains a branch name (column A) and store id (column C)
    if (parsedData[`A${rowNumber}`] && parsedData[`C${rowNumber}`]) {
      mappings.push(mapBranchToStore(parsedData, branchData, rowNumber));
    }
  });

  return mappings;
};

export default createBranchToStoreMappings;
