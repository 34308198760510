import React from 'react';
import { FeatureGroup } from 'react-leaflet';
import Leaflet from 'leaflet';
import { isArray, each, noop } from 'lodash';
import PropTypes from 'prop-types';
import AtlasPin from '../atlasPin/atlasPin';
import { pinGroupShape, mapDetailsShape } from '../atlasPropTypes';

const AtlasGroup = React.memo(
  ({ pinGroup, mapDetails, setGroupId, clickCallback }) => {
    // Accessing feature groups in Leaflet isn't as intuitive as it should be.  All groups have unique IDS
    // so on loaded we send this ID back up to the mother atlas component.  The Atlas component uses this ID
    // to know which group to set as active.
    const groupId = (e) => {
      setGroupId(pinGroup.id || 'UNMAPPED', e.target._leaflet_id);
    };

    const icons = {};
    each(mapDetails.svgIcons, (icon) => {
      icons[icon.name] = new Leaflet.Icon(icon);
    });
    each(mapDetails.textIcons, (icon) => {
      icons[icon.name] = new Leaflet.DivIcon(icon);
    });

    if (isArray(pinGroup)) {
      return (
        <FeatureGroup data-name="allPins" onAdd={groupId}>
          {pinGroup.map((pin) => {
            return (
              <AtlasPin
                key={`${pin.name}-pin`}
                pin={pin}
                icon={icons[pin.icon]}
              />
            );
          })}
        </FeatureGroup>
      );
    } else {
      // TODO: Pull this z-index value from a z-index hierarchy.
      return (
        <FeatureGroup
          data-name={pinGroup.name}
          onAdd={groupId}
          onClick={() => {
            clickCallback(pinGroup.id);
          }}
        >
          <AtlasPin zIndex={1000} pin={pinGroup} icon={icons[pinGroup.icon]} />
          {pinGroup.associatedPins && pinGroup.associatedPins.length
            ? pinGroup.associatedPins.map((associatedPin) => (
                <AtlasPin
                  key={associatedPin.name}
                  pin={associatedPin}
                  icon={icons[associatedPin.icon]}
                />
              ))
            : ''}
        </FeatureGroup>
      );
    }
  },
);

AtlasGroup.propTypes = {
  pinGroup: pinGroupShape.isRequired,
  mapDetails: mapDetailsShape,
  setGroupId: PropTypes.func.isRequired,
  clickCallback: PropTypes.func,
};

AtlasGroup.defaultProps = {
  mapDetails: {},
  clickCallback: noop,
};

export default AtlasGroup;
