export const numericalStringValidator = (val) => {
  // catches literal numbers and a string of the number 0
  if (typeof val === 'number' || Number(val) === 0) return true;

  // catches numbers that are dollar amounts
  const regex = new RegExp('\\$\\d+(?:\\.\\d+)?');
  if (regex.test(val)) return true;

  // catches strings of all other numbers
  return !!Number(val);
};
