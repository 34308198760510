/**
 * This is a function used in validation of parsed data. It validates and corrects enum values
 *
 * @param {string} value - A string value
 * @param {string} legalValues - An array of string values
 * @return {obj} - {
 *      isValid: Bool
 *      message: String
 *      isCoerced: Bool
 *      correctedVal: String
 *   }
 *
 */

export const enumValidator = (
  value,
  legalValues = [window.Locale.YES, window.Locale.NO],
) => {
  if (legalValues.includes(value)) {
    return {
      isValid: true,
      message: null,
      isCoerced: false,
      correctedVal: null,
    };
  }

  // Only try to capitalize if it is a string
  const uppercasedVal = typeof value === 'string' ? value.toUpperCase() : value;
  const uppercasedLegalVals = legalValues.map((val) => {
    return val.toUpperCase ? val.toUpperCase() : val;
  });

  // Check for improper capitalization and correct.
  if (uppercasedLegalVals.includes(uppercasedVal)) {
    const returnable = {
      isValid: true,
      message: null,
      isCoerced: true,
      correctedVal: null,
    };

    uppercasedLegalVals.forEach((legalVal) => {
      if (legalVal === uppercasedVal) {
        returnable.correctedVal = legalVal;
      }
    });

    return returnable;
  }

  const prettyValue =
    value === undefined || value === null || value === ''
      ? window.Locale.BLANK
      : value;

  return {
    isValid: false,
    message: window.Locale.ERROR_ILLEGAL_VALUE_FOUND(
      legalValues.join(', ').trim(),
      prettyValue,
    ),
    isCoerced: false,
    correctedVal: null,
  };
};
