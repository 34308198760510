export default {
  ADD_NEW_SUPPLIER: 'Add New Supplier',
  ADDRESS: 'Address',
  ALLOW_JOB_SITE_DELIVERY: 'Allow Job Site Delivery',
  ALLOW_UNIQUE_VENDOR_PRODUCT_ID_BY_LENGTH:
    'Allow Unique Vendor Product Id By Length',
  ARRIVAL_WITHIN_BUSINESS_DAYS_MUST_BE_A_NUMBER:
    'Arrival within Business days should be a number',
  ARRIVAL_WITHIN_BUSINESS_DAYS: 'Arrival within Business Days',
  AS_NEEDED_DELIV_INFO_MISSING:
    'This delivery group has been set up with ‘As Needed’ delivery, but it is missing a time to deliver. Please set it.',
  AS_NEEDED_DELIVERY_SETTINGS: 'As Needed Delivery Settings',
  AS_NEEDED_INFO_WITHOUT_AS_NEEDED_DELIV: `This delivery group has been set up with ‘As Needed’ delivery information, but is not set to as needed delivery.
    Please set it to ‘As Needed’ delivery or remove this information.`,
  BLANK: 'BLANK',
  BRANCH_ADDRESS: 'Branch Address',
  BRANCH_LISTING: 'Branch Listing',
  BRANCH_NAME: 'Branch Name',
  BRANCH_TO_STORE_MAPPING: 'Branch To Store Mapping',
  BRANCHES: 'Branches',
  BRAND_NAME: 'Brand Name',
  BUSINESS_DAYS_IN_ADVANCE: (numberOfDays) =>
    `${numberOfDays} business ${numberOfDays >= 1 ? `days` : `day`}`,
  CADENCE_DELIVERY_SETTINGS: 'Cadence Delivery Settings',
  CANCEL_PUSH_TO_PROD: 'Cancel Push to Production',
  CANCEL: 'Cancel',
  CANCELLED_SUCCESSFULLY: 'Supplier creation cancelled successfully',
  CANNOT_LOAD_FILE: 'Could not load file data',
  CANT_BE_BOTH_WEEKLY_AND_AS_NEEDED_DELIVERY:
    'This delivery group has been set to deliver both weekly and as needed. Please remove one setting.',
  CHECK: 'Check and Preview',
  CITY: 'City',
  COMMA_STRING_AND: (commaSeparated, lastElement) =>
    `${commaSeparated} and ${lastElement}`,
  CONTACT_NAME: 'Contact Name',
  COULD_NOT_REVERT_CREATE:
    'Record creation failed and we could not clean up the record. Please go to Ergon and delete the record you were trying to create',
  CREATING_BRANCHES: 'Creating Branches',
  CREATING_DELIVERY_GROUPS: 'Creating Delivery Groups',
  CREATING_RECORDS_IN_PRODUCTION: (supplierName) =>
    `Creating records for ${supplierName} in production`,
  CREATING_SUPPLIER: 'Creating Supplier Record',
  CREATION_COMPLETE: 'Supplier Record Creation Complete',
  CUSTOMER_SERVICE_CONTACT: 'Customer Service Contact',
  CUSTOMER_SERVICE: 'Customer Service',
  DAY_OF_THE_WEEK: 'Day of the Week',
  DAYS: (numberOfDays) =>
    `${numberOfDays} ${numberOfDays >= 1 ? `days` : `day`}`,
  DELIVER_TO_STORE_AS_NEEDED: 'Deliver to Store as Needed',
  DELIVER_TO_STORE_ON_WEEKLY_CADENCE: 'Deliver to Store on Weekly Cadence',
  DELIVERY_FEE: 'Delivery Fee',
  DELIVERY_GROUP_BRANCH_NAME: 'Delivery Group Branch Name',
  DELIVERY_GROUP_NAME: 'Delivery Group Name',
  DELIVERY_GROUP_NOT_USED: 'Delivery group is not used',
  DELIVERY_GROUP_DOESNT_EXIST:
    'The delivery group you used does not exist for this branch',
  DELIVERY_GROUP: 'Delivery Group',
  DELIVERY_SETTINGS: 'Delivery Settings',
  DIVISION_NAME: 'Division Name',
  DIVISION: 'Division',
  EDI_CONTACT_NAME: 'EDI Contact Name',
  EMAIL: 'Email',
  ERROR_ADDRESS_CITY: 'No City was Provided',
  ERROR_ADDRESS_STATE: 'State should be 2 characters',
  ERROR_ADDRESS_STREET: 'No Street was Provided',
  ERROR_ADDRESS_STREET_CONTAINS_SPECIAL_CHARACTER:
    'Street address contains a special character. To prevent errors in the address location service, only the following characters are allowed: uppercase and lowercase letters, numbers, dashes, commas, periods, apostrophes, and spaces.',
  ERROR_ADDRESS_ZIP:
    'US ZIP codes should be 5 numbers, or 5 numbers followed by a hypen and 4 more numbers. Canadian postal codes should be 6 characters (all capitalized), with an optional space after the third character.',
  ERROR_ADDRESS: 'No Address was Provided',
  ERROR_BAD_BRAND_NAME: 'Brand name must be a string or omitted',
  ERROR_BAD_EMAIL: 'Email is not valid',
  ERROR_BAD_EXTENSION:
    "Phone Number Extension can't contain Letters or Special Characters",
  ERROR_BAD_GLN: (VALUE) =>
    `GLN's need to have 13 digits; provided contains: ${VALUE}`,
  ERROR_BAD_MVENDOR: 'mVendor Number not valid',
  ERROR_BAD_PVENDOR: 'pVendor Number not valid',
  ERROR_BING_COULD_NOT_LOCATE_ADDRESS:
    'Our location service (Bing) could not find one or more addresses. Please update the incorrect addresses or try again later.',
  STORE_DELIVERY_ENABLED_ERROR: (VALUE) =>
    `Store Delivery Enabled must be either yes, no, or empty; received ${VALUE}`,
  ERROR_BRANCH_NAME_DUPLICATION:
    'Two or more branches share a name; please rename one of them',
  ERROR_BRANCH_NAME_MISSING_DELIVERY_SETTING: (branchName) =>
    `Branch "${branchName}" is not set up for delivery. Branches must have either store delivery or job site delivery.`,
  ERROR_DELIVERY_GROUP_NAME_DUPLICATION:
    'Two delivery groups for the same branch share a name, please rename one of them',
  ERROR_ILLEGAL_VALUE_FOUND: (VALUE, PROVIDED) =>
    `The system was expecting a value of ${VALUE} instead of ${PROVIDED}.`,
  ERROR_NO_BRANCH_NAME: 'No Branch name was Provided',
  ERROR_NO_BRANCH_STORE_DELIVERY: 'Store Delivery Enabled must be yes or no',
  ERROR_NO_BRANCH: 'No Branch was Provided',
  ERROR_NO_BRAND_NAME: 'No Brand Name was Provided',
  ERROR_NO_CONTACT_NAME: 'No Contact Name was Provided',
  ERROR_NO_CUSTOMER_SERVICE_CONTACT:
    'No Customer Service Contact name was Provided',
  ERROR_NO_PVENDOR: 'No pVendor Number was Provided',
  ERROR_NO_STORE_DELIVERY_GROUP_NAME:
    'No Store Delivery Group Name was Provided',
  ERROR_NO_STORE_ID: 'No Store Id was Provided',
  ERROR_NO_STORE_INFO_RECIEVED: 'No store info recieved from server',
  ERROR_NO_SUPPLIER_NAME: 'No Supplier Name was Provided',
  ERROR_PHONE_LENGTH: 'Phone Numbers need to be 10 digits',
  ERROR_PHONE_NON_NUMBERS:
    "Phone Numbers can't contain Letters or Special Characters",
  ERROR_STORE_ID_MUST_BE_CORRECT_LENGTH: (VALUE) =>
    `Store Id must be either 3 or 4 digits; provided: ${VALUE}`,
  ERROR_STORE_ID_MUST_BE_NUMBER: 'Store Id must be a number',
  ERROR_STORE_NUMBER_DUPLICATION:
    'Two or more stores share the same store number; store numbers must be unique',
  ERROR_SUPPLIER_NAME_CONTAINS_SPECIAL_CHARACTER:
    'Supplier Name contains a special character. Only the following characters are allowed: uppercase and lowercase letters, numbers, underscores, dashes, commas, periods, forward slashes, parentheses, brackets, ampersands, apostrophes, and spaces.',
  ERROR_UPLOADING_SUPPLIER_RECORD: (supplierName) =>
    `There was an error when creating records for ${supplierName} in production`,
  EXT: 'Ext',
  FAX_NUMBER: 'Fax Number',
  FAX: 'Fax',
  FIELD_MUST_BE_POSITIVE_NUM: (fieldName) =>
    `${fieldName} must be a positive number`,
  FIELD_MUST_BE_WHOLE_NUM: (fieldName) => `${fieldName} must be a whole number`,
  FREE_DELIVERY_OVER: 'Free Delivery Over',
  FRIDAY: 'Friday',
  FROM: 'From',
  GLN: 'GLN',
  HDQC_EMAIL: 'quotecenter@homedepot.com',
  HDQC_FAX: '360-604-7475',
  HDQC_PHONE: '800-910-7028',
  HDQC: 'Home Depot Quote Center',
  IDM_CONTACT_NAME: 'IDM Contact Name',
  INFORMATION_COMPLETE: 'Information Complete',
  JOB_SITE_DELIVERY_LISTING: 'Job Site Delivery Listing',
  JOB_SITE_DELIVERY_GROUP: 'Job Site Delivery Group',
  JOBSITE_DELIVERY_COLUMN: 'Allow Jobsite Delivery',
  JOBSITE_DELIVERY: {
    UNIQUE_BRANCH_NAME_ERROR:
      'Branch name must be defined in the Branch Listing tab',
    BRANCH_NAME_REQUIRED: 'Branch name must be at least one character',
    FROM: 'From',
    FROM_ERROR:
      'Distance from must be at least 2 miles greater than the next smallest "from" value within a given branch name.',
    FROM_REQUIRED:
      'From is a required value and must be between 1 and 12,500 miles.',
    DELIVERY_FEE: 'Delivery Fee',
    DELIVERY_FEE_ERROR:
      'Delivery fee must be empty or an amount less than $100,000.',
    FREE_DELIVERY: 'Free Delivery Over',
    FREE_DELIVERY_ERROR:
      'Free delivery over must be empty or an amount less than $1,000,000.',
    MAX_DELIVERY: 'Max Delivery Time',
    MAX_DELIVERY_ERROR:
      'Max delivery time is required to be between 1 and 99 days.',
  },
  JOB_SITE_DELIVERY_ENABLED_BUT_NO_BRANCHES:
    'Please provide job site delivery information.',
  JSD_YES_WITHOUT_JSD_SETTINGS:
    'Jobsite Delivery is enabled, but no jobsite delivery settings were provided',
  JSD_NO_WITH_JSD_SETTINGS:
    'Jobsite Delivery is disabled, but jobsite delivery settings were provided',
  LATITUDE: 'Latitude',
  LEAD_TIME: 'Lead Time',
  LOGOUT: 'Logout',
  LONGITUDE: 'Longitude',
  MAIN_CONTACT: 'Main Contact',
  MAPPED_STORES: 'Mapped Stores',
  MAPPING_BRANCHES_TO_STORES: 'Mapping Branches to Stores',
  MAPPING_STORES_TO_DELIVERY_GROUPS: 'Mapping Stores to Delivery Groups',
  MARKET_NAME: 'Market Name',
  MARKET: 'Market',
  MAX_DELIVERY_TIME: 'Max Delivery Time',
  MILES: (numberOfMiles) =>
    `${numberOfMiles} ${numberOfMiles >= 1 ? `miles` : `mile`}`,
  MINIMUM_ORDER_FEE_MUST_BE_A_NUMBER: 'Minimum order fee must be a number',
  MINIMUM_ORDER_FEE: 'Minimum Order Fee',
  MINIMUM_ORDER_VALUE: 'Minimum Order Value',
  MINIMUM_PO_MUST_BE_A_NUMBER: 'Minimum PO should be a number',
  MINIMUM_PURCHASE_ORDER_TOTAL: 'Minimum Purchase Order Total',
  MINIMUM_PURCHASE_ORDER_TOTAL_PRESENT_BUT_NO_MINIMUM_ORDER_FEE:
    'Minimum Purchase Order Total is set, but Minimum Order Fee is missing. Please add a Minimum Order Fee.',
  MINIMUM_ORDER_FEE_PRESENT_BUT_NO_MINIMUM_PURCHASE_ORDER_TOTAL:
    'Minimum Order Fee is set, but Minimum Purchase Order Total is missing. Please add a Minimum Purchase Order Total.',
  MONDAY: 'Monday',
  MULTIPLE_SHEETS: 'Multiple Sheets',
  MUST_BE_EITHER_WEEKLY_OR_AS_NEEDED_DELIVERY:
    'This delivery group is missing a delivery setting. Please select either delivery as needed or cadence delivery.',
  MVENDOR_NUMBER: 'mVendor Number',
  MVENDOR: 'mVendor',
  NEW_SUPPLIER: 'Add New Supplier',
  NO_BRANCH_NAME:
    'This delivery setting is missing the branch name. Please name it to a branch.',
  NO_DELIVERY_GROUP_NAME: 'No Delivery Group Name was Provided',
  NO_SUCH_BRANCH_NAME: 'No branch exists with this name',
  NO_SUPPLIER_INFO: 'No Supplier Information',
  NO: 'No',
  ONBOARDING_TOOL: 'Onboarding Tool',
  PHONE_NUMBER_EXTENSION: 'Phone Number Extension',
  PHONE_NUMBER: 'Phone Number',
  PHONE: 'Phone',
  PRIMARY_CONTACT_NAME: 'Primary Contact Name',
  PURCHASE_DAYS_IN_ADVANCE_MUST_BE_LESS_THAN_60:
    'Purchase days in advance must be 60 days or less',
  PURCHASE_DAYS_IN_ADVANCE_MUST_BE_NUMBER:
    'Purchase Days in Advance should be a number',
  PURCHASE_ORDER_DAYS_IN_ADVANCE: 'Purchase Order Days in Advance',
  PUSH_TO_PRODUCTION: 'Push To Production',
  PVENDOR_NUMBER: 'pVendor Number',
  QC_ACCESS_CREDENTIALS_NEEDED: {
    PART_1: 'QC',
    CONDITIONAL_PART_2: ' Access ',
    PART_3: ' Credentials Needed?',
  },
  RANGE: 'Range',
  RANGE_NUMBER: 'Range Number',
  RECORD_CREATED: 'Supplier Record Created',
  REGION_NAME: 'Region Name',
  REGION: 'Region',
  REQUIRE_VENDOR_PRODUCT_IDS_FOR_EDI: 'Require Vendor Product Ids For EDI',
  REQUIRES_QC_ACCESS_CREDENTIALS: 'Requires QC Access Credentials',
  REUPLOAD_FILE: 'Re-Upload File',
  SELECT_DIFF_FILE: 'Select Different File',
  SELECT_FILE: 'Select File',
  SETTING_INSTANT_PRICING: 'Setting Instant Prices',
  SETTING_MARKET_CLASS: 'Setting Market Classes',
  SETTING_MERCHANT_CLASS: 'Setting Merchant Classes',
  SETTING_JOBSITE_DELIVERY: 'Setting Job Site Delivery',
  STATE: 'State',
  STORE_ADDRESS: 'Store Address',
  STORE_CITY: 'Store City',
  STORE_COUNT: 'Store Count',
  STORE_DELIVERY_ENABLED: 'Store Delivery Enabled',
  STORE_DELIVERY_GROUP_AS_NEEDED: 'Store Delivery Groups - As Needed',
  STORE_DELIVERY_GROUP_CADENCED: 'Store Delivery Groups - Cadenced',
  STORE_DELIVERY_GROUP_LISTING: 'Store Delivery Group Listing',
  STORE_DELIVERY_GROUP_NAME: 'Store Delivery Group',
  STORE_DELIVERY_REQUIRED_FOR_ALL_DELIVERY_GROUPS: `This delivery group exists for a branch that doesn’t have store delivery enabled.
    Please check if the branch does not deliver to stores.`,
  STORE_DELIVERY_NOT_ENABLED_BUT_DATA_FOUND: `This branch does not have store delivery enabled, but a delivery group was provided.
    Please remove the delivery group information or enable store delivery for this branch.`,
  STORE_DELIVERY: 'Store Delivery',
  STORE_DELIVERY_WITHOUT_DELIVERY_GROUPS:
    'Store delivery is enabled, but there are no delivery groups for this branch',
  STORE_ID: 'Store Id',
  STORE_NUMBER_IS_INVALID: 'This store number does not exist',
  STORE_NUMBER: 'Store Number',
  STORE_STATE: 'Store State',
  STORE_ZIP: 'Store ZIP',
  STORE: 'Store',
  STREET: 'Street',
  SUCCESSFULLY_MAPPED: (supplierName) => `${supplierName} Successfully Mapped`,
  SUPPLIER_ACCOUNT_NAME: 'Supplier Account Name',
  SUPPLIER_ACCOUNT: 'Supplier Account',
  SUPPLIER_INFO: 'Supplier Information',
  SUPPLIER_LISTING: 'Supplier Listing',
  SUPPLIER_PORTAL: 'Supplier Portal',
  SUPPLIER: 'Supplier',
  THURSDAY: 'Thursday',
  TITLE: 'Title',
  TRUCK_CAN_ARRIVE_WITHIN: 'Truck Can Arrive Within',
  TUESDAY: 'Tuesday',
  UNMAPPED_BRANCH_LISTING: 'Branch is not mapped to a store',
  UNMAPPED_STORES: 'Unmapped Stores',
  UPLOAD_FILE: 'Upload A File',
  UPLOAD_NEW_SPREADSHEET: 'Upload a new Supplier Spreadsheet to Onboard',
  UPLOAD: 'Upload',
  VALIDATION_COLUMN: 'Column',
  VALIDATION_DISPLAY_HEADER_ERRORS:
    'Please Correct Errors and Re-Upload Spreadsheet',
  VALIDATION_DISPLAY_HEADER_NO_DATA: 'Please Upload a Spreadsheet',
  VALIDATION_DISPLAY_HEADER_NO_ERRORS: 'No Errors Found!',
  VALIDATION_ERROR: 'Error Message',
  VALIDATION_LOCATION: 'Location',
  VALIDATION_ROW: 'Row',
  VALIDATION_VALUE: 'Value On Cell',
  VIEW_IN_VENDOR_PORTAL: 'View in Vendor Portal',
  VIEW_MAP: 'View Map',
  VIEW_SPREADSHEET: 'View Spreadsheet',
  WEDNESDAY: 'Wednesday',
  WEEKLY_DELIV_INFO_MISSING:
    'This delivery group has been set up with cadence delivery but is missing cadence information. Please set it.',
  WEEKLY_DELIV_INFO_WITHOUT_WEEKLY_DELIV: `This delivery group has been set up with weekly delivery information, but is not set to as needed delivery.
    Please set it to weekly delivery or remove this information.`,
  WORKBOOK_DOES_NOT_CONTAIN_CORRECT_SHEETS: (
    sheetNameCount,
    expectedSheetnames,
    receivedSheetnames,
  ) =>
    `The uploaded file does not contain the correct sheets.
    Expected sheet name${
      sheetNameCount > 1 ? 's' : ''
    } ${expectedSheetnames}, but received ${receivedSheetnames}.`,
  YES: 'Yes',
  ZIP: 'ZIP',
};
