import { get } from 'lodash';
import { createSelector } from 'reselect';
import { validateStoreDeliveryData } from '../../../validateParsedData/validateStoreDeliveryData';

export const getStoreDeliveryData = (state) =>
  get(state, 'storeDelivery.data', []);

export const getStoreDeliveryValidation = createSelector(
  getStoreDeliveryData,
  (data) => validateStoreDeliveryData(data),
);
