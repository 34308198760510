import React from 'react';

export default () => (
  <span>
    {window.Locale.QC_ACCESS_CREDENTIALS_NEEDED.PART_1}
    <span className="optional-access">
      {window.Locale.QC_ACCESS_CREDENTIALS_NEEDED.CONDITIONAL_PART_2}
    </span>
    {window.Locale.QC_ACCESS_CREDENTIALS_NEEDED.PART_3}
  </span>
);
