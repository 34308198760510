import { validationObjInit } from './validators/validationObjInit';
import { createErrorObj } from './helpers/createErrorObj';

// Note: This validator handles "cross-sheet validation" between the
// branch listing tab and the store delivery group listing tab; it does
// NOT perform validation related to the branch-to-store mapping tab

export const branchToStoreDeliveryGroupValidator = (
  deliveryGroupData,
  branchData,
) => {
  if (!branchData || !deliveryGroupData) return {};

  const validation = validationObjInit();
  validation.message = [];

  // Create a set for constant time lookups
  const deliveryGroupDataBranchNames = new Set(
    deliveryGroupData.map(({ branchName }) => branchName),
  );

  // =======================================================
  // If "Store Delivery Enabled" is set to "Yes" on the branch listing tab,
  // that branch MUST ALSO APPEAR on the store delivery group listing tab
  // =======================================================
  branchData.forEach(({ branchName, storeDeliveryEnabled, row }) => {
    if (
      storeDeliveryEnabled === true &&
      !deliveryGroupDataBranchNames.has(branchName)
    ) {
      const err = createErrorObj(
        row,
        window.Locale.BRANCH_NAME,
        branchName,
        window.Locale.STORE_DELIVERY_WITHOUT_DELIVERY_GROUPS,
        window.Locale.BRANCH_LISTING,
      );

      validation.isValid = false;
      validation.message.push(err);
    }
  });

  // =======================================================
  // If "Store Delivery Enabled" is set to "No" on the branch listing tab,
  // that branch MUST NOT APPEAR on the store delivery group listing tab
  // =======================================================

  branchData.forEach(({ branchName, storeDeliveryEnabled, row }) => {
    if (
      storeDeliveryEnabled === false &&
      deliveryGroupDataBranchNames.has(branchName)
    ) {
      const err = createErrorObj(
        row,
        window.Locale.STORE_DELIVERY_ENABLED,
        // the value "No" from the spreadsheet is coerced to false by the parser; we display "No"
        window.Locale.NO,
        window.Locale.STORE_DELIVERY_NOT_ENABLED_BUT_DATA_FOUND,
        window.Locale.BRANCH_LISTING,
      );

      validation.isValid = false;
      validation.message.push(err);
    }
  });

  return validation;
};
