import { createSelector } from 'reselect';
import { get } from 'lodash';
import {
  getStoreDeliveryValidation,
  getStoreDeliveryData,
} from '../../parseOnboardingSpreadsheet/redux/storeDelivery/storeDeliverySelectors';
import {
  getSupplierValidation,
  getSupplierInformation,
} from '../../supplierInfoDisplay/redux/supplierInformationSelectors';
import {
  getBranchListingValidation,
  getBranchListingData,
} from '../../parseOnboardingSpreadsheet/redux/branchListing/branchListingSelectors';
import { getJobSiteDeliveryData } from '../../parseOnboardingSpreadsheet/redux/jobSiteDelivery/jobSiteDeliverySelectors';
import {
  getBranchToStoreMappingValidation,
  getBranchToStoreMappingData,
} from '../../branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { validateStoreIds } from '../../validateParsedData/validateStoreIds';
import { validateJobSiteDelivery } from '../../validateParsedData/validateJobSiteDelivery';
import { getStoreInfo } from '../../storeInfo/redux/storeInfoSelector';
import { crossSheetDeliveryGroupValidator } from '../../validateParsedData/crossSheetDeliveryGroup';
import { crossEntityBranchValidator } from '../../validateParsedData/crossEntityBranchValidator';
import { crossEntityJobSiteValidator } from '../../validateParsedData/crossEntityJobSiteValidator';
import { branchToStoreDeliveryGroupValidator } from '../../validateParsedData/validateBranchToStoreDeliveryGroup';

export const getStoreInfoValidation = createSelector(
  getBranchToStoreMappingData,
  getStoreInfo,
  (branchStores, storeInfo) => {
    return validateStoreIds(branchStores, storeInfo);
  },
);

export const getJobSiteDeliveryValidation = createSelector(
  getJobSiteDeliveryData,
  getBranchListingData,
  (jobSiteDeliveryData, branchListingData) => {
    return validateJobSiteDelivery(jobSiteDeliveryData, branchListingData);
  },
);

// This is an entry point we can use for getting cross sheet validation.
export const getCrossSheetValidationMessages = createSelector(
  getBranchListingData,
  getBranchToStoreMappingData,
  getStoreDeliveryData,
  getJobSiteDeliveryData,
  getSupplierInformation,
  (
    branches,
    branchStoreMapping,
    storeDelivery,
    jobSiteDeliveryData,
    supplierInformation,
  ) => {
    const crossEntityBranchMessages = get(
      crossEntityBranchValidator(branches, branchStoreMapping),
      'message',
      [],
    );
    const crossEntityJobSiteMessages = get(
      crossEntityJobSiteValidator(jobSiteDeliveryData, supplierInformation),
      'message',
      [],
    );
    const crossEntityBranchToStoreDeliveryGroupMessages = get(
      branchToStoreDeliveryGroupValidator(storeDelivery, branches),
      'message',
      [],
    );
    const crossEntityDeliveryGroupMessages = get(
      crossSheetDeliveryGroupValidator(
        storeDelivery,
        branches,
        branchStoreMapping,
      ),
      'message',
      [],
    );

    return [
      ...crossEntityBranchMessages,
      ...crossEntityJobSiteMessages,
      ...crossEntityBranchToStoreDeliveryGroupMessages,
      ...crossEntityDeliveryGroupMessages,
    ];
  },
);

export const selectAllValidation = createSelector(
  getSupplierValidation,
  getStoreDeliveryValidation,
  getBranchListingValidation,
  getBranchToStoreMappingValidation,
  getStoreInfoValidation,
  getJobSiteDeliveryValidation,
  getCrossSheetValidationMessages,
  (
    supplierInformation,
    storeDelivery,
    branchListing,
    branchToStoreMapping,
    storeInfo,
    jobSiteDelivery,
    crossSheetValidationMessages,
  ) => {
    const supplierMessages = get(
      supplierInformation,
      'validation.message',
      [],
    ).map((error) => ({
      ...error,
      location: window.Locale.SUPPLIER_LISTING,
    }));
    const storeDeliveryMessages = get(
      storeDelivery,
      'validation.message',
      [],
    ).map((error) => ({
      ...error,
      location: window.Locale.STORE_DELIVERY_GROUP_LISTING,
    }));
    const branchListingMessages = get(
      branchListing,
      'validation.message',
      [],
    ).map((error) => ({
      ...error,
      location: window.Locale.BRANCH_LISTING,
    }));
    const branchToStoreMappingMessages = get(
      branchToStoreMapping,
      'validation.message',
      [],
    ).map((error) => ({
      ...error,
      location: window.Locale.BRANCH_TO_STORE_MAPPING,
    }));
    const storeInfoMessages = get(storeInfo, 'validation.message', []).map(
      (error) => ({
        ...error,
        location: window.Locale.BRANCH_TO_STORE_MAPPING,
      }),
    );
    const jobSiteDeliveryMessages = get(
      jobSiteDelivery,
      'validation.message',
      [],
    ).map((error) => ({
      ...error,
      location: window.Locale.JOB_SITE_DELIVERY_LISTING,
    }));

    return [
      ...supplierMessages,
      ...branchListingMessages,
      ...storeDeliveryMessages,
      ...branchToStoreMappingMessages,
      ...storeInfoMessages,
      ...jobSiteDeliveryMessages,
      ...crossSheetValidationMessages,
    ];
  },
);

export const checkDataPresence = createSelector(
  getSupplierInformation,
  getBranchListingData,
  getStoreDeliveryData,
  getJobSiteDeliveryData,
  getBranchToStoreMappingData,
  (
    supplierInformation,
    branchListingData,
    storeDeliveryData,
    jobSiteDeliveryData,
    branchToStoreMappingData,
  ) => {
    // remove all data that is null from the array, if the length is greater than 0, then return true.
    return (
      [
        supplierInformation,
        branchListingData,
        storeDeliveryData,
        jobSiteDeliveryData,
        branchToStoreMappingData,
      ].filter((val) => val !== null).length > 0
    );
  },
);

export const isSheetValid = createSelector(
  selectAllValidation,
  (data) => {
    return data.length === 0;
  },
);
