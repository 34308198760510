import { jobSiteDeliveryStartingRowNumber } from '../consts';
import {
  getCellRefValue,
  getCellRefNumberValue,
  generateRowRange,
} from './spreadsheetHelpers';

export default function createJobSiteDeliveryInfo(parsedData) {
  const jobSiteDeliveryArray = [];
  const jobSiteDeliveryGroupRowNumbers = generateRowRange(
    parsedData,
    jobSiteDeliveryStartingRowNumber,
  );

  function createDeliveryInfo(rowNumber) {
    return {
      row: rowNumber,
      branchName: getCellRefValue(parsedData, 'A', rowNumber),
      fromInMiles: getCellRefNumberValue(parsedData, 'C', rowNumber),
      deliveryFeeDollarAmount: getCellRefNumberValue(
        parsedData,
        'D',
        rowNumber,
      ),
      freeDeliveryOverDollarAmount: getCellRefNumberValue(
        parsedData,
        'E',
        rowNumber,
      ),
      maximumDeliveryTime: getCellRefNumberValue(parsedData, 'F', rowNumber),
    };
  }

  jobSiteDeliveryGroupRowNumbers.forEach((rowNumber) => {
    const jobSiteDeliveryData = createDeliveryInfo(rowNumber);

    // if all the JSD data is empty or undefined, jsdContainsOnlyFalsyData will be true,
    // and we should not push that data into the array
    const jsdContainsOnlyFalsyData =
      jobSiteDeliveryData.branchName === '' &&
      jobSiteDeliveryData.deliveryFeeDollarAmount === 0 &&
      jobSiteDeliveryData.freeDeliveryOverDollarAmount === 0 &&
      jobSiteDeliveryData.fromInMiles === 0 &&
      jobSiteDeliveryData.maximumDeliveryTime === 0;

    if (!jsdContainsOnlyFalsyData) {
      jobSiteDeliveryArray.push(jobSiteDeliveryData);
    }
  });

  return jobSiteDeliveryArray;
}
