import {
  call,
  delay,
  put,
  takeLatest,
  select,
  all,
  cancelled,
  race,
  take,
} from 'redux-saga/effects';
import {
  keyBy,
  mapValues,
  toString,
  groupBy,
  find,
  values,
  first,
} from 'lodash';
import dayjs from 'dayjs';
import { getStoreDeliveryData } from 'features/parseOnboardingSpreadsheet/redux/storeDelivery/storeDeliverySelectors';
import {
  userErrorMessage,
  userSuccessMessage,
  userInfoMessage,
} from 'common/userMessageContainer/redux/userMessageActionCreators';
import { clientActionSuccessSaga } from 'common/clientActions/clientActionsSaga';
import { getBranchesWithCoords } from 'features/previewBranch/redux/previewBranchSelectors';
import {
  assignStoresToBranch,
  createBranch,
  createJobSiteDelivery,
  createSupplier,
  enableJobSiteDelivery,
  saveMarketClass,
  saveMerchantClass,
  deleteSupplier,
  createNewDeliveryGroup,
  setInstantPrice,
  assignStoreToDeliveryGroup,
  pollDeliveryGroups,
} from './onboardSupplierAJAX';
import { PROGRESS_STEPS } from './onboardSupplierReducer';

import {
  ONBOARD_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_REQUEST,
  ONBOARD_SUPPLIER_CANCEL_REQUEST,
} from './onboardSupplierActionTypes';
import {
  onboardSupplierSuccess,
  onboardSupplierError,
  onboardSupplierProgress,
  deleteSupplierRequest,
  deleteSupplierSuccess,
  deleteSupplierFailure,
  resetOnboardingState,
} from './onboardSupplierActionCreators';
import { createWorkerQueue } from './onboardSupplierUtil';
import { getSupplierInformation } from '../../../supplierInfoDisplay/redux/supplierInformationSelectors';
import { getBranchToStoreMappingData } from '../../../branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { mapBranchInfoToStoreData } from '../utils/index';
import { getJobSiteDeliveryData } from '../../../parseOnboardingSpreadsheet/redux/jobSiteDelivery/jobSiteDeliverySelectors';

function* createSupplierStep() {
  // TODO: commented out fields are data that Ergon used to need but is no longer provided

  const {
    supplierAccountName,
    brandName,
    requireVendorProductIdsForEdi,
    allowJobSiteDelivery,
    // TODO: This will be required eventually but Ergon and the Adapter needs a contract change
    // allowUniqueVendorProductIdByLength,
    primaryContact: { name, phoneNumber, phoneNumberExt, email, title },
  } = yield select(getSupplierInformation);

  const body = {
    accountName: supplierAccountName,
    mainContactName: name,
    brandName,
    phoneNumber: toString(phoneNumber),
    phoneNumberExt,
    email,
    contactTitle: title,
    // TODO: figure out how to make these values case insensitive
    requireSupplierProductIdsForEdi:
      requireVendorProductIdsForEdi.toLowerCase() === 'yes',
    canDeliverToJobsite: allowJobSiteDelivery.toLowerCase() === 'yes',
    //   ewpUniqueModelNumberEnabled: false,
    //   backgroundInfo: backgroundInformation,
    //   apiKey: '',
  };
  return yield call(createSupplier, body);
}

function* createBranchStep({
  supplierId,
  branchName,
  customerServiceContact,
  address: { street, city, state, zip, latitude, longitude },
  brandName,
  phone,
  email,
  fax,
  phoneExt,
  storeDeliveryEnabled,
}) {
  const body = {
    name: branchName,
    brandName,
    customerServiceContact,
    address: street,
    city,
    state,
    zip: toString(zip),
    latitude: toString(latitude),
    longitude: toString(longitude),
    phone: toString(phone),
    ext: toString(phoneExt),
    fax: toString(fax),
    email,
    storeDeliveryEnabled,
  };
  const { branchId } = yield call(createBranch, { supplierId, body });
  return {
    branchName,
    branchId,
  };
}

function* mapBranchToStores({ supplierId, branchId, storeIds }) {
  // We have serious performance hits whenever we map banches to many many stores.
  // A request to map a single branch to all stores takes 3 minutes to respond.
  // This is an ergon level challenge that is outside of our control.
  // From this we need to batch agressively to prevent GCP from killing our requests. This happens at 30 seconds
  // Additionally we cannot batch this on the server (which would prevent corruption of this codebase) as we would still hit the timeout
  // We must also account for load. If we try to squeeze the most performance out of this we will end up breaking if ergon has slowdowns.
  // In theory we could handle about 20% of all stores at time, which would take almost exactly 30 seconds, but it gives very little breathing room.
  // To attempt to minimize failures. We will instead take on no more than 50 stores at a time. (~2% of total stores)
  // This projects an estimated 4.7 second response time (ignoring request overhead). Leaving a lot of leftover space in the request.

  // create a queue of stores
  let remainingStores = storeIds;

  while (remainingStores.length > 50) {
    // Process the first 50 of them
    const firstFifty = remainingStores.slice(0, 50);
    // Remove those 50 from the queue
    remainingStores = remainingStores.slice(50);
    // send a network request to map the first 50
    yield call(assignStoresToBranch, {
      supplierId,
      branchId,
      storeIds: firstFifty,
    });
    // if at this point we have less than 50 stores in our queue we exit the loop
    // They will be processed as the remaining stores
  }
  yield call(assignStoresToBranch, {
    supplierId,
    branchId,
    storeIds: remainingStores,
  });
}

function* mapAllBranchesToStore({ supplierId, branches }) {
  const storeInfo = yield select(getBranchToStoreMappingData);
  const storeBranchInfo = mapBranchInfoToStoreData(branches, storeInfo);
  yield all(
    storeBranchInfo.map((branch) => {
      return call(mapBranchToStores, { supplierId, ...branch });
    }),
  );
}

function* writeJobSiteDelivery(branches) {
  const jobSiteDelivery = yield select(getJobSiteDeliveryData);
  const jobSiteDeliveryByGroup = groupBy(jobSiteDelivery, 'branchName');
  // Enables JobSite Delivery for each branch that had JobSite delivery ranges
  yield all(
    values(jobSiteDeliveryByGroup).map((branch) => {
      const { branchId } = find(branches.newBranches, {
        branchName: first(branch).branchName,
      });
      return call(enableJobSiteDelivery, branchId);
    }),
  );

  // Add the JobSite Delivery ranges for each branch
  yield all(
    values(jobSiteDeliveryByGroup).map((ranges) => {
      // For a given set of ranges we need to find a branchID by searching for the branchName
      // The branchName will be the same for every item in the set of ranges so we just use the first
      const { branchId } = find(branches.newBranches, {
        branchName: first(ranges).branchName,
      });
      return call(createJobSiteDelivery, { branchId, ranges });
    }),
  );
}

function* processDeliveryGroupQueue(queue) {
  while (queue.length) {
    const task = queue.shift();

    let taskAttempts = 1; // number of times we have fired the assign request, after three tries we abandon the create process by throwing an error
    let res = yield assignStoreToDeliveryGroup(task);

    let delayInSeconds = 1;
    let tries = 0;
    while (res.statusCode === 202) {
      res = yield pollDeliveryGroups(task);

      // If we're still waiting, add a delay.
      if (res.statusCode === 202) {
        yield delay(delayInSeconds * 1000);

        tries += 1;

        // We're performing exponential backoff in our polling, but once we are waiting longer than 30 seconds we stop increasing our delay.
        // This is a normal practice within exponential backoffs. We stop at 32 in particular because it makes the most sense mathwise
        // (1 * 2^5 = 32, so if we double 5 times we have a 32 second delay)
        if (delayInSeconds < 32) {
          delayInSeconds *= 2;
        }
        if (tries > 10) {
          if (taskAttempts > 2) {
            throw new Error('could not map stores to delivery groups');
          }

          // reattempt first request
          delayInSeconds = 1;
          tries = 0;
          taskAttempts += 1;
          res = yield assignStoreToDeliveryGroup(task);
        }
      }
    }
  }
}

function* storeDeliveryGroupCreation(newBranches) {
  // To create storeDeliveryGroups, we need to have them, so we check that here.
  const deliveryGroups = yield select(getStoreDeliveryData);

  if (deliveryGroups && newBranches) {
    yield put(onboardSupplierProgress(PROGRESS_STEPS.CREATING_DELIVERY_GROUPS));
    // deliveryGroup creation requires branchIds and delivery group pairings.
    // So first we perform a transform on newBranches to make lookups easier.
    // This creates a shape that looks like this: {
    //   branchName: { branchName, branchId }
    // }
    const temporaryDictionary = keyBy(newBranches, (pair) => pair.branchName);
    // We perform another transform to make it cleaner and achieve this: {
    //    branchName: branchId
    // }
    const branchNameIdDictionary = mapValues(
      temporaryDictionary,
      (obj) => obj.branchId,
    );

    // Here we perform a network request that creates the delivery group.
    const deliveryGroupBranchNamePairs = yield all(
      deliveryGroups.map((deliveryGroup) => {
        return call(
          createNewDeliveryGroup,
          branchNameIdDictionary[deliveryGroup.branchName],
          deliveryGroup,
        );
      }),
    );

    // It returns an array of objects that look like this: [
    //    {
    //      branchId: 5024
    //      deliveryGroupId: 4077
    //      deliveryGroupName: "Group 1"
    //    }
    //  ]

    // Its now time to put stores in deliveryGroups
    // We express our "branch to store" mappings as an array of objects that look like this: {
    //    branchName: "Alabama",
    //    deliveryGroupName: "Alaska"
    //    storeData: {
    //      storeNumber: "6889"
    //    }
    // }

    // We need three things to map. BranchId, DeliveryGroupID and StoreNumber

    // But as you can see, we don't really have that in the same language,
    // So we're going to create an intermediary data structure for this purpose.

    // Here we create an object that looks like this: {
    //   [branchId]: {
    //     [deliveryGroupName]: deliveryGroupId
    //   }
    // }
    const branchToStoreMappingLookupObject = {};

    deliveryGroupBranchNamePairs.forEach((element) => {
      const { deliveryGroupName, deliveryGroupId, branchId } = element;
      // This is building the tree object above.
      // We accomplish that by
      //    adding to a branch if it exists
      //    creating a branch if it doesn't exist
      if (branchToStoreMappingLookupObject[branchId]) {
        branchToStoreMappingLookupObject[branchId] = {
          ...branchToStoreMappingLookupObject[branchId],
          [deliveryGroupName]: deliveryGroupId,
        };
      } else {
        branchToStoreMappingLookupObject[branchId] = {
          [deliveryGroupName]: deliveryGroupId,
        };
      }
    });
    // Now that we have created our intermediary object we can use it to convert "branch to store" mappings into a network request

    // update the progress
    yield put(
      onboardSupplierProgress(PROGRESS_STEPS.MAPPING_STORES_TO_DELIVERY_GROUPS),
    );

    // get our "branch to store" mapping data
    const branchToStoreMapping = yield select(getBranchToStoreMappingData);

    const bulkJobs = {};
    yield branchToStoreMapping
      // remove stores that don't have delivery groups
      .filter((element) => !!element.deliveryGroupName)
      // Then convert into the desired object, a grouping of branchId, deliveryGroupId and storeId
      .forEach((element) => {
        const { branchName, deliveryGroupName, storeData } = element;
        const { storeNumber: storeId } = storeData;
        const branchId = branchNameIdDictionary[branchName];
        const deliveryGroupId =
          branchToStoreMappingLookupObject[branchId][deliveryGroupName];

        // Here we are creating the jobs object
        // {
        //    [branchId]: {
        //        [deliveryGroupId]: [storeId],
        //    }
        // }
        if (bulkJobs[branchId]) {
          if (bulkJobs[branchId][deliveryGroupId]) {
            // If you are here, the object already has this branchId and this deliveryGroupId, just add the storeId to the array
            bulkJobs[branchId][deliveryGroupId].push(storeId);
          } else {
            // If you are here, the object already has your branchId, but doesn't have this deliveryGroupId, so add that and your storeId to the array
            bulkJobs[branchId][deliveryGroupId] = [storeId];
          }
        } else {
          // If you are here, the object doesn't have any of this information, so you should add it here.
          bulkJobs[branchId] = {
            [deliveryGroupId]: [storeId],
          };
        }
      });

    const workerQueue = yield createWorkerQueue(bulkJobs);
    // Then send our network requests
    yield call(processDeliveryGroupQueue, workerQueue);
  }
}

export function* enableInstantPricing(branches) {
  yield put(onboardSupplierProgress(PROGRESS_STEPS.SETTING_INSTANT_PRICING));

  yield all(branches.map((branch) => setInstantPrice(branch.branchId)));
}

// exported for tests
// Created clientActionsSaga to log business events that are non-blocking to the application, if AJAX calls are done after action creators it will be blocking
export function* onboardSupplierFlow() {
  const startTime = dayjs();
  let createdSupplierId;
  let errored;

  try {
    yield put(onboardSupplierProgress(PROGRESS_STEPS.CREATING_SUPPLIER));
    const { supplierId } = yield call(createSupplierStep);

    createdSupplierId = supplierId;
    const branches = yield select(getBranchesWithCoords);

    yield put(onboardSupplierProgress(PROGRESS_STEPS.CREATING_BRANCHES));

    const newBranches = yield all(
      branches.map((branch) =>
        call(createBranchStep, { ...branch, supplierId }),
      ),
    );

    yield put(onboardSupplierProgress(PROGRESS_STEPS.SETTING_MERCHANT_CLASS));
    yield call(saveMerchantClass, supplierId);

    yield put(onboardSupplierProgress(PROGRESS_STEPS.SETTING_MARKET_CLASS));
    yield call(saveMarketClass, supplierId);
    yield put(onboardSupplierProgress(PROGRESS_STEPS.SETTING_JOBSITE_DELIVERY));
    yield call(writeJobSiteDelivery, { newBranches });

    yield call(enableInstantPricing, newBranches);

    yield put(
      onboardSupplierProgress(PROGRESS_STEPS.MAPPING_BRANCHES_TO_STORES),
    );
    yield call(mapAllBranchesToStore, { supplierId, branches: newBranches });
    // createStoreDeliveryGroups
    yield call(storeDeliveryGroupCreation, newBranches);
    // done
    yield put(onboardSupplierSuccess(supplierId));
    yield put(onboardSupplierProgress(PROGRESS_STEPS.CREATION_COMPLETE));
    yield put(userSuccessMessage(window.Locale.RECORD_CREATED));

    const endTime = dayjs();
    const createTime = endTime.diff(startTime);

    yield call(clientActionSuccessSaga, {
      endTime: endTime.format(),
      startTime: startTime.format(),
      createTime,
      supplierId,
    });
  } catch (e) {
    errored = true;

    yield put(userErrorMessage(e.message));
    yield put(onboardSupplierError(e));
    yield put(onboardSupplierProgress(PROGRESS_STEPS.NOT_STARTED));
  } finally {
    // This will always execute, so why is it here?
    // This saga expects to be canceled.
    // If canceled. This is the only place to perform cleanup
    // So we do that here.

    // Errors could also need cleanup, so we handle that here to be DRY

    // yield cancelled is true when canceled (in our case via a saga race)
    const isCancelled = yield cancelled();

    if ((isCancelled || errored) && createdSupplierId) {
      // If we created a supplier, we start to clean that up here.
      yield put(deleteSupplierRequest(createdSupplierId));
    }
  }
}

// Due to the architecture of our application, atomicity is a challenge.
// If a user attempts a create then closes their browser, our server is unable to clean up their mess.
// If our create fails partway through our server will not automatically clean up the mess. Nor can it.
// If we attempt to delete a record and it does not work, our server will not automatically reattempt it.
// If we fail to create, we may not have even made it far enough to need to perform a delete.
// Because of this we need to opt for high confidence here.
// Therefore we have added retry and a failure case that explicitly reaches out to the human stack for help
export function* deleteSupplierFlow({ supplierId, attempt = 1 }) {
  // Delete the record
  //    If delete fails and isn't a 404, retry up to three times.
  // If at that point delete hasn't succeeded or returned a 404, alert the user.

  const RETRY_LIMIT = 3;

  try {
    yield deleteSupplier(supplierId);
    yield put(deleteSupplierSuccess());
  } catch (e) {
    if (e.status === 404) {
      // If we have a 404 error the supplier doesn't exist and we actually succeeded.
      yield put(deleteSupplierSuccess());
    } else {
      yield put(deleteSupplierFailure(e, supplierId));

      if (attempt > RETRY_LIMIT) {
        yield put(userErrorMessage(window.Locale.COULD_NOT_REVERT_CREATE));
      } else {
        yield put(deleteSupplierRequest(supplierId, attempt + 1));
      }
    }
  }
}

export function* cancelableOnboardingSaga() {
  // How this works:
  // Races are really well documented within Saga docs. Check that out if you need to go deeper
  // When a race is called all effects act simultaneously. The moment one completes,
  // all others get a cancelization error thrown within them.
  // In our case, when a cancel request occurs, an action with type ONBOARD_SUPPLIER_CANCEL_REQUEST will dispatch
  // 'take' will match that action then return, winning the race.
  // Then the onboardingSupplierFlow will have an 'error' thrown in it.
  // This error can't be caught. So we need to do cleanup actions within the finally block. (Head there for specifics)
  // We then provide messaging from here in case of cancel. Networking cleanup is left to the onboardSupplierFlow.

  const { cancel } = yield race({
    cancel: take(ONBOARD_SUPPLIER_CANCEL_REQUEST),
    succeeded: call(onboardSupplierFlow),
  });

  if (cancel) {
    yield put(userInfoMessage(window.Locale.CANCELLED_SUCCESSFULLY));
    yield put(resetOnboardingState());
  }
}

function* pushToProductionSaga() {
  yield takeLatest(ONBOARD_SUPPLIER_REQUEST, cancelableOnboardingSaga);
  yield takeLatest(DELETE_SUPPLIER_REQUEST, deleteSupplierFlow);
}

export default pushToProductionSaga;
