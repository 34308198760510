import { createSelector } from 'reselect';
import { branchSelector } from 'features/previewBranch/redux/previewBranchSelectors';
import { getStoreInfo } from 'features/storeInfo/redux/storeInfoSelector';
import { getBranchToStoreMappingData } from '../../branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { getCoordinates } from '../../parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesSelector';
import { getPins } from '../previewBranchToStoreMappingUtils';

export const allPinsSelector = createSelector(
  getBranchToStoreMappingData,
  getCoordinates,
  branchSelector,
  getStoreInfo,
  (mapping, coordinates, branches, stores) => {
    return getPins(mapping, stores, coordinates, branches);
  },
);
