import { get } from 'lodash';
import { createSelector } from 'reselect';
import { validateBranchListingData } from '../../../validateParsedData/validateBranchListingData';

export const getBranchListingData = (state) =>
  get(state, 'branchListing.data', []);

export const getBranchListingValidation = createSelector(
  getBranchListingData,
  (data) => validateBranchListingData(data),
);
