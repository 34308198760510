import request from 'superagent';

import { getConfig } from '../../../util/config';

/**
 *
 * AJAX request for validating a metoxos cookie
 */
export const authenticateCookieRequest = () => {
  const config = getConfig();
  return request
    .get(`${config.REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/getUserProfile`)
    .withCredentials()
    .then((res) => res.body)
    .catch((e) => Promise.reject(e));
};

export const userSessionIdRequest = () => {
  const config = getConfig();
  return request
    .post(
      `${config.REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/status/session/started`,
    )
    .withCredentials()
    .then((res) => res.body);
};

export const userLogoutRequest = () => {
  const config = getConfig();
  return request
    .post(`${config.REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/user/logout`)
    .withCredentials()
    .then((res) => res.body);
};
