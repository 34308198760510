import { validationObjInit } from './validators/validationObjInit';
import { existenceValidator } from './validators/existenceValidator';
import { specialCharactersValidator } from './validators/specialCharactersValidator';
import { nonEmptyValidator } from './validators/nonEmptyValidator';
import { numericalStringValidator } from './validators/numericalStringValidator';
import { enumValidator } from './validators/enumValidator';
import { contactValidator } from './validators/contactValidator';
import { lengthValidator } from './validators/lengthValidator';
import { createErrorObj } from './helpers/createErrorObj';

export const validateSupplierData = (supplierData) => {
  if (!supplierData) return {};

  // we use this to track the validation state of the whole entity.
  const supplierValidation = validationObjInit();
  supplierValidation.message = [];

  // ============================================
  //  Name Validations
  // ============================================

  // ======================
  // Supplier name must:
  //   - Be at least 1 character
  //   - Contain only alphanumeric characters
  //   - Exist
  // ======================

  const supplierNameValidation = validationObjInit();
  // must exist and be at least 1 character
  if (
    !existenceValidator(supplierData.supplierAccountName) ||
    !nonEmptyValidator(supplierData.supplierAccountName)
  ) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.SUPPLIER_ACCOUNT_NAME,
      supplierData.supplierAccountName,
      window.Locale.ERROR_NO_SUPPLIER_NAME,
    );

    supplierNameValidation.isValid = false;
    supplierNameValidation.message = message;

    supplierValidation.isValid = false;
    supplierValidation.message.push(message);
  }

  if (!specialCharactersValidator(supplierData.supplierAccountName)) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.SUPPLIER_ACCOUNT_NAME,
      supplierData.supplierAccountName,
      window.Locale.ERROR_SUPPLIER_NAME_CONTAINS_SPECIAL_CHARACTER,
    );

    supplierNameValidation.isValid = false;
    supplierNameValidation.message = message;

    supplierValidation.isValid = false;
    supplierValidation.message.push(message);
  }

  // ======================
  // Brand name must:
  //   - Be at least 1 character
  //   - Exist
  // ======================

  // must exist and be at least 1 character
  const brandNameValidation = validationObjInit();
  if (
    !existenceValidator(supplierData.brandName) ||
    !nonEmptyValidator(supplierData.brandName)
  ) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.BRAND_NAME,
      supplierData.brandName,
      window.Locale.ERROR_NO_BRAND_NAME,
    );

    brandNameValidation.isValid = false;
    brandNameValidation.message = message;

    supplierValidation.isValid = false;
    supplierValidation.message.push(message);
  }

  // ============================================
  //  Number Validations
  // ============================================

  // ======================
  // pVendor Number must:
  //   - Be a number
  //   - Exist
  // ======================

  const pVendorNumberValidation = validationObjInit();

  // Must be a number
  if (!numericalStringValidator(supplierData.pVendorNumber)) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.PVENDOR_NUMBER,
      supplierData.pVendorNumber,
      window.Locale.ERROR_BAD_PVENDOR,
    );

    pVendorNumberValidation.isValid = false;
    pVendorNumberValidation.message = message;

    supplierValidation.isValid = false;
    supplierValidation.message.push(message);
  }

  // Must exist
  if (!nonEmptyValidator(supplierData.pVendorNumber)) {
    const message = createErrorObj(
      supplierData.row,
      window.Locale.PVENDOR_NUMBER,
      supplierData.pVendorNumber,
      window.Locale.ERROR_NO_PVENDOR,
    );

    pVendorNumberValidation.isValid = false;
    pVendorNumberValidation.message = message;

    supplierValidation.isValid = false;
    supplierValidation.message.push(message);
  }

  // ======================
  // mVendor Number must:
  //   - Be a number
  //   - OPTIONAL VALUE
  // ======================

  const mVendorNumberValidation = validationObjInit();

  if (nonEmptyValidator(supplierData.mVendorNumber)) {
    // Must be a number
    if (!numericalStringValidator(supplierData.mVendorNumber)) {
      const message = createErrorObj(
        supplierData.row,
        window.Locale.MVENDOR_NUMBER,
        supplierData.mVendorNumber,
        window.Locale.ERROR_BAD_MVENDOR,
      );

      mVendorNumberValidation.isValid = false;
      mVendorNumberValidation.message = message;

      supplierValidation.isValid = false;
      supplierValidation.message.push(message);
    }
  }

  // ======================
  // GLN must:
  //   - Be a number
  //   - Be 13 digits
  //   - OPTIONAL VALUE
  // ======================

  const glnValidation = validationObjInit();

  // optional value
  if (supplierData.GLN.length !== 0) {
    // Must be a number and must be 13 digits
    if (
      !numericalStringValidator(supplierData.GLN) ||
      !lengthValidator(supplierData.GLN, { length: 13 })
    ) {
      const message = createErrorObj(
        supplierData.row,
        window.Locale.GLN,
        supplierData.GLN,
        window.Locale.ERROR_BAD_GLN(supplierData.GLN),
      );

      mVendorNumberValidation.isValid = false;
      mVendorNumberValidation.message = message;

      supplierValidation.isValid = false;
      supplierValidation.message.push(message);
    }
  }

  // ============================================
  //  Contact Validations
  // ============================================

  // ======================
  // Primary Contact must:
  //   - Be a valid contact
  //   - Exist
  // ======================
  // NOTE: Primary contact does not have a row param on the object, we add this here.

  const primaryContactValidation = contactValidator({
    ...supplierData.primaryContact,
    row: supplierData.row,
  });
  if (!primaryContactValidation.validation.isValid) {
    supplierValidation.isValid = false;
    supplierValidation.message.push(
      ...primaryContactValidation.validation.message,
    );
  }

  // ======================
  // idm Contact must:
  //   - Be a valid contact
  // ======================

  let idmContactValidation;

  // optional, only check contact if it has values
  if (Object.keys(supplierData.idmContact).length) {
    idmContactValidation = contactValidator(supplierData.idmContact);

    if (!idmContactValidation.validation.isValid) {
      supplierValidation.isValid = false;
      supplierValidation.message.push(
        ...idmContactValidation.validation.message,
      );
    }
  }

  // ======================
  // edi Contact must:
  //   - Be a valid contact
  // ======================

  let ediContactValidation;

  // optional, only check contact if it has values
  if (Object.keys(supplierData.ediContact).length) {
    ediContactValidation = contactValidator(supplierData.ediContact);

    if (!ediContactValidation.validation.isValid) {
      supplierValidation.isValid = false;
      supplierValidation.message.push(
        ...ediContactValidation.validation.message,
      );
    }
  }

  // ======================
  // additional Contacts must:
  //   - Be an array of valid contacts
  //   - Can be empty
  // ======================

  const additionalContactsValidation = [];

  // optional, only check contact if it has values
  if (supplierData.additionalContacts.length) {
    const validationsForContacts = supplierData.additionalContacts.map(
      (contact) => {
        const validatedContact = contactValidator(contact);
        if (!validatedContact.validation.isValid) {
          supplierValidation.isValid = false;
          supplierValidation.message.push(
            ...validatedContact.validation.message,
          );
        }
        return validatedContact;
      },
    );
    additionalContactsValidation.push(...validationsForContacts);
  }

  // ============================================
  //  Enum Validations
  // ============================================

  // ======================
  // requireVendorProductIdsForEdiValidation must:
  //   - Be 'Yes' or 'No'
  // ======================

  // Because the enumValidator function handles case-sensitivity, we do not need to convert casing here
  const requireVendorProductIdsForEdiValidation = enumValidator(
    supplierData.requireVendorProductIdsForEdi,
    [window.Locale.YES, window.Locale.NO],
  );
  if (!requireVendorProductIdsForEdiValidation.isValid) {
    supplierValidation.isValid = false;
    supplierValidation.message.push(
      createErrorObj(
        supplierData.row,
        window.Locale.REQUIRE_VENDOR_PRODUCT_IDS_FOR_EDI,
        supplierData.requireVendorProductIdsForEdi,
        requireVendorProductIdsForEdiValidation.message,
      ),
    );
  }

  // ======================
  // allowJobSiteDeliveryValidation must:
  //   - Be 'Yes' or 'No'
  // ======================

  // Because the enumValidator function handles case-sensitivity, we do not need to convert casing here
  const allowJobSiteDeliveryValidation = enumValidator(
    supplierData.allowJobSiteDelivery,
    [window.Locale.YES, window.Locale.NO],
  );
  if (!allowJobSiteDeliveryValidation.isValid) {
    supplierValidation.isValid = false;
    supplierValidation.message.push(
      createErrorObj(
        supplierData.row,
        window.Locale.ALLOW_JOB_SITE_DELIVERY,
        supplierData.allowJobSiteDelivery,
        allowJobSiteDeliveryValidation.message,
      ),
    );
  }

  // ======================
  // allowUniqueVendorProductIdByLengthValidation must:
  //   - Be 'Yes', 'No', or empty
  // ======================

  // Because the enumValidator function handles case-sensitivity, we do not need to convert casing here
  const allowUniqueVendorProductIdByLengthValidation = enumValidator(
    supplierData.allowUniqueVendorProductIdByLength,
    [window.Locale.YES, window.Locale.NO, ''],
  );
  if (!allowUniqueVendorProductIdByLengthValidation.isValid) {
    supplierValidation.isValid = false;
    supplierValidation.message.push(
      createErrorObj(
        supplierData.row,
        window.Locale.ALLOW_UNIQUE_VENDOR_PRODUCT_ID_BY_LENGTH,
        supplierData.allowUniqueVendorProductIdByLength,
        allowUniqueVendorProductIdByLengthValidation.message,
      ),
    );
  }

  // ======================
  // backgroundInformationValidation must:
  //   - Be anything really, all states are legal
  // ======================
  const backgroundInformationValidation = validationObjInit();

  return {
    validation: supplierValidation,
    GLN: glnValidation,
    additionalContacts: additionalContactsValidation,
    allowJobSiteDelivery: allowJobSiteDeliveryValidation,
    allowUniqueVendorProductIdByLength: allowUniqueVendorProductIdByLengthValidation,
    backgroundInformation: backgroundInformationValidation,
    brandName: brandNameValidation,
    ediContact: ediContactValidation,
    idmContact: idmContactValidation,
    mVendorNumber: mVendorNumberValidation,
    pVendorNumber: pVendorNumberValidation,
    primaryContact: primaryContactValidation,
    requireVendorProductIdsForEdi: requireVendorProductIdsForEdiValidation,
    supplierAccountName: supplierNameValidation,
  };
};
