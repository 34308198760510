export const postalCodeValidator = (val) => {
  // This is a relatively strict postal code regex that validates both US (zip/zip+4) and Canadian postal codes.
  // US ZIP codes should be either 5 integers or 5 integers followed by a hypen and 4 more integers.
  // Canadian postal codes should be 6 alphanumeric characters (all capitalized), with an optional space after the third character.

  const postalCodeRegex = new RegExp(
    /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/,
  );

  return postalCodeRegex.test(val);
};
