import {
  GET_STORE_INFO_SUCCESS,
  GET_STORE_INFO_FAILURE,
  GET_STORE_INFO_REQUEST,
} from './storeInfoActionTypes';

// Currently there is no UI component for this reducer
// Placing it here follows our folder structure convention.

export const getInitialState = () => ({
  data: {},
  error: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case GET_STORE_INFO_SUCCESS: {
      const { stores } = action;

      return { ...state, data: stores || {} };
    }
    case GET_STORE_INFO_FAILURE: {
      const { error } = action;

      return { ...state, error };
    }
    case GET_STORE_INFO_REQUEST: {
      // unset the error message on new request
      return { ...state, error: null };
    }

    default:
      return state;
  }
};
