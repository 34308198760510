import { SET_USER_MESSAGE, CLEAR_USER_MESSAGE } from './userMessageActionTypes';
import LOG_LEVELS from '../logLevels';

export const setUserMessage = (message, logLevel) => ({
  type: SET_USER_MESSAGE,
  message,
  logLevel,
});

// convenience functions
export const userErrorMessage = (message) =>
  setUserMessage(message, LOG_LEVELS.ERROR);
export const userWarningMessage = (message) =>
  setUserMessage(message, LOG_LEVELS.WARNING);
export const userInfoMessage = (message) =>
  setUserMessage(message, LOG_LEVELS.INFO);
export const userSuccessMessage = (message) =>
  setUserMessage(message, LOG_LEVELS.SUCCESS);

export const clearUserMessage = () => ({
  type: CLEAR_USER_MESSAGE,
});
