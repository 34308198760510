import { get, map, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import { getBranchToStoreMappingData } from 'features/branchToStoreMapping/redux/branchToStoreMappingSelectors';
import { getCoordinates } from 'features/parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesSelector';

export const branchSelector = (state) => get(state, 'branchListing.data', []);

export const getBranchCounts = createSelector(
  getBranchToStoreMappingData,
  branchSelector,
  (mapping, branches) => {
    const groups = groupBy(mapping, 'id');
    return map(branches, (branch) => ({
      ...branch,
      storeCount: groups[branch.row] ? groups[branch.row].length : 0,
    }));
  },
);

export const getBranchesWithCoords = createSelector(
  branchSelector,
  getCoordinates,
  (branches, coords) =>
    branches.map((branch) => {
      const [latitude, longitude] = coords[branch.row];
      return {
        ...branch,
        address: {
          ...branch.address,
          latitude,
          longitude,
        },
      };
    }),
);
