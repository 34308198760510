import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getBranchCount,
  getMappedStoreCount,
  getUnmappedStoreCount,
} from '../redux/submissionSelectors';
import './submissionSummaryBanner.scss';

export const SubmissionSummaryBanner = ({
  branchCount,
  mappedStoreCount,
  unmappedStoreCount,
}) => (
  <div className="submission-display-container">
    <span className="label">{`${window.Locale.BRANCHES}:`}</span>
    <span className="number">{branchCount}</span>

    <span className="label">{`${window.Locale.MAPPED_STORES}:`}</span>
    <span className="number">{mappedStoreCount}</span>

    <span className="label">{`${window.Locale.UNMAPPED_STORES}:`}</span>
    <span className="number">{unmappedStoreCount}</span>
  </div>
);

SubmissionSummaryBanner.propTypes = {
  branchCount: PropTypes.number,
  mappedStoreCount: PropTypes.number,
  unmappedStoreCount: PropTypes.number,
};

SubmissionSummaryBanner.defaultProps = {
  branchCount: null,
  mappedStoreCount: null,
  unmappedStoreCount: null,
};

const mapStateToProps = (state) => ({
  branchCount: getBranchCount(state),
  mappedStoreCount: getMappedStoreCount(state),
  unmappedStoreCount: getUnmappedStoreCount(state),
});

export default connect(mapStateToProps)(SubmissionSummaryBanner);
