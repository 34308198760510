import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';
import locale from './app/locale';
import { initConfig } from './util/config';
import { initDatadogRum } from './datadog';

window.Locale = locale;

// Import environment variables before we render the app.
const main = async () => {
  await initConfig();
  await initDatadogRum();
};

main().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
